import { Box, Stack, TableContainer } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import api_endpoints, { base_url } from "../../Api/end_points";
import { checkExportToExcelAllowed } from "../../Api/generic_apicalls";
import {
  ActionType,
  DOWNLOAD_SAMPLE_TEMPLATE_TYPE,
  ErrorTableHeaders,
  PublishModuleDD,
} from "../../Common/Constants";
import CustomTabs from "../../Common/CustomTabs";
import DataTable from "../../Common/DataTable";
import DataTableActions from "../../Common/DataTable/tableActions";
import { DownLoadSampleTemplate } from "../../Common/DownloadSampleTemplate";
import FilterAndSortRedux from "../../Common/FilterAndSortRedux";
import { SortTypesProps } from "../../Common/FilterAndSortRedux/Sort";
import PageTitle from "../../Common/PageTitle";
import AddNewContentModal from "../../Common/Popup";
import { useDownloadProgressContext } from "../../Common/ProgressProvider";
import { ACTION_TYPE } from "../../Common/RenderTableActions";
import RowsPerPage from "../../Common/RowsPerPage";
import SearchBar from "../../Common/SearchBar";
import { IRequestBody } from "../../Constants/interfaces";
import { formatDate } from "../../Utility/DateFormat";
import downloadZipFile, {
  DownloadZipParamsType,
} from "../../Utils/downloadZip";
import { ConvertJSONToXLS } from "../../Utils/fileHelper";
import { TriggerToastMessage } from "../../Utils/toastTrigger";
import { RootState } from "../../redux/reducers";
import BulkUploadModal from "../RetailersAndNurseries/Modal/BulkUploadModal";
import { GetThreatStageDetails } from "../Threats/ThreatsApiCalls";
import KnowMorePopup from "./../../Common/KnowMoreErrorModal/knowMoreDetails";
import { CreateOrModifyCrop } from "./AddEditCrop";
import {
  GetCropDetails,
  GetCropsDataList,
  GetErrorList,
  GetMyUploadList,
  UpdateCropStatus,
} from "./CropsAPICalls";
import {
  CropsMyUploadsTableColumnType,
  CropsTableColumnType,
} from "./CropsColumnsData";
import ViewCrop from "./ViewCrop/ViewCrop";
import { useStyles } from "./useStyle";
import cropImageMedia from "../../Utils/cropMedia";

const INITIAL_SORT: Partial<SortTypesProps> = {
  lastUpdatedOn: "1",
};

const Crop = (props: any) => {
  const { addDownloadProgress } = useDownloadProgressContext();

  const classes = useStyles();
  const storeData = useSelector((state: RootState) => {
    return {
      primaryLanguageCode: state.auth.languageCode,
      languages: state.generic.ListOfLanguages.languageList,
      countryCode: state.auth.countryCode,
    };
  });
  const INITIAL_FILTER_STATE = {
    languageIds: [] as string[],
    // status: [],
    // crop: [],
    // category: [],
  };
  // states
  const [searchedValue, setSearchedValue] = useState<string | undefined>("");
  const [activeTab, setActiveTab] = useState<number>(0);
  // ---Add New Data Modal Handlers-------------- //
  const [open, setOpen] = useState(false);
  const [cropsData, setCropsData] = useState<Array<any>>([]);
  const [cropsDataChecked, setCropsDataChecked] = useState<Array<any>>([]);
  const [sortId, setSortId] = useState("1");
  const [limit, setLimit] = useState<number>(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [viewCropModal, toggleViewCropModal] = useState<boolean>(false);
  const [selectedCropInfo, setSelectedCropInfo] = useState<any>("");
  const [filterObj, setFilterObj] = useState(INITIAL_FILTER_STATE);
  const [actionType, setActionType] = useState<string>("");
  const [showBulkUploadModal, toggleBulkUploadView] = useState(false);
  const [showCropModal, toggleNewCropModal] = useState<boolean>(false);
  const [openKnowMore, setOpenKnowMore] = useState<boolean>(false);
  const [errorList, setErrorList] = useState<Array<any> | undefined>(undefined);
  const [myUploadData, setMyUploadData] = useState<any | undefined>(undefined);
  /** variables creation end here */
  const [buttonExportVisible, setButtonExportVisible] =
    useState<boolean>(false);
  const [sortObj, setSortObj] = useState<Partial<SortTypesProps>>(INITIAL_SORT);

  // Filter data based on page checked
  const filterDataBasedOnPageChecked = (page: number, data: any) => {
    if (cropsDataChecked.length < 1) {
      setCropsDataChecked([
        {
          page: page,
          data: data,
        },
      ]);
      return;
    }

    const checkPageIsExist = cropsDataChecked.some(
      (oldData) => oldData.page === page
    );
    if (checkPageIsExist) {
      const filterData: any = cropsDataChecked.map((isdata: any) => {
        if (isdata.page === page) {
          return {
            page,
            data,
          };
        }
        return isdata;
      });
      setCropsDataChecked(filterData);
      return;
    }

    setCropsDataChecked([
      ...cropsDataChecked,
      {
        page: page,
        data: data,
      },
    ]);
  };

  const replaceDataAlreadyExistWithChecked = () => {
    if (cropsDataChecked.length < 1) return;

    const dataCheckedPage = cropsDataChecked.filter((data) => {
      return data.page === currentPage;
    });
    if (currentPage === dataCheckedPage?.[0]?.page) {
      setCropsData(dataCheckedPage?.[0]?.data);
    }
  };

  const getDetailCrop = (data: any) => {
    let apiData = {} as IRequestBody;
    apiData.domain = base_url;
    let endPoint: string = api_endpoints.crop_details;
    apiData.endPoint = endPoint.replace("__SYSTEM__NAME__", `${data.name}`);
    apiData.showLoader = true;
    /** payload */

    const languageCode = storeData.languages.find(
      (lang) => lang.languageId === data.languageId
    )?.languageCode;

    apiData.customHeaders = {
      "language-code": languageCode ?? "en",
    };

    return new Promise((resolve, reject) => {
      GetCropDetails(apiData)
        ?.then((response: any) => {
          resolve(response.data);
        })
        .catch((error) => reject(new Error("Error", { cause: error })));
    });
  };

  const getThreatsDetail = (data: any) => {
    const languageCode = storeData.languages.find(
      (lang) => lang.languageId === data.languageId
    )?.languageCode;

    const payload = {
      details: {
        name: data.systemName,
        languageCode,
      },
    };

    return new Promise((resolve, reject) => {
      GetThreatStageDetails(payload)
        ?.then((response: any) => {
          resolve(response.data);
        })
        .catch((error) => reject(new Error("Error", { cause: error })));
    });
  };

  const downloadWithRetry = (
    params: DownloadZipParamsType,
    retryCount: number = 0,
    selectedCpProductInfo: any[]
  ) => {
    const MAX_RETRY_COUNT = 3;
    downloadZipFile({
      ...params,
      onErrorDownload: (message) => {
        addDownloadProgress({
          module: "Crops",
          downloadName: selectedCpProductInfo[0]?.name,
          status: "Error",
          message: message ?? "Something went wrong",
          canRefetch: true,
          onRefetchCallback: () => {
            if (retryCount < MAX_RETRY_COUNT) {
              downloadWithRetry(params, retryCount + 1, selectedCpProductInfo);
            } else {
              addDownloadProgress({
                module: "Crops",
                downloadName: selectedCpProductInfo[0]?.name,
                status: "Error",
                message: `Maximum numbers of ${MAX_RETRY_COUNT} retries has been reached`,
                canRefetch: false,
              });
            }
          },
        });
      },
    });
  };

  const handleExportToExcel = async () => {
    const selectedCpProductInfo = _.filter(cropsData, { checked: true });

    if (selectedCpProductInfo.length === 0) {
      TriggerToastMessage("Please select atleast one line to export", "warn");
      return;
    }

    downloadWithRetry(
      {
        systemNames: [selectedCpProductInfo[0]?.name],
        path: `admin-portal/${storeData.countryCode}/`,
        folder: `crops`,
        onDownloadProgress: (progressEvent) => {
          if (progressEvent.target?.status === 200) {
            addDownloadProgress({
              module: "Crops",
              downloadName: selectedCpProductInfo[0]?.name,
              totalDownloaded: progressEvent.loaded,
              totalSize: progressEvent.total,
              progress: Math.round(
                (progressEvent.loaded / progressEvent.total) * 100
              ),
              status: "Downloading",
            });
          }
        },
        onPreparingDownload: () => {
          addDownloadProgress({
            module: "Crops",
            downloadName: selectedCpProductInfo[0]?.name,
            status: "Preparing",
            message: "Preparing to Download Zip File",
          });
        },
      },
      0,
      selectedCpProductInfo
    );

    const publishPendingStatus = selectedCpProductInfo.some(
      (value: any) => value.statusId === 5
    );

    if (publishPendingStatus) {
      const confirmationExport = confirm(
        "Exporting draft record for 'Publish Pending' item"
      );
      if (!confirmationExport) return;
    }

    const allDataCrop = await Promise.all(
      selectedCpProductInfo.map(getDetailCrop)
    );

    const allDataThreats = await Promise.all(allDataCrop.map(getThreatsDetail));

    const dataCropThreats: any[] = [];
    const dataThreatsDetailsList: any[] = [];
    const dataStagesCrop: any[] = [];
    const dataFiltersKey: any[] = [];
    const dataFiltersValues: any[] = [];
    const dataStagesThreats: any[] = [];
    const dataCropStages: any[] = [];
    const dataThreatFaq: any[] = [];
    const dataCropCalendar: any[] = [];
    const dataCropStageCalendar: any[] = [];
    const dataEnrollmentOptionsTranslation: any[] = [];

    processAllDataThreats(
      allDataThreats,
      dataCropThreats,
      dataThreatsDetailsList,
      dataStagesThreats,
      dataThreatFaq
    );
    processAllDataCrop(
      allDataCrop,
      dataStagesCrop,
      dataFiltersKey,
      dataFiltersValues,
      dataCropStages,
      dataCropCalendar,
      dataCropStageCalendar,
      dataEnrollmentOptionsTranslation
    );

    const dataObj = prepareDataObj(
      allDataCrop,
      dataThreatsDetailsList,
      dataStagesCrop,
      dataFiltersValues,
      dataFiltersKey,
      dataCropStages,
      dataStagesThreats,
      dataCropThreats,
      dataThreatFaq,
      dataEnrollmentOptionsTranslation,
      dataCropCalendar,
      dataCropStageCalendar
    );
    let settings = {
      fileName: "CropsInfo_" + new Date().getTime(), // Name of the resulting spreadsheet
      extraLength: 3, // A bigger number means that columns will be wider
      writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
    };
    ConvertJSONToXLS(dataObj, settings);
  };

  const processAllDataThreats = (
    allDataThreats: any[],
    dataCropThreats: any[],
    dataThreatsDetailsList: any[],
    dataStagesThreats: any[],
    dataThreatFaq: any[]
  ) => {
    allDataThreats.forEach((data: any) => {
      if (!data) return;

      data.payload.threatDetailsList.forEach((threat: any) => {
        const existingThreatDetail = dataCropThreats.find(
          (detail: any) => detail.threatSystemName === threat.threatSystemName
        );
        if (!existingThreatDetail) {
          dataCropThreats.push({
            ...threat,
            languageName: data.payload.languageName,
            cropSystemName: data.payload.cropSystemName,
          });
        }

        dataThreatsDetailsList.push({
          ...threat,
          languageName: data.payload.languageName,
          cropSystemName: data.payload.cropSystemName,
        });

        threat.threatFaq.forEach((threatFaq: any) => {
          dataThreatFaq.push({
            ...threatFaq,
            threatSystemName: threat.threatSystemName,
            languageName: data.payload.languageName,
          });
        });
      });
      data.payload.stages.forEach((stage: any) => {
        stage.threats.forEach((threat: any) => {
          const existingStageThreat = dataStagesThreats.find(
            (stageThreat: any) =>
              stageThreat.stageSystemName === stage.stageSystemName &&
              stageThreat.systemName === threat.systemName
          );
          if (!existingStageThreat) {
            dataStagesThreats.push({
              ...threat,
              stageSystemName: stage.stageSystemName,
            });
          }
        });
      });
    });
  };

  const processAllDataCrop = (
    allDataCrop: any[],
    dataStagesCrop: any[],
    dataFiltersKey: any[],
    dataFiltersValues: any[],
    dataCropStages: any[],
    dataCropCalendar: any[],
    dataCropStageCalendar: any[],
    dataEnrollmentOptionsTranslation: any[]
  ) => {
    allDataCrop.forEach((data: any) => {
      if (!data) return;

      data.payload.cropStages.forEach((stageCrop: any) => {
        dataStagesCrop.push({
          ...stageCrop,
          cropSystemName: data.systemName,
          languageName: data.languageName,
        });

        const existingStagesThreat = dataCropStages.find(
          (cropStage: any) => cropStage.systemName === stageCrop.systemName
        );

        if (!existingStagesThreat) {
          dataCropStages.push({
            ...stageCrop,
            cropSystemName: data.systemName,
          });
        }
      });
      data.payload.filterKeys.forEach((filterKey: any) => {
        dataFiltersKey.push({ ...filterKey, languageName: data.languageName });
        filterKey.filterValues.forEach((filterValue: any) => {
          dataFiltersValues.push({
            ...filterValue,
            filterKeySystemName: filterKey.systemName,
            languageName: data.languageName,
          });
        });
      });
      data.payload.cropCalendars.forEach((cropCalendar: any, index: number) => {
        const existingCropStageCalendar = dataCropStageCalendar.find(
          (stageCalendar: any) =>
            stageCalendar.calendarSystemName === cropCalendar.calendarName &&
            stageCalendar.stageSystemName === cropCalendar.stageSystemName
        );
        if (!existingCropStageCalendar) {
          cropCalendar.cropStagesCalendar.forEach((cropStageCalendar: any) => {
            dataCropStageCalendar.push({
              ...cropStageCalendar,
              applicable: cropStageCalendar.applicable ? "yes" : "no",
              cropSystemName: data.payload.cropName,
              calendarSystemName: cropCalendar.calendarName,
            });
          });
        }
        dataEnrollmentOptionsTranslation.push({
          cropSystemName: data.payload.systemName,
          translationSystemName: cropCalendar.practiceSystemName,
          type: "PRACTICE",
          translatedText: cropCalendar.practice,
          languageName: data.languageName,
          displayOrder: cropCalendar.displayOrder ?? index * 2 + 1,
        });
        dataEnrollmentOptionsTranslation.push({
          cropSystemName: data.payload.systemName,
          translationSystemName: cropCalendar.durationSystemName,
          type: "DURATION",
          translatedText: cropCalendar.duration,
          languageName: data.languageName,
          displayOrder: cropCalendar.displayOrder ?? index * 2 + 2,
        });
        const existingCropCalendar = dataCropCalendar.find(
          (calendar: any) => calendar.calendarName === cropCalendar.calendarName
        );
        if (!existingCropCalendar) {
          dataCropCalendar.push({
            ...cropCalendar,
            practice: cropCalendar.practiceSystemName,
            duration: cropCalendar.durationSystemName,
            cropSystemName: data.payload.systemName,
          });
        }
      });
    });
  };

  const prepareDataObj = (
    allDataCrop: any[],
    dataThreatsDetailsList: any[],
    dataStagesCrop: any[],
    dataFiltersValues: any[],
    dataFiltersKey: any[],
    dataCropStages: any[],
    dataStagesThreats: any[],
    dataCropThreats: any[],
    dataThreatFaq: any[],
    dataEnrollmentOptionsTranslation: any[],
    dataCropCalendar: any[],
    dataCropStageCalendar: any[]
  ) => {
    const columnsData = [
      {
        label: "Crop System Name",
        value: "systemName",
      },
      {
        label: "Display Label",
        value: "payload.cropName",
      },
      {
        label: "Language",
        value: "languageName",
      },
      {
        label: "Image Url",
        value: "payload.imageUrl",
      },
      {
        label: "Image Local Path",
        value: "payload.imageLocalPath",
      },
    ];

    const columnThreats = [
      {
        label: "Threat System Name",
        value: "threatSystemName",
      },
      {
        label: "Display Label",
        value: "threatDisplayLabel",
      },
      {
        label: "Language",
        value: "languageName",
      },
      {
        label: "Image Path",
        value: (row: any) => cropImageMedia(row.threatImagePath, storeData),
      },
    ];

    const columnStages = [
      {
        label: "Stage System Name",
        value: "systemName",
      },
      {
        label: "Display Label",
        value: "title",
      },
      {
        label: "Language",
        value: "languageName",
      },
      {
        label: "Image Path",
        value: (row: any) => cropImageMedia(row.imagePath, storeData),
      },
      {
        label: "Description",
        value: "description",
      },
      {
        label: "Display Order",
        value: "displayOrder",
      },
    ];

    const columnFiltersValue = [
      {
        label: "Filter Value System Name",
        value: "filterKeySystemName",
      },
      {
        label: "Filter Key System Name",
        value: "valueSystemName",
      },
      {
        label: "Display Label",
        value: "displayLabel",
      },
      {
        label: "Language",
        value: "languageName",
      },
      {
        label: "Display Order",
        value: "displayOrder",
      },
    ];

    const columnFiltersKey = [
      {
        label: "Filter Key System Name",
        value: "systemName",
      },
      {
        label: "Display Label",
        value: "filterName",
      },
      {
        label: "Language",
        value: "languageName",
      },
      {
        label: "Display Order",
        value: "displayOrder",
      },
    ];

    const columnCropStages = [
      {
        label: "Crop System Name",
        value: "cropSystemName",
      },
      {
        label: "Stage System Name",
        value: "systemName",
      },
      {
        label: "Display Order",
        value: "displayOrder",
      },
    ];

    const columnStagesThreats = [
      {
        label: "Stage System Name",
        value: "stageSystemName",
      },
      {
        label: "Threat System Name",
        value: "systemName",
      },
      {
        label: "Display Order",
        value: "displayOrder",
      },
    ];

    const columnCropThreats = [
      {
        label: "Crop System Name",
        value: "cropSystemName",
      },
      {
        label: "Threat System Name",
        value: "threatSystemName",
      },
      {
        label: "Display Order",
        value: "displayOrder",
      },
    ];

    const columnThreatFaq = [
      {
        label: "Threat System Name",
        value: "threatSystemName",
      },
      {
        label: "Language",
        value: "languageName",
      },
      {
        label: "Threat FAQ",
        value: "header",
      },
      {
        label: "Threat FAQ Answer",
        value: "text",
      },
      {
        label: "Display Order",
        value: "displayOrder",
      },
    ];

    const columnEnrollmentOptionsTranslations = [
      {
        label: "Crop System Name",
        value: "cropSystemName",
      },
      {
        label: "Translation System Name",
        value: "translationSystemName",
      },
      {
        label: "Type",
        value: "type",
      },
      {
        label: "Translated Text",
        value: "translatedText",
      },
      {
        label: "Language",
        value: "languageName",
      },
      {
        label: "Display Order",
        value: "displayOrder",
      },
    ];

    const columnCropCalendar = [
      {
        label: "Crop System Name",
        value: "cropSystemName",
      },
      {
        label: "Calendar System Name",
        value: "calendarName",
      },
      {
        label: "Generic",
        value: "generic",
      },
      {
        label: "Farming Practice System Name",
        value: "practice",
      },
      {
        label: "Crop Duration System Name",
        value: "duration",
      },
      {
        label: "DoS Applicable",
        value: "isDosApplicable",
      },
      {
        label: "DoT Applicable",
        value: "isDotApplicable",
      },
    ];

    const columnCropStageCalendar = [
      {
        label: "Calendar System Name",
        value: "calendarSystemName",
      },
      {
        label: "Stage System Name",
        value: "stageSystemName",
      },
      {
        label: "Is Applicable",
        value: "applicable",
      },
      {
        label: "Start Day",
        value: "startDay",
      },
      {
        label: "End Day",
        value: "endDay",
      },
      {
        label: "Reference (DoS/DoT)",
        value: "reference",
      },
      {
        label: "Display Order",
        value: "displayOrder",
      },
    ];

    return [
      {
        columns: columnsData,
        content: allDataCrop,
        sheet: "Crops",
      },
      {
        columns: columnThreats,
        content: dataThreatsDetailsList,
        sheet: "Threats",
      },
      {
        columns: columnStages,
        content: dataStagesCrop,
        sheet: "Stages",
      },
      {
        columns: columnFiltersValue,
        content: dataFiltersValues,
        sheet: "Filters Value - Translations",
      },
      {
        columns: columnFiltersKey,
        content: dataFiltersKey,
        sheet: "Filters Key - Translations",
      },
      {
        columns: columnCropStages,
        content: dataCropStages,
        sheet: "Crop Stages",
      },
      {
        columns: columnStagesThreats,
        content: dataStagesThreats,
        sheet: "Stages Threats",
      },
      {
        columns: columnCropThreats,
        content: dataCropThreats,
        sheet: "Crop Threats",
      },
      {
        columns: columnThreatFaq,
        content: dataThreatFaq,
        sheet: "Threat FAQ",
      },
      {
        columns: columnEnrollmentOptionsTranslations,
        content: dataEnrollmentOptionsTranslation,
        sheet: "Enrollment Options Translations",
      },
      {
        columns: columnCropCalendar,
        content: dataCropCalendar,
        sheet: "Crop Calendar",
      },
      {
        columns: columnCropStageCalendar,
        content: dataCropStageCalendar,
        sheet: "Crop Stage Calendar",
      },
    ];
  };

  /** Tab change handler */
  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setFilterObj(INITIAL_FILTER_STATE);
    setSortId("1");
    setSearchedValue("");
    setCurrentPage(0);
    setActiveTab(newValue);
    setCropsDataChecked([]);
  };

  const handleSearch = () => {
    switch (activeTab) {
      case 0: {
        fetchDataList();
        break;
      }
      case 1: {
        fetchMyUploads();
        break;
      }
    }
  };
  const showCreateCropModal = (flag: boolean) => {
    setActionType(ActionType.CREATE);
    setOpen(false);
    toggleNewCropModal(flag);
    if (!flag) {
      setSelectedCropInfo("");
    }
  };

  const showBulkUploadPopup = () => {
    setOpen(false);
    toggleBulkUploadView(true);
  };
  const getCropsColumns = () => {
    switch (activeTab) {
      case 0:
        return CropsTableColumnType;
      case 1:
        return CropsMyUploadsTableColumnType;
      default:
        return CropsTableColumnType;
    }
  };
  /**check all records handler */
  const checkOrUncheckAllRows = (data: Array<any>, status: boolean) => {
    const newArr = _.map(data, (o) => _.extend({ ...o }, { checked: status }));

    setCropsData([...newArr]);
    filterDataBasedOnPageChecked(currentPage, newArr);
  };
  /**
   * @description to check the all rows or perticular row
   */
  const selectAllRecords = (status: boolean) => {
    checkOrUncheckAllRows(cropsData, status);
  };

  const checkOrUncheckRow = (data: any, status: boolean) => {
    const filterData: any = cropsData.map((isdata: any) => {
      isdata.checked = false;

      if (isdata.id === data.id) {
        isdata.checked = status;
      }
      return isdata;
    });
    setCropsData([...filterData]);
    // filterDataBasedOnPageChecked(currentPage, filterData);
  };
  const selectEachByRow = (check: boolean, cropsData: any) => {
    checkOrUncheckRow(cropsData, check);
  };
  /**
   * @description An api call to fetch notifications
   */
  const successCallBack = (response: any, type: string, optionalData?: any) => {
    switch (type) {
      case "crops_data_list": {
        let listData: any = response.data;
        // setTotalRecords(listData?.totalRecords);
        setTotalPages(listData?.totalPages);
        let dataListToDisplay: any = [];

        listData.cropsList.forEach((row: any) => {
          /**updating notification data according to table columns */
          dataListToDisplay.push({
            id: row.id,
            title: row.nameTranslation,
            name: row.name,
            language: row?.languageName,
            image: row?.imageurl,
            lastUpdatedOn: formatDate(row?.lastUpdatedDate),
            isActive: row.isactive,
            languageId: row.languageId,
            statusId: row.statusId,
            imageLocalPath: row.imageLocalPath,
          });
        });
        // checkOrUncheckAllRows({ ...dataListToDisplay }, false);
        setCropsData([...dataListToDisplay]);
        break;
      }
      case "crops_upload_list": {
        let listData: any = response.data;
        setTotalPages(Math.ceil(listData.totalElements / limit));
        let myUploadListToDisplay: any = [];
        listData.uploadDocumentList.forEach((row: any) => {
          /**updating notification data according to table columns */
          myUploadListToDisplay.push({
            id: row.id,
            fileName: row.fileName,
            cropsUploadDate: moment(row?.lastUpdatedDate)
              .add(moment().utcOffset(), "m")
              .format("YYYY/MM/DD hh:mm a"),
            comnMyUploadStatus: row.status,
          });
        });

        checkOrUncheckAllRows(myUploadListToDisplay, false);
        break;
      }
      case "error_list": {
        if (response.statusCode === 200) {
          setErrorList(response.data);
        }
        break;
      }
    }
  };
  /**
   * @description A callback function triggered when an api is failed
   */
  const errorCallback = (error: any, type: string) => {
    TriggerToastMessage("Unable to process your request", "error");
  };

  const fetchDataList = () => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    let endPoint: string = api_endpoints.crops;
    apiData.payLoad = {
      pageNo: currentPage,
      pageSize: limit,
    };

    if (filterObj.languageIds.length > 0) {
      apiData.payLoad.languageIds = filterObj.languageIds
        .map((filterLngObj: any) => {
          return filterLngObj.split(":::")[1];
        })
        .join(",");
    }

    /**search payload */
    if (searchedValue) {
      apiData.payLoad.term = searchedValue.toLocaleLowerCase();
    }

    if (sortId) {
      apiData.payLoad.sort = sortObj.lastUpdatedOn === "1" ? "desc" : "asc";
    }
    apiData.endPoint = endPoint;
    apiData.showLoader = true;
    apiData.customHeaders = {
      "language-code": "",
    };
    /** prepare selected filter payload */
    GetCropsDataList(apiData, successCallBack, errorCallback);
  };
  const fetchMyUploads = () => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    let endPoint: string = api_endpoints.myupload_list;
    /**applying sortid to the query params */
    sortObj.lastUpdatedOn
      ? (endPoint += `?sortId=${sortObj.lastUpdatedOn}&pageNo=${currentPage}&pageSize=${limit}&dataType=${PublishModuleDD.CROPS}`)
      : (endPoint += `?pageNo=${currentPage}&pageSize=${limit}&dataType=${PublishModuleDD.CROPS}`);
    /**search payload */
    if (searchedValue) {
      endPoint += `&searchKey=${searchedValue}`;
    }
    apiData.endPoint = endPoint;
    apiData.showLoader = true;
    GetMyUploadList(apiData, successCallBack, errorCallback);
  };

  const changeStatusOfCrop = (status: boolean, systemName: string) => {
    if (
      confirm(
        "By changing the status of this crop all the other translated records will be updated, do you wish to continue ?"
      )
    ) {
      UpdateCropStatus(systemName, status)
        .then(() => {
          TriggerToastMessage("crop updated successfully", "success");
          fetchDataList();
        })
        .catch(() => {
          TriggerToastMessage("Unable to update status of crop", "error");
        });
    }
  };

  const getErrorListData = (id: number) => {
    let apiData = {} as IRequestBody;
    apiData.domain = base_url;
    let endPoint: string = api_endpoints.bulkupload_error_list;
    apiData.endPoint = endPoint.replace("__ID__", `${id}`);
    apiData.showLoader = true;
    /** payload */

    GetErrorList(apiData, successCallBack, errorCallback);
  };
  const renderPopupToCreateCrop = () => {
    return (
      <AddNewContentModal
        title={"Add New Data"}
        isOpen={open}
        onModalClose={() => setOpen(false)}
        onAddNew={() => {
          showCreateCropModal(true);
        }}
        onBulkUpload={() => {
          showBulkUploadPopup();
        }}
      />
    );
  };

  const showViewCrop = (rowInfo: any) => {
    toggleViewCropModal(true);
    setSelectedCropInfo(rowInfo);
  };
  const handleBulkClose = (currentTab: number) => {
    toggleBulkUploadView(false);
    setMyUploadData(undefined);
    if (currentTab) {
      setActiveTab(currentTab);
      fetchMyUploads();
    }
  };
  const renderBulkUploadForm = () => {
    return (
      <BulkUploadModal
        allowedTypes={["xls", "xlsx"]}
        maxFileSize={5}
        onClose={handleBulkClose}
        validationLiteral={PublishModuleDD.CROPS}
        templateLiteral={DOWNLOAD_SAMPLE_TEMPLATE_TYPE.CROP}
        reuploadId={myUploadData ? myUploadData.id : null}
        canByPassValidation={false}
      />
    );
  };

  const renderCreateEdit = () => {
    return (
      <CreateOrModifyCrop
        onClose={() => {
          showCreateCropModal(false);
          /** refresh DataList table */
          fetchDataList();
        }}
        isEdit={!!selectedCropInfo.id}
        id={selectedCropInfo.id}
        lngId={selectedCropInfo.languageId}
        sysNm={selectedCropInfo.name}
        actionType={actionType}
      />
    );
  };

  const renderViewModal = () => {
    return (
      <ViewCrop
        id={selectedCropInfo.id}
        lngId={selectedCropInfo.languageId}
        sysNm={selectedCropInfo.name}
        onCloseViewCrop={() => {
          setSelectedCropInfo("");
          toggleViewCropModal(false);
        }}
        onEditClick={(clickAction: string, details: any) =>
          handleActionClick(clickAction, details)
        }
      />
    );
  };
  const renderKnowMoreModal = () => {
    return (
      <KnowMorePopup
        handleKnowMoreClose={(flag: boolean) => {
          setOpenKnowMore(flag);
          setMyUploadData(undefined);
        }}
        title={myUploadData && myUploadData.fileName}
        tableHeaders={ErrorTableHeaders}
        tableData={errorList}
        openBulkUploadPopup={() => {
          toggleBulkUploadView(true);
          setOpenKnowMore(false);
        }}
      />
    );
  };
  const handleKnowMorePopup = (flag: boolean, rowData: any) => {
    setOpenKnowMore(flag);
    /** API call to get Error list */
    getErrorListData(rowData.id);
    /** using this state variable to store file name and pass it
     * to the ErrorListPopup
     */
    setMyUploadData(rowData);
  };

  const updateRowsPerPage = (value: number) => {
    setLimit(value);
  };
  const handleActionClick = (clickAction: string, details?: any) => {
    /** storing action type to send the information
     * to create/edit crop popup
     */
    switch (clickAction) {
      case ActionType.VIEW:
        setActionType(clickAction);
        showViewCrop(details);
        break;
      case ActionType.UPDATE:
      case ActionType.ADD_TRANSLATION:
        setActionType(clickAction);
        toggleNewCropModal(true);
        setSelectedCropInfo(details);
        break;
      case ActionType.CREATE:
        setOpen(true);
        break;
      default:
        handleExportToExcel();
        return;
    }
  };

  const fetchData = () => {
    switch (activeTab) {
      case 0: {
        fetchDataList();

        break;
      }
      case 1: {
        fetchMyUploads();
        break;
      }
    }
  };

  useEffect(() => {
    if (currentPage === 0) {
      fetchData();
    } else {
      setCurrentPage(0);
    }
  }, [limit]);

  /** all useEffects here */
  useEffect(() => {
    setCropsData([]);
    fetchData();
  }, [activeTab, filterObj, currentPage, sortObj]);

  useEffect(() => {
    /** If  searchedValue is empty than only reset the table data*/
    if (currentPage > 0) {
      setCurrentPage(0);
      return;
    }
    handleSearch();
  }, [searchedValue]);

  useEffect(() => {
    setCropsDataChecked([]);
  }, [limit]);

  useEffect(() => {
    if (cropsData.length < 1) return;
    replaceDataAlreadyExistWithChecked();
  }, [currentPage, cropsData]);

  useEffect(() => {
    checkExportToExcelAllowed("Crops")?.then((res) =>
      setButtonExportVisible(res)
    );
  }, []);

  return (
    <>
      <TableContainer>
        <div>
          <Stack
            direction="row"
            alignItems="center"
            className={classes.pageTitleNSearchStylesHelper}
          >
            <PageTitle title={props.title} />
            <SearchBar
              placeholderText="Search by Title"
              searchValue={searchedValue || ""}
              onSearchValueChange={(newSearchValue: string) => {
                setSearchedValue(newSearchValue);
              }}
              onEnterClick={() => {}}
            />
          </Stack>
          <Box>
            <Box className={classes.customTabBtmBorder}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <CustomTabs
                  value={activeTab}
                  tabsList={["Data List", "My uploads"]}
                  onChange={handleChange}
                />
                {activeTab === 1 && (
                  <DownLoadSampleTemplate
                    literal={DOWNLOAD_SAMPLE_TEMPLATE_TYPE.CROP}
                    displayAsButton={false}
                  />
                )}
              </Stack>
            </Box>
            {/* <TabPanel value={activeTab} index={0}> */}
            <Stack direction="row" className={classes.rowsPerPageStylesHelper}>
              <RowsPerPage
                pagSize={5}
                onChangeRowsPerPage={(data: number) => {
                  updateRowsPerPage(data);
                }}
              />
              <FilterAndSortRedux
                showFilter={activeTab === 0}
                initialFilter={INITIAL_FILTER_STATE}
                filters={{ ...filterObj }}
                onChangeFilters={(filter) => {
                  setFilterObj({
                    languageIds: filter.languageIds ?? [],
                  });
                }}
                initialSort={INITIAL_SORT}
                sort={{ ...sortObj }}
                onChangeSort={(sort) => {
                  setSortObj({
                    lastUpdatedOn: sort.lastUpdatedOn ?? "1",
                  });
                }}
              />
            </Stack>
            <Stack
              className={classes.chipBoxHelper}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              {/* <Stack direction="row" spacing={1}>
                {CustomChip('English')}
                {CustomChip('Hindi')}
              </Stack> */}
              {/* <Stack>
                <Button
                  variant="text"
                  color="error"
                  endIcon={<CloseOutlinedIcon />}
                  onClick={() => setFilterObj(INITIAL_FILTER_STATE)}
                >
                  Clear all
                </Button>
              </Stack> */}
            </Stack>
            {/* Table view */}
            <DataTable
              columns={getCropsColumns()}
              data={cropsData}
              enableSelection={activeTab === 0 && buttonExportVisible}
              singleSelection={activeTab === 0 && buttonExportVisible}
              pageNumber={1}
              onPageChange={() => {}}
              onSelectAll={selectAllRecords}
              onDeselectAll={() => {}}
              onRowSelect={selectEachByRow}
              isEditEnabled={activeTab === 0}
              isViewButton={activeTab === 0}
              onActionClick={(type, rowInfo) => {
                handleActionClick(type, rowInfo);
              }}
              onStatusChanged={(checked: boolean, data: any) =>
                changeStatusOfCrop(checked, data.name)
              }
              languageKey={"language"}
              hasTranslation={activeTab === 0}
              handleKnowMorePopup={(flag: boolean, rowData: any) =>
                handleKnowMorePopup(flag, rowData)
              }
            />
            {/* Bottom of list page (delete, pagination, plus icon) */}
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <DataTableActions
                actionsType={ACTION_TYPE.TYPE_CROP}
                buttonVisible={activeTab === 0}
                pageNumber={currentPage + 1}
                onPageChange={(selectedPageNo: number) =>
                  setCurrentPage(selectedPageNo - 1)
                }
                onActionClick={(data: string) => handleActionClick(data)}
                canShowAvatar={activeTab === 0}
                buttonExportVisible={buttonExportVisible}
                // buttonVisible={showDelete}
                activeTab={activeTab}
                totalpages={totalPages}
              />
            </Stack>
          </Box>
        </div>
      </TableContainer>
      {/* ------------------------- Manual/Bulk upload Modal--------------------------------------- */}
      {renderPopupToCreateCrop()}
      {/* ------------------------------ Bulk Upload Modal ---------------------------------------- */}
      {showBulkUploadModal && renderBulkUploadForm()}
      {/* ------------------------------ Add New Data Modal ---------------------------------------- */}
      {showCropModal && renderCreateEdit()}
      {/* ------------------------------ View Crop Info ---------------------------------------- */}
      {viewCropModal && renderViewModal()}
      {/* ------------------------------ Uploaded Crop Error List Info ---------------------------------------- */}
      {openKnowMore && renderKnowMoreModal()}
    </>
  );
};

export default Crop;

export const getIconImage = (key: string) => {
  switch (key) {
    case "ImgageUploader":
      return (
        <svg
          width="37"
          height="36"
          viewBox="0 0 37 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.5 0H4.5C2.3 0 0.5 1.8 0.5 4V32C0.5 34.2 2.28 36 4.5 36H32.5C34.7 36 36.5 34.2 36.5 32V4C36.5 1.8 34.7 0 32.5 0ZM32.5 32H4.5V26H11.62C13 28.38 15.56 30 18.52 30C21.48 30 24.02 28.38 25.42 26H32.5V32ZM22.52 22H32.5V4H4.5V22H14.52C14.52 24.2 16.32 26 18.52 26C20.72 26 22.52 24.2 22.52 22Z"
            fill="#0071CD"
          />
        </svg>
      );
    case "nineDots":
      return (
        <svg
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="1.5" cy="1.5" r="1.5" fill="#14151C" fillOpacity="0.5" />
          <circle cx="6.5" cy="1.5" r="1.5" fill="#14151C" fillOpacity="0.5" />
          <circle cx="11.5" cy="1.5" r="1.5" fill="#14151C" fillOpacity="0.5" />
          <circle cx="1.5" cy="6.5" r="1.5" fill="#14151C" fillOpacity="0.5" />
          <circle cx="6.5" cy="6.5" r="1.5" fill="#14151C" fillOpacity="0.5" />
          <circle cx="11.5" cy="6.5" r="1.5" fill="#14151C" fillOpacity="0.5" />
          <circle cx="1.5" cy="11.5" r="1.5" fill="#14151C" fillOpacity="0.5" />
          <circle cx="6.5" cy="11.5" r="1.5" fill="#14151C" fillOpacity="0.5" />
          <circle
            cx="11.5"
            cy="11.5"
            r="1.5"
            fill="#14151C"
            fillOpacity="0.5"
          />
        </svg>
      );
    default:
      break;
  }
};
