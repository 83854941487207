export type PublishColumnType = {
  fieldName: string;
  fieldLabel: string;
};

export const PublishedColumn: Array<PublishColumnType> = [
  {
    fieldLabel: "ID",
    fieldName: "id",
  },
  {
    fieldLabel: "Data Type",
    fieldName: "dataType",
  },
  {
    fieldLabel: "Language",
    fieldName: "languageName",
  },
  {
    fieldLabel: "Title",
    fieldName: "title",
  },
  {
    fieldLabel: "Last Updated On",
    fieldName: "modifiedDate",
  },
  {
    fieldLabel: "Modified By",
    fieldName: "modifiedBy",
  },
];

export const PublishedHistory: Array<PublishColumnType> = [
  {
    fieldLabel: "ID",
    fieldName: "id",
  },
  {
    fieldLabel: "Language",
    fieldName: "languageName",
  },
  {
    fieldLabel: "Title",
    fieldName: "title",
  },
  {
    fieldLabel: "Published On",
    fieldName: "modifiedDate",
  },
  {
    fieldLabel: "Modified By",
    fieldName: "modifiedBy",
  },
];

export const RetailerNurseriesColumns = [
  {
    fieldLabel: "Data Type",
    fieldName: "dataType",
  },
  {
    fieldLabel: "Name",
    fieldName: "name",
  },
  {
    fieldLabel: "Mobile Number",
    fieldName: "phoneNumber",
  },
  {
    fieldLabel: "Category",
    fieldName: "category",
  },
  {
    fieldLabel: "Last Updated On",
    fieldName: "modifiedDate",
  },
  {
    fieldLabel: "Modified By",
    fieldName: "modifiedBy",
  },
];

export const PlantixPeatidMappingColumns = [
  {
    fieldLabel: "Crop",
    fieldName: "title",
  },
  {
    fieldLabel: "Disease",
    fieldName: "diseaseName",
  },
  {
    fieldLabel: "CP System Name",
    fieldName: "name",
  },
  {
    fieldLabel: "Eppo Code",
    fieldName: "eppoCode",
  },
  {
    fieldLabel: "Peat ID",
    fieldName: "peatId",
  },
  {
    fieldLabel: "Display Order",
    fieldName: "productDisplayOrder"
  },
  {
    fieldLabel: "Last Updated On",
    fieldName: "modifiedDate",
  },
];

export const AdditionalPlantixMappingColumns = [
  {
    fieldLabel: "Child Disease Name",
    fieldName: "childDiseaseName",
  },
  {
    fieldLabel: "Child Disease Display Order",
    fieldName: "childDiseaseDisplayOrder",
  },
  {
    fieldLabel: "Child Disease Image URL",
    fieldName: "childDiseaseImageUrl",
  },
  {
    fieldLabel: "Language",
    fieldName: "languageName",
  },
];

export const PublishedPeatidMappingHistory: Array<PublishColumnType> = [
  {
    fieldLabel: "Crop",
    fieldName: "title",
  },
  {
    fieldLabel: "Disease",
    fieldName: "diseaseName",
  },
  {
    fieldLabel: "CP System Name",
    fieldName: "name",
  },
  {
    fieldLabel: "Published On",
    fieldName: "modifiedDate",
  },
  {
    fieldLabel: "Modified By",
    fieldName: "modifiedBy",
  },
];
