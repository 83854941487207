import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  Select: {
    '& ul': {
      maxHeight: '20vh',
    }
  },
  textAreaStyles: {
    borderColor: 'rgba(0,0,0,0.23)',
    borderRadius: '5px',
    color: '#696F88',
    resize: 'none',
    height: '96px !important',
    overflowY: 'scroll',
    outlineColor: '#0071CD',
    outlineWidth: '2px',
    borderWidth: '1px',
    backgroundColor: 'transparent',
    '&:hover': {
      borderColor: 'rgba(0, 0, 0, 0.87)'
    },

  },
  rmPicture: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  formPaper: {
    margin: '30px auto',
    width: '750px',
    height: '500px',
  },
  formContainer: {
    height: '400px',
    overflowY: 'auto',
    width:'850px',
    position: 'relative',
    scrollbarWidth: 'auto',
    paddingBottom: '20px',
    '&::-webkit-scrollbar': {
      width: '8px !important',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#CFD3DB',
      borderRadius: '15px',

    },
    '&::-webkit-scrollbar-track': {
      background: '#ffffff',
    },
    '& .MuiFormControl-root':{
      '& .MuiFormHelperText-root':{
        marginLeft:'0px'
      }
    }
  },
  formFieldsHelperOne: {
    maxWidth: '380px',
    margin: 'auto'
  },
  formFieldsHelper: {
    margin: 'auto',
    maxWidth: '430px'
  },
  rpPicture: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  formFieldsHelperDos: {
    marginLeft: '20px',
    maxWidth: '380px'
  },
  cropContainerHelper: {
    width: '100%'
  },
  tabContainerHelper: {
    paddingLeft: 16
  },
  tabHelper: {
    textTransform: 'none !important' as 'none'
  },
  panelBoxHelper: {
    display: 'inline-flex',
    alignItems: 'center',
    width: '-webkit-fill-available'
  },
  titleAndSearchHelper: {
    margin: '24px 24px auto',
    display: 'inline-flex',
    width: '-webkit-fill-available'
  },
  expandIconSelect: {
    position: 'absolute', right: '3px'
  },
  languageHelpTextForm: {
    marginLeft: '0', position: 'absolute', top: '65px', left: '460px'
  },
  formHelperTextMr: {
    marginLeft: '0 !important'
  },
  uploadImageAddIcon: {
    color: '#696F88'
  },
  buttonHelperNext: {
    marginRight: '.5rem',
    width: '91px'
  },
  uploadBtnContainerHelper: {
    "& .MuiButton-root": {
      whiteSpace: 'nowrap',
      height: '128px',
      border: '1px dashed #C2C7D0 ',
      color: '#14151C ',
      background: '#F3F4F6',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      "&:hover": {
        background: "#C2C7D0"
      }

    }
  },
  formUploadImageHelper: {
    whiteSpace: 'nowrap',
    height: '128px',
    '&.MuiButton-root': {
      background: '#F3F4F6',
      border: '1px dashed #C2C7D0',
      color: '#14151C',
      '&:hover': { background: '#C2C7D0' },
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

  },
  uploadImageFormatTextHelper: {
    fontSize: '11px !important'
  },
  modalFooterButtonHelper: {
    display: 'flex',
    flexDirection: 'row',
    padding: '1rem 1rem 0',
    marginBottom: '1rem',
    justifyContent: 'flex-end',

  },
  typographyFormHelper: {
    fontWeight: '400 !important',
    flex: 1
  },
  addedFeaturesTableColData: {
    fontWeight: '400 !important',
    flex: '1'
  },
  formMarginHelper: {
    marginTop: '8px !important'
  },
  formFiledMrHelper: {
    margin: '8px auto 0px auto !important'
  },
  formFooterBtnActionHepler: {
    width: 'fit-content',
    margin: '0px 0px auto auto',
    padding: 16
  },
  languagErrorMsgHepler: {
    position: 'absolute',
    left: '332px'
  },
  formContainerChildDivHelper:{
    margin: 'auto 20px'
  },
  formOutline:{
    outline:'none',
    paddingTop:'0.4rem'
  },
  lineHeightHelper:{
    lineHeight: 'unset'
  },
  uploadImgBtnIconColorHelper:{
    color: '#696F88'
  },
  formHelperTextFontSizeHelper:{
    fontSize: '11px !important'
  },
  leftPanel: {
    position: 'sticky',
    top: 0,
    background: 'white',
    zIndex: 10,
    padding: '10px',
  },
  scrollableList: {
    maxHeight: '350px', 
    overflowY: 'auto',
  },
  
})
