import { Box, Stack, TableContainer } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import api_endpoints, { base_url } from "../../Api/end_points";
import {
  ActionType,
  DOWNLOAD_SAMPLE_TEMPLATE_TYPE,
  ErrorTableHeaders,
  PublishModuleDD,
} from "../../Common/Constants";
import CustomTabs from "../../Common/CustomTabs";
import DataTable from "../../Common/DataTable";
import DataTableActions from "../../Common/DataTable/tableActions";
import { DownLoadSampleTemplate } from "../../Common/DownloadSampleTemplate";
import FilterAndSort from "../../Common/FilterAndSort";
import PageTitle from "../../Common/PageTitle";
import AddNewContentModal from "../../Common/Popup";
import { ACTION_TYPE } from "../../Common/RenderTableActions";
import RowsPerPage from "../../Common/RowsPerPage";
import SearchBar from "../../Common/SearchBar";
import { IRequestBody } from "../../Constants/interfaces";
import { RootState } from "../../redux/reducers";
import { TriggerToastMessage } from "../../Utils/toastTrigger";
import { GetErrorList } from "../Crop/CropsAPICalls";
import BulkUploadModal from "../RetailersAndNurseries/Modal/BulkUploadModal";
import KnowMorePopup from "./../../Common/KnowMoreErrorModal/knowMoreDetails";
import AddEditDashboard from "./AddEditDashboardModal";
import {
  DeleteDashboard,
  getDashboardMediaData,
  DashboardUploadList,
  viewDashboardMedia,
} from "./DashboardMediaApiCalls";
import {
  DashboardMediaColumns,
  DashboardMediaDeletedListTableColumnType,
  DashboardMediaMyUploadsTableColumnType,
} from "./DashboardMediaColumnData";
import { useStyles } from "./style";
import ViewDashboard from "./ViewDashboardMedia";
import { formatDate } from "../../Utility/DateFormat";
import _ from "../../Utils/lodash";
import { ConvertJSONToXLS } from "../../Utils/fileHelper";
import { S3_BUCKET_URL } from "../../Constants/contants";
import { checkExportToExcelAllowed, GetDeleteLog, PostDeleteLog } from "../../Api/generic_apicalls";
import { useManageCheckbox } from "../../hooks/useManageCheckbox";

interface ITabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface DeletedMediaData {
  languageName: string;
  payload: {
    category: string;
    title: string;
  };
}
interface DeleteLogPayload {
  languageName: string;
  title: string;
  category: string;
}

const TabPanel = (props: ITabPanelProps) => {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

export const DashboardMedia = (props: any) => {
  const storeData = useSelector((state: RootState) => {
    return {
      languages: state.generic?.ListOfLanguages?.languageList,
      primaryLanguageCode: state.auth.languageCode,
      countryCode: state.auth.countryCode,
    };
  });
  const INITIAL_FILTER_STATE = {
    languageIds: [],
    notificationTypes: [],
    status: [],
    frequencyIds: [],
    crop: [],
    category: [],
    mediaType: [],
  };
  const [searchValue, setSearchValue] = useState<string>("");
  const [activeTab, setActiveTab] = useState<number>(0);
  const [actionType, setActionType] = useState<any>("");
  const [dashboardData, setDashboardData] = useState<any>([]);
  const [dataToDisplayIsChecked, setDataToDisplayIsChecked] = useState<
    Array<any>
  >([]);

  const [sortId, setSortId] = useState("1");
  const [value] = useState(0);
  const [addDashboard, setAddDashboard] = useState(false);
  const [showViewDashboard, setShowViewDashboard] = useState(false);
  const [dashboardViewDetails, setDashboardViewDetails] = useState({});
  const [selectedFilters, setSelectedFilters] =
    useState<any>(INITIAL_FILTER_STATE);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [showDashboardModal, toggleNewDashboardModal] =
    useState<boolean>(false);
  const [selectedDashboardInfo, setSelectedDashboardInfo] = useState<any>("");
  const [showBulkUploadModal, toggleBulkUploadView] = useState(false);
  const [openKnowMore, setOpenKnowMore] = useState<boolean>(false);
  const [errorList, setErrorList] = useState<Array<any> | undefined>(undefined);
  const [myUploadData, setMyUploadData] = useState<any | undefined>(undefined);
  const [deleteLogsDashboard, setDeleteLogsDashboard] = useState<any[]>([]);

  const { filterDataBasedOnPageChecked } = useManageCheckbox({
    currentPage,
    dataToDisplayIsChecked,
    setDataToDisplayIsChecked,
    setDataToDisplay: setDashboardData,
  });

  /** variables creation end here */
  const [buttonExportVisible, setButtonExportVisible] =
    useState<boolean>(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
    setDataToDisplayIsChecked([]);
    if (newValue === 2) {
      fetchDashboardDeleteLogs();
    }
  };

  const handleSelectAll = (status: boolean) => {
    AssignCheckedFlag(dashboardData, status);
  };

  const selectEachByRow = (check: boolean, rowData: any) => {
    checkOrUncheckRow(rowData, check);
  };

  //check or uncheck row
  const checkOrUncheckRow = (data: any, status: boolean) => {
    const filterData: any = dashboardData.map((isdata: any) => {
      if (isdata.id === data.id && isdata.languageId === data.languageId) {
        isdata.checked = status;
      }

      return isdata;
    });

    setDashboardData(filterData);
    filterDataBasedOnPageChecked(currentPage, filterData);
  };

  const AssignCheckedFlag = (data: any, status: boolean) => {
    const filterData: any = data.map((isdata: any) => {
      isdata.checked = status;
      return isdata;
    });

    filterDataBasedOnPageChecked(currentPage, filterData);
  };

  // Filter data based on page checked

  const replaceDataAlreadyExistWithChecked = () => {
    if (dataToDisplayIsChecked.length < 1) return;

    const dataCheckedPage = dataToDisplayIsChecked.filter((data) => {
      return data.page === currentPage;
    });
    if (currentPage === dataCheckedPage?.[0]?.page) {
      setDashboardData(dataCheckedPage?.[0]?.data);
    }
  };

  const convertMatrictToArray = () => {
    const data = dataToDisplayIsChecked.map((data) => {
      return data.data;
    });
    return data.flat();
  };

  const getDetailData = async (data: any) => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.view_dashboard_media.replace(
      "__FEEDID__",
      data.id
    );
    if (props.isDraftRecord) {
      apiData.endPoint = api_endpoints.get_draft_media_content.replace(
        "__MEDIA_ID__",
        data.id
      );
    }

    return new Promise((resolve, reject) =>
      viewDashboardMedia(
        apiData,
        (response: any) => {
          resolve(response.data);
        },
        (error: any) => reject(new Error("Error", { cause: error }))
      )
    );
  };

  // Export to excel
  const handleExportToExcel = async () => {
    const dataArrayDisplay = convertMatrictToArray();
    console.log("dataArrayDisplay", dataArrayDisplay);

    const dataCpProductWithImageCrop = dataArrayDisplay.map(cropImageMedia);
    const selectedCpProductInfo = _.filter(dataCpProductWithImageCrop, {
      checked: true,
    });

    if (selectedCpProductInfo.length === 0) {
      TriggerToastMessage("Please select at least one Dashboard Media", "warn");
      return;
    }

    const allDetailedData = await Promise.all(
      selectedCpProductInfo.map(getDetailData)
    );

    const columnsData = [
      {
        label: "title",
        value: "payload.title",
      },
      {
        label: "feedurl",
        value: "payload.feedurl",
      },
      {
        label: "languageid",
        value: "payload.languageId",
      },
      {
        label: "uniquestateid",
        value: "payload.uniqueStateId",
      },
      {
        label: "thumbnail",
        value: "payload.thumbnail",
      },
      {
        label: "category",
        value: "payload.category",
      },
      {
        label: "type",
        value: "payload.type",
      },
    ];
    const dataObj = [
      {
        columns: columnsData,
        content: allDetailedData,
        sheet: "Dashboard Media Mapping",
      },
    ];
    let settings = {
      fileName: "DashboardMediaInfo_" + new Date().getTime(), // Name of the resulting spreadsheet
      extraLength: 3, // A bigger number means that columns will be wider
      writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
    };
    ConvertJSONToXLS(dataObj, settings);
  };

  const cropImageMedia = (data: any) => {
    const productMediaPath = data.productMediaPath?.split(
      `${S3_BUCKET_URL}/${storeData.countryCode}/`
    );
    return {
      ...data,
      productMediaPath: Array.isArray(productMediaPath)
        ? productMediaPath[1]
        : data.productMediaPath,
    };
  };

  const fetchDashboardContent = (): void => {
    const apiData: IRequestBody = {
      endPoint: api_endpoints.get_dashboard_content,
      domain: base_url,
      payLoad: {
        pageNo: currentPage,
        pageSize: limit,
        term: searchValue.toLocaleLowerCase(),
      },
    };

    if (selectedFilters.languageIds?.length > 0) {
      const languageIds = selectedFilters.languageIds.map(
        (languageObj: any) => languageObj.split(":::")[1]
      );
      apiData.payLoad.languageIds = languageIds.join(",");
    }

    if (selectedFilters.mediaType?.length > 0) {
      apiData.payLoad.types = selectedFilters.mediaType.join(",");
    }

    if (selectedFilters.category?.length > 0) {
      const filteredCategories = selectedFilters.category.filter(
        (item: string) => item !== "ALL"
      );
      if (filteredCategories.length > 0) {
        apiData.payLoad.categories = filteredCategories.join(",");
      }
    }

    if (sortId) {
      apiData.payLoad.sort = sortId === "1" ? "desc" : "asc";
    }

    getDashboardMediaData(apiData, successCallBack, errorCallback);
  };

  const successCallBack = (response: any, type: string) => {
    switch (type) {
      case "dashboard_data_list":
        handleDashboardDataListResponse(response);
        break;
      case "dashboard_upload_list":
        handleDashboardUploadListResponse(response);
        break;
      case "error_list":
        handleErrorListResponse(response);
        break;
      case "delete_dashboard":
        handleDeleteDashboardResponse(response);
        break;
      default:
        break;
    }
  };

  const handleDashboardDataListResponse = (response: any) => {
    const { feeds, totalElements } = response.data;
    const updatedData = feeds.map((row: any) => ({
      id: row.id,
      title: row.title,
      languageName: row.languageName,
      languageId: row.languageId,
      category: row.category,
      feedurl: row.feedurl,
      dashboardScheduleDate: `${row.fromDate}##${row.toDate}`,
      lastUpdated: formatDate(row?.lastupdated),
      smartGrowerDashboardId: row.smartGrowerDashboardId,
      stateName: row.stateName,
      image: row.thumbnail,
      displayOrder: row.displayOrder,
      startDate: row.startDate,
      endDate: row.endDate,
      statusId: row.statusId,
    }));
    setTotalRecords(totalElements);
    setDashboardData(updatedData);
  };

  const handleDashboardUploadListResponse = (response: any) => {
    const { uploadDocumentList, totalElements } = response.data;
    const myUploadListToDisplay = uploadDocumentList.map((row: any) => ({
      id: row.id,
      fileName: row.fileName,
      lastUpdatedDate: moment(row?.lastUpdatedDate)
        .add(moment().utcOffset(), "m")
        .format("YYYY/MM/DD hh:mm a"),
      comnMyUploadStatus: row.status,
    }));
    setTotalRecords(Math.ceil(totalElements / limit));
    setDashboardData(myUploadListToDisplay);
  };

  const handleErrorListResponse = (response: any) => {
    if (response.statusCode === 200) {
      setErrorList(response.data);
    }
  };

  const handleDeleteDashboardResponse = (response: any) => {
    if (response && response.statusCode === 200) {
      TriggerToastMessage(
        response.message
          ? response.message
          : "Dashboard Media deleted successfully",
        "success"
      );
    } else {
      TriggerToastMessage(
        response.message ? response.message : "Dashboard Media deletion failed",
        "error"
      );
    }
    fetchDashboardContent(); // Assuming fetchDashboardContent is defined elsewhere
  };

  const errorCallback = () => {
    TriggerToastMessage("Unable to fetch", "error");
  };
  const fetchMyUploads = () => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    let endPoint: string = api_endpoints.myUploads_dashboard_list;
    /**applying sortid to the query params */
    sortId
      ? (endPoint += `?sortId=${sortId}&pageNo=${currentPage}&pageSize=${limit}&dataType=${PublishModuleDD.DASHBOARD_MEDIA}`)
      : (endPoint += `?pageNo=${currentPage}&pageSize=${limit}&dataType=${PublishModuleDD.DASHBOARD_MEDIA}`);
    /**search payload */
    if (searchValue) {
      endPoint += `&searchKey=${searchValue}`;
    }
    apiData.endPoint = endPoint;
    apiData.showLoader = true;
    DashboardUploadList(apiData, successCallBack, errorCallback);
  };
  const deleteDashboardMedia = async (feedid: string) => {
    if (
      confirm(
        `Are you sure want to delete this record?`
      )
    ) {
      try {
        const deletedData = await getDetailData({ id: feedid }) as DeletedMediaData;

        const deleteRequest: IRequestBody = {
          domain: base_url,
          endPoint: api_endpoints.delete_dashboard_media.replace(
            "__FEEDID__",
            feedid
          ),
          showLoader: true,
        };

        const logPayload = {
          type: "DASHBOARD_MEDIA",
          payload: JSON.stringify({
            languageName: deletedData.languageName,
            category: deletedData.payload.category,
            title: deletedData.payload.title,

          }),
          isDraft: true, //need to fix
        };

        DeleteDashboard(
          deleteRequest,
          () => {
            successCallBack;
            const logRequest: IRequestBody = {
              domain: base_url,
              endPoint: api_endpoints.delete_logs,
              payLoad: {
                type: "DASHBOARD_MEDIA",
                payload: JSON.stringify(logPayload),
                isDraft: true,
              },
              showLoader: false,
            };

            PostDeleteLog(
              logRequest,
              (logResponse: any) => {
                TriggerToastMessage(
                  logResponse.message ? logResponse.message : "Delete log recorded successfully",
                  "success"
                );
              },
              (logError: any) => {
                TriggerToastMessage(
                  logError.message ? logError.message : "Failed to record delete log",
                  "error"
                );
              }
            );
          },
          (error: any) => {
            TriggerToastMessage(
              error.message ? error.message : "Dashboard Media deletion failed",
              "error"
            );
          }
        );

      } catch (error) {
        console.error("Error while deleting Dashboard Media:", error);
        TriggerToastMessage("Failed to delete Dashboard Media", "error");
      }
    }
  };

  const fetchDashboardDeleteLogs = () => {
    let apiData: IRequestBody = {
      domain: base_url,
      endPoint: api_endpoints.delete_logs,
      payLoad: { type: "DASHBOARD_MEDIA" },
      showLoader: true,
    };

    GetDeleteLog(apiData)
      .then((response: any) => {
        const logs = response?.data?.deleteLogs?.map((log: any) => {
          let parsedPayload: DeleteLogPayload = {
            languageName: "N/A",
            title: "N/A",
            category: "N/A",
          };

          try {
            const firstParse = JSON.parse(log.payload);
            parsedPayload = JSON.parse(firstParse.payload) as DeleteLogPayload;
          } catch (error) {
            console.error("Error parsing payload:", error);
          }

          return {
            ...log,
            languageName: parsedPayload.languageName,
            title: parsedPayload.title,
            category: parsedPayload.category,
            draft: log.draft ? "True" : "False",
          };
        });

        setDeleteLogsDashboard(logs);
      })
      .catch((error) => {
        console.error("Error fetching dashboard delete logs:", error);
        TriggerToastMessage("Failed to fetch dashboard delete logs", "error");
      });
  };

  const showCreateDashboardModal = (flag: boolean) => {
    setActionType(ActionType.CREATE);
    setOpen(false);
    toggleNewDashboardModal(flag);
    if (!flag) {
      setSelectedDashboardInfo("");
    }
  };

  const fetchData = () => {
    switch (activeTab) {
      case 0: {
        fetchDashboardContent();
        break;
      }
      case 1: {
        fetchMyUploads();
        break;
      }
      case 2: {
        fetchDashboardDeleteLogs();
        break;
      }
    }
  };
  const getDashboardColumns = () => {
    switch (activeTab) {
      case 0:
        return DashboardMediaColumns;
      case 1:
        return DashboardMediaMyUploadsTableColumnType;
      case 2:
        return DashboardMediaDeletedListTableColumnType;
      default:
        return DashboardMediaColumns;
    }
  };
  const showBulkUploadPopup = () => {
    setOpen(false);
    toggleBulkUploadView(true);
  };
  const renderPopupToCreateDashboardMedia = () => {
    return (
      <AddNewContentModal
        title={"Add New Data"}
        isOpen={open}
        onModalClose={() => setOpen(false)}
        onAddNew={() => {
          showCreateDashboardModal(true);
        }}
        onBulkUpload={() => {
          showBulkUploadPopup();
        }}
      />
    );
  };
  const handleSearchValueChange = (searchValue: string) => {
    setSearchValue(searchValue);
  };

  const handleSearch = () => {
    props.onSearch(searchValue);
  };
  /**
   * @description A Handler function, which updates the selected number of rows per page
   * @param value number
   */
  const onChangeOfRowsPerPage = (value: number) => {
    setLimit(value);
    setCurrentPage(0);
  };
  /**this method will handle all view click actions */
  const handleActionClick = (actionType: string, details?: any) => {
    switch (actionType) {
      case ActionType.VIEW:
        setShowViewDashboard(true);
        return setDashboardViewDetails(details);
      case "Create_Push":
        setActionType("");
        setOpen(true);
        return setAddDashboard(true);
      case ActionType.UPDATE:
        toggleNewDashboardModal(true);
        setActionType(ActionType.UPDATE);
        return setDashboardViewDetails(details);
      case "Delete":
        deleteDashboardMedia(details.id);
        break;
      case "Back":
        return props.onRefresh();
      default:
        handleExportToExcel();
        break;
    }
  };

  const renderBulkUploadForm = () => {
    return (
      <>
        <BulkUploadModal
          allowedTypes={["xls", "xlsx"]}
          maxFileSize={5}
          onClose={(currentTab: number) => {
            toggleBulkUploadView(false);
            setMyUploadData(undefined);
            if (currentTab) {
              setActiveTab(currentTab);
              fetchMyUploads();
            }
          }}
          validationLiteral={PublishModuleDD.DASHBOARD_MEDIA}
          templateLiteral={DOWNLOAD_SAMPLE_TEMPLATE_TYPE.DASHBOARD}
          canByPassValidation={true}
        />
      </>
    );
  };

  const renderKnowMoreModal = () => {
    return (
      <>
        <KnowMorePopup
          handleKnowMoreClose={(flag: boolean) => {
            setOpenKnowMore(flag);
            setMyUploadData(undefined);
          }}
          title={myUploadData && myUploadData.fileName}
          tableHeaders={ErrorTableHeaders}
          tableData={errorList}
          openBulkUploadPopup={() => {
            toggleBulkUploadView(true);
            setOpenKnowMore(false);
          }}
        />
      </>
    );
  };
  const getErrorListData = (id: number) => {
    let apiData = {} as IRequestBody;
    apiData.domain = base_url;
    let endPoint: string = api_endpoints.bulkupload_error_list;
    apiData.endPoint = endPoint.replace("__ID__", `${id}`);
    apiData.showLoader = true;
    /** payload */
    GetErrorList(apiData, successCallBack, errorCallback);
  };
  const handleKnowMorePopup = (flag: boolean, rowData: any) => {
    setOpenKnowMore(flag);
    /** API call to get Error list */
    getErrorListData(rowData.id);
    /** using this state variable to store file name and pass it
     * to the ErrorListPopup
     */
    setMyUploadData(rowData);
  };

  useEffect(() => {
    if (currentPage === 0) {
      fetchData();
      return;
    }
    setCurrentPage(0);
  }, [limit, searchValue, selectedFilters, activeTab, sortId]);

  useEffect(() => {
    fetchDashboardContent();
  }, [currentPage]);

  useEffect(() => {
    setDataToDisplayIsChecked([]);
  }, [limit]);

  useEffect(() => {
    if (dashboardData.length < 1) return;
    replaceDataAlreadyExistWithChecked();
  }, [currentPage, dashboardData]);

  useEffect(() => {
    checkExportToExcelAllowed("Dashboard Media")?.then((res) =>
      setButtonExportVisible(res)
    );
  }, []);

  const classes = useStyles();
  return (
    <>
      <TableContainer>
        <div>
          <Stack
            direction="row"
            alignItems="center"
            className={classes.pageTitleNSearchStylesHelper}
          >
            <PageTitle title="Dashboard Media" />
            <SearchBar
              placeholderText="Search by Video Title"
              searchValue={searchValue}
              onSearchValueChange={handleSearchValueChange}
              onEnterClick={handleSearch}
            />
          </Stack>
          <Box>
            <Box className={classes.customTabBtmBorder}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <CustomTabs
                  value={activeTab}
                  tabsList={["Data List", "My Uploads", "Deleted List"]}
                  onChange={handleChange}
                />
                {activeTab === 1 && (
                  <DownLoadSampleTemplate
                    literal={DOWNLOAD_SAMPLE_TEMPLATE_TYPE.DASHBOARD}
                    displayAsButton={false}
                  />
                )}
              </Stack>
            </Box>
            <TabPanel value={value} index={0}>
              <Stack
                direction="row"
                className={classes.rowsPerPageStylesHelper}
              >
                <RowsPerPage
                  pagSize={limit}
                  onChangeRowsPerPage={(value: any) =>
                    onChangeOfRowsPerPage(value)
                  }
                />
                <FilterAndSort
                  sortBy={sortId}
                  onSort={(sortType: any) =>
                    setSortId(sortType?.toString() ?? "1")
                  }
                  showLanguageSelection={true}
                  onChangeFilters={(data: any) => {
                    setSelectedFilters(data);
                  }}
                  showLastUpdatedOn={true}
                  filters={{ ...selectedFilters }}
                  showMediaType={true}
                  hasFilter={activeTab === 0}
                />
              </Stack>
              <DataTable
                columns={getDashboardColumns()}
                data={activeTab === 2 ? deleteLogsDashboard : dashboardData}
                enableSelection={activeTab === 0}
                pageNumber={1}
                onPageChange={() => { }}
                onSelectAll={handleSelectAll}
                onDeselectAll={() => { }}
                onRowSelect={selectEachByRow}
                isViewButton={activeTab === 0}
                onActionClick={handleActionClick}
                isEditEnabled={activeTab === 0}
                languageKey={"languageName"}
                isDeleteEnabled={activeTab === 0}
                handleKnowMorePopup={(flag: boolean, rowData: any) =>
                  handleKnowMorePopup(flag, rowData)
                }
              />
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <DataTableActions
                  pageNumber={currentPage + 1}
                  actionsType={ACTION_TYPE.TYPE_CP_PRODUCT}
                  onPageChange={(selectedPageNo: number) =>
                    setCurrentPage(selectedPageNo - 1)
                  }
                  onActionClick={handleActionClick}
                  activeTab={activeTab}
                  buttonVisible={activeTab === 0}
                  totalpages={Math.ceil(totalRecords / limit)}
                  canShowAvatar={activeTab === 0}
                  buttonExportVisible={activeTab === 0 && buttonExportVisible}
                />
              </Stack>
            </TabPanel>
            <TabPanel value={value} index={1}></TabPanel>
          </Box>
        </div>
      </TableContainer>

      {renderPopupToCreateDashboardMedia()}
      {/* ------------------------------ Dashboard Modals ---------------------------------------- */}
      {showViewDashboard && (
        <ViewDashboard
          canShowEditButton={activeTab === 0}
          isDraftRecord={false}
          dashboardMediaDetails={dashboardViewDetails}
          onCloseViewDashboard={() => setShowViewDashboard(false)}
          onEditClick={() => {
            handleActionClick(ActionType.UPDATE, dashboardViewDetails);
            setShowViewDashboard(false);
          }}
        />
      )}
      {showBulkUploadModal && renderBulkUploadForm()}
      {showDashboardModal && (
        <AddEditDashboard
          isDraftRecord={false}
          dashboardMediaDetails={dashboardViewDetails}
          onCloseCreateDashboardMedia={toggleNewDashboardModal}
          action={actionType}
          onCreateDashboard={() => { }}
          uniqueKey={0}
        />
      )}
      {openKnowMore && renderKnowMoreModal()}
    </>
  );
};

export default DashboardMedia;
