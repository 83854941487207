import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Stack,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { ErrorMessage, Field, Form, Formik, useField } from "formik";
import { useEffect, useState } from "react";
import { GetImageURLMappedWithCROP } from "../../../Api/generic_apicalls";
import ButtonWithIcon, {
  CANCEL_TYPE,
  CREATE_TYPE,
} from "../../../Common/Buttons";
import SortableComponent from "../../../Common/SortableRecords";
import { UploadFile } from "../../../Common/UploadFile";
import { checkForDuplicates } from "../../../Utils/genericUtils";
import { FeatureSchemaValidation } from "../HybridValidationSchema";
import { useStyles } from "../useStyle";
import { HybridFeaturesKeys, InitialHybridFeatures } from "./index";
import {
  ModuleImageUpload,
  STEPS,
  ValidationType,
} from "../../../Common/Constants";
import { genericFormFieldValidation } from "../../../Utils/fileHelper";

type HybridFeaturesFormPropTypes = {
  ListOfStages: Array<HybridFeaturesKeys>;
  selectedStage: HybridFeaturesKeys;
  onSelect: Function;
  onSave: Function;
  onRemove: Function;
  onNext: Function;
  onPrevious: Function;
  getLatestImgURL: Function;
  updateUniqueKey: Function;
  uniqueKey: number;
  updateListOrder: Function;
  isEdit: boolean;
  hybridSystemName: string;
  cropSystemName: string;
  actionType: string;
  isPrimaryProduct: boolean;
  isSystemNameEditable: boolean;
  canAddNewRecord: boolean;
  canUpdateExistingRecord: boolean;
};

const MyTextField = ({ label, ...props }: any) => {
  const classes = useStyles();
  const [field] = useField(props);

  return (
    <>
      <Typography variant="subtitle2" className={classes.label}>
        {`${label} *`}
      </Typography>
      <TextField {...field} {...props} fullWidth />
    </>
  );
};

const TextArea = ({ label, ...props }: any) => {
  const classes = useStyles();
  const [field] = useField(props);

  return (
    <>
      <Typography variant="subtitle2" className={classes.label}>
        {`${label} *`}
      </Typography>
      <TextareaAutosize
        {...field}
        {...props}
        className={classes.textAreaStyles}
      />
    </>
  );
};

const HybridFeaturesForm = (props: HybridFeaturesFormPropTypes) => {
  const classes = useStyles();
  const [isRuleErrorFlag, setIsRuleErrorFlag] = useState<boolean>(false);

  const nextStepHandler = (step: number) => {
    props.onNext(step);
  };

  useEffect(() => {
    props.updateUniqueKey();
  }, [props.selectedStage.systemName]);

  const renderAddedHybridFeatures = () => {
    if (props.ListOfStages.length === 0) {
      return (
        <Stack flex={1} width={400}>
          <Box>
            <Typography variant="subtitle2">Added Feature(s):</Typography>
            <Typography variant="caption" component="span">
              You may reorder the features by dragging each panel up or down
            </Typography>
            <FormHelperText error={true}>
              {isRuleErrorFlag && (
                <>{"Please add one hybrid feature to continue"}</>
              )}
            </FormHelperText>
          </Box>
        </Stack>
      );
    }

    return (
      <Stack flex={1} width={400}>
        <Box>
          <Typography variant="subtitle2">Added Feature(s):</Typography>
          <Typography variant="caption" component="span">
            You may reorder the features by dragging each panel up or down
          </Typography>
          <FormHelperText error={true}></FormHelperText>
          <Box my={2} className={classes.scrollableList}>
            <SortableComponent
              name={"Hybrid Feature"}
              items={props.ListOfStages}
              updateCropStageOrder={(
                newList: Array<HybridFeaturesFormPropTypes>
              ) => {
                props.updateListOrder(newList);
              }}
              key={props.ListOfStages.length}
              listProps={props}
              displayKey={"systemName"}
              initialState={InitialHybridFeatures}
              selectedRecord={props.selectedStage}
              secMatchKey={""}
              // isDisabled={
              //   !props.isPrimaryProduct && props.actionType !== ActionType.CREATE
              // }
              showRemoveButton={props.isPrimaryProduct}
              isDraggable={true}
            />
          </Box>
        </Box>
      </Stack>
    );
  };

  const submitGeneralDetails = (data: HybridFeaturesFormPropTypes) => {
    props.onSave(data);
    props.updateUniqueKey();
  };

  return (
    <Box>
      <Box className={classes.formContainer}>
        <div className={classes.formContainerChildDivStylesHepler}>
          <Stack direction="row" spacing={2}>
            <Formik
              initialValues={{
                displayOrder: props.selectedStage.displayOrder,
                heading: props.selectedStage.heading,
                id: props.selectedStage.id ?? null,
                media: props.selectedStage.media,
                systemName: props.selectedStage.systemName,
                text: props.selectedStage.text,
                imagePath: props.selectedStage.imagePath,
              }}
              validationSchema={FeatureSchemaValidation}
              enableReinitialize={true}
              onSubmit={(values: any, _onSubmitProps: any) => {
                if (
                  checkForDuplicates(
                    props.ListOfStages,
                    { systemName: values.systemName },
                    values.systemName,
                    props.selectedStage.systemName
                  )
                ) {
                  _onSubmitProps.setFieldError(
                    "systemName",
                    "Duplicate system name"
                  );
                  _onSubmitProps.setSubmitting(false);
                  return;
                }
                let featureData: any = { ...values };
                // check if image is a string or file object and upload to s3 if it is an object
                if (values.imagePath && typeof values.imagePath === "object") {
                  GetImageURLMappedWithCROP(
                    values.imagePath,
                    props.hybridSystemName,
                    ModuleImageUpload.HYBRID_FEATURE,
                    props.cropSystemName,
                    "hybridSystemName",
                    (response: any) => {
                      featureData.imagePath = response.data.imageUrl;
                      props.getLatestImgURL(response.data.imageUrl);
                      submitGeneralDetails(featureData);
                      _onSubmitProps.resetForm();
                    },
                    () => {
                      _onSubmitProps.setFieldError(
                        "imagePath",
                        "Unable to upload file."
                      );
                      _onSubmitProps.setSubmitting(false);
                      return;
                    }
                  );
                } else {
                  submitGeneralDetails(featureData);
                  _onSubmitProps.resetForm();
                }
              }}
            >
              {({ values, setFieldValue }) => (
                <Stack
                  flex={1}
                  justifyContent="flex-start"
                  direction="row"
                  gap={2}
                  width={400}
                  className={classes.leftPanel}
                >
                  <Form>
                    <Stack spacing={2} minWidth={350}>
                      <Box>
                        <Field
                          name="systemName"
                          validate={
                            props.selectedStage.systemName
                              ? null
                              : (value: string) =>
                                genericFormFieldValidation(
                                  value,
                                  ValidationType.SYS_NM
                                )
                          }
                        >
                          {() => {
                            return (
                              <FormControl fullWidth>
                                <Typography variant="subtitle2">
                                  Feature System Name *
                                </Typography>
                                <TextField
                                  name="systemName"
                                  onChange={(event: any) => {
                                    setFieldValue(
                                      "systemName",
                                      event.target.value
                                        .trim()
                                        .toLocaleUpperCase()
                                    );
                                  }}
                                  defaultValue={values.systemName}
                                  value={values.systemName}
                                  disabled={
                                    props.canAddNewRecord
                                      ? !!props.selectedStage.systemName
                                      : true
                                  }
                                />
                              </FormControl>
                            );
                          }}
                        </Field>
                        <FormHelperText error={true}>
                          <ErrorMessage name="systemName" />
                        </FormHelperText>
                        <MyTextField
                          name="heading"
                          type="text"
                          label="Feature Heading"
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setFieldValue("heading", event.target.value);
                          }}
                        />
                        <FormHelperText
                          error={true}
                          className={classes.languagErrorMsgHepler}
                        >
                          <ErrorMessage name="heading" />
                        </FormHelperText>

                        <TextArea
                          name="text"
                          as="textarea"
                          label="Feature Description"
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setFieldValue("text", event.target.value);
                          }}
                        />
                        <FormHelperText
                          error={true}
                          className={classes.languagErrorMsgHepler}
                        >
                          <ErrorMessage name="text" />
                        </FormHelperText>
                        <Field name="imagePath">
                          {() => {
                            return (
                              <UploadFile
                                name="imagePath"
                                acceptedFileTypes={[
                                  "image/png",
                                  "image/jpg",
                                  "image/jpeg",
                                ]}
                                maxFileSize={5}
                                imageUrl={values.imagePath}
                                onChange={(data: any) =>
                                  setFieldValue("imagePath", data)
                                }
                                key={values.imagePath}
                                canRemoveOrReplace={props.canAddNewRecord}
                              />
                            );
                          }}
                        </Field>
                        <FormHelperText
                          error={true}
                          className={classes.languagErrorMsgHepler}
                        >
                          <ErrorMessage name="imagePath" />
                        </FormHelperText>
                      </Box>
                      <Stack alignSelf="self-end" maxWidth={150}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="success"
                          data-testid="submit-feature"
                          disabled={
                            !(props.selectedStage.systemName
                              ? props.canUpdateExistingRecord
                              : props.canAddNewRecord)
                          }
                        >
                          {(props.selectedStage.systemName && "Update") ||
                            "Add Feature"}
                        </Button>
                      </Stack>
                    </Stack>
                  </Form>
                  <Divider orientation="vertical" flexItem />
                </Stack>
              )}
            </Formik>
            {renderAddedHybridFeatures()}
          </Stack>
        </div>
      </Box>
      <>
        <Divider />
        <div className={classes.formActionFooterBtnHelper}>
          <Stack direction="row" spacing={2}>
            <ButtonWithIcon
              showCreateProgress={false}
              type={CANCEL_TYPE}
              label="Previous"
              onClick={() => {
                props.onPrevious(STEPS.STEP1);
              }}
            />
            <ButtonWithIcon
              showCreateProgress={false}
              type={CREATE_TYPE}
              label="Next"
              onClick={() => {
                nextStepHandler(STEPS.STEP3);
              }}
            />
          </Stack>
        </div>
      </>
    </Box>
  );
};

export default HybridFeaturesForm;
