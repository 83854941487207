import React, { useCallback, useEffect } from "react";
import PlantixMapProductView from "./PlantixMapProduct.View";
import { ActionType, ErrorTableHeaders } from "../../Common/Constants";
import { IRequestBody } from "../../Constants/interfaces";
import api_endpoints, { base_url } from "../../Api/end_points";
import { api_service } from "../../Api/api_service";
import { TriggerToastMessage } from "../../Utils/toastTrigger";
import KnowMore from "../../Common/KnowMoreErrorModal/knowMoreDetails";
import { PlantixPeatidResponseObject } from "./types";
import ViewPlantixMapProduct from "./ViewPlantixMapProduct";
import { formatDate } from "../../Utility/DateFormat";
import _ from "../../Utils/lodash";
import { ConvertJSONToXLS } from "../../Utils/fileHelper";
import { checkExportToExcelAllowed, GetDeleteLog, PostDeleteLog } from "../../Api/generic_apicalls";
import useSharedState from "../../hooks/useStateShared";
import { useManageCheckbox } from "../../hooks/useManageCheckbox";

export const INITIAL_FILTER_STATE = {
  languageIds: [],
  status: [],
  notificationTypes: [],
  moduleNames: [],
  frequencyIds: [],
  crop: [],
  states: [],
  category: [],
  mediaType: [],
  retailerType: [],
  retailerCategory: [],
  responded: [],
};

const PlantixMapProduct: React.FC = () => {
  const [searchValue, setSearchValue] = React.useState("");
  const [activeTab, setActiveTab] = React.useState(0);
  const [limit, setLimit] = React.useState(5);
  const [sortId, setSortId] = React.useState<"0" | "1" | "">("1");
  const [filterObj, setFilterObj] = React.useState<any>(_.cloneDeep(INITIAL_FILTER_STATE));
  const [showAdditionalColumns, setShowAdditionalColumns] = React.useState(false);
  const {
    currentPage,
    setCurrentPage,
    totalPages,
    setTotalPages,
    open,
    setOpen,
    actionType,
    setActionType,
    showCreateOrEditModal,
    setShowCreateOrEditModal,
    showBulkUpload,
    setShowBulkUpload,
    dataToDisplay,
    setDataToDisplay,
    dataToDisplayIsChecked,
    setDataToDisplayIsChecked,
  } = useSharedState();

  const [myUploadData, setMyUploadData] = React.useState<any | undefined>(
    undefined
  );
  const [openKnowMore, setOpenKnowMore] = React.useState<boolean>(false);
  const [errorList, setErrorList] = React.useState<Array<any> | undefined>(
    undefined
  );

  const [showViewModal, setShowViewModal] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState<number | undefined>(
    undefined
  );

  const [buttonExportVisible, setButtonExportVisible] =
    React.useState<boolean>(false);

  const handleSearchValueChange = (newValue: string) => {
    setSearchValue(newValue);
  };

  const { convertMatrictToArray, filterDataBasedOnPageChecked, replaceDataAlreadyExistWithChecked } = useManageCheckbox({
    dataToDisplayIsChecked,
    setDataToDisplayIsChecked,
    currentPage,
    setDataToDisplay,
  })

  const AssignCheckedFlag = (status: boolean) => {
    const filterData: any = dataToDisplay.map((isdata: any) => {
      isdata.checked = status;
      return isdata;
    });

    filterDataBasedOnPageChecked(currentPage, filterData);
  };

  //check or uncheck row
  const checkOrUncheckRow = (status: boolean, data: any) => {
    const filterData: any = dataToDisplay.map((isdata: any) => {
      if (isdata.id === data.id) {
        isdata.checked = status;
      }

      return isdata;
    });

    setDataToDisplay(filterData);
    filterDataBasedOnPageChecked(currentPage, filterData);
  };


  // Export to excel
  const handleExportToExcel = () => {
    const { data, dataFromOtherPage } = convertMatrictToArray();

    const selectedCpProductInfo = _.filter(data, { checked: true });

    if (selectedCpProductInfo.length === 0) {
      TriggerToastMessage("Please select atleast one line to export", "warn");
      return;
    }

    const publishPendingStatus = selectedCpProductInfo.some(
      (value: any) => value.statusId === 5
    );

    if (publishPendingStatus) {
      if (!confirm("Exporting draft record for 'Publish Pending' item")) {
        return;
      }
    }

    if (dataFromOtherPage) {
      if (!confirm("CP Product from different page are selected")) {
        return;
      }
    }

    const baseColumns = [
      {
        label: "variety",
        value: "cropName",
      },
      {
        label: "peat_id",
        value: "peatId",
      },
      {
        label: "eppo_code",
        value: "eppoCode",
      },
      {
        label: "Product System name",
        value: "systemName",
      },
      {
        label: "disease_name",
        value: "diseaseName",
      },
      {
        label: "Display Order",
        value: "productDisplayOrder",
      },
    ];

    const additionalColumns = [
      {
        label: "child_disease_name",
        value: "childDiseaseName",
      },
      {
        label: "child_disease_display_order",
        value: "childDiseaseDisplayOrder",
      },
      {
        label: "child_disease_image_url",
        value: "childDiseaseImageUrl",
      },
      {
        label: "Language",
        value: "languageName",
      },
    ];

    const columnsData = [
      ...baseColumns,
      ...(showAdditionalColumns ? additionalColumns : []),
    ];

    const dataObj = [
      {
        columns: columnsData,
        content: selectedCpProductInfo,
        sheet: "General Info",
      },
    ];

    const settings = {
      fileName: "Plantix Map Product_" + new Date().getTime(), // Name of the resulting spreadsheet
      extraLength: 3, // A bigger number means that columns will be wider
      writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
    };

    ConvertJSONToXLS(dataObj, settings);
  };


  const handleChange = useCallback((event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
    // setFilterObj(INITIAL_FILTER_STATE);
    setSortId("1");
    setSearchValue("");
    setCurrentPage(0);
    setDataToDisplayIsChecked([]);
    if (newValue === 2) {
      fetchPlantixMapDeleteLogs();
    }
  }, [activeTab, filterObj, sortId, searchValue, currentPage]);

  const fetchChildDiseaseFlag = async () => {
    try {
      const apiData = {} as IRequestBody;
      apiData.endPoint = api_endpoints.attribute_api.replace(
        "__ATTRIBUTE__KEY__",
        "CHILD_DISEASE_FLAG"
      );

      const response = await api_service.get(apiData);
      setShowAdditionalColumns(response.data.data === "true");
    } catch (error) {
      console.error("Error checking child disease flag:", error);
      setShowAdditionalColumns(false);
    }
  };

  useEffect(() => {
    fetchChildDiseaseFlag();
  }, []);

  const handleClickAction = (clickAction: string, details?: any) => {
    switch (clickAction) {
      case ActionType.VIEW:
        setSelectedId(details?.id);
        setShowViewModal(true);
        break;
      case ActionType.DELETE:
        if (confirm("Are you sure want to delete?"))
          deletePublishedData(details?.id);
        break;
      case ActionType.CREATE:
        handleShowBulkUpload(true);
        break;
      default:
        handleExportToExcel();
        return;
    }
  };

  const handleKnowMorePopUp = (flag: boolean, rowData: any) => {
    setOpenKnowMore(flag);

    getErrorListData(rowData.id);

    setMyUploadData(rowData);
  };

  const getErrorListData = async (id: number) => {
    try {
      const apiData = {} as IRequestBody;
      apiData.domain = base_url;
      apiData.endPoint = api_endpoints.bulkupload_error_list.replace("__ID__", `${id}`);
      apiData.showLoader = true;

      const res = await api_service.get(apiData);
      if (res.status === 200) {
        setErrorList(res.data.data);
      }
    } catch (error) {
      TriggerToastMessage("Unable to process your request", "error");
    }
  };

  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  const handleCloseViewModal = () => {
    setShowViewModal(false);
  };

  const handleShowCreateOrEditModal = (toggle: boolean) => {
    if (!toggle) {
      // TODO
    }
    setActionType(ActionType.CREATE);
    setOpen(false);
    setShowCreateOrEditModal(toggle);
  };

  const handleShowBulkUpload = (toggle: boolean) => {
    setOpen(false);
    setShowBulkUpload(toggle);
  };

  const fetchDataList = async () => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.plantix_cp_mapping;
    apiData.payLoad = {
      pageNo: currentPage,
      pageSize: limit,
    };

    if (filterObj.crop.length > 0) {
      apiData.payLoad.crop = filterObj.crop
        .map((filterCropObj: any) => {
          return filterCropObj.split(":::")[0];
        })
        .join(",");
    }

    if (searchValue) {
      apiData.payLoad.term = searchValue.toLocaleLowerCase();
    }

    if (sortId) {
      apiData.payLoad.sort = sortId === "1" ? "desc" : "asc";
    }
    apiData.showLoader = true;

    try {
      const res = await api_service.get(apiData);
      if (res.status === 200) {
        const listData: PlantixPeatidResponseObject = res.data.data;
        const data: any[] = listData.products.map((item) => ({
          id: item.id,
          cropName: item.cropName,
          diseaseName: item.diseaseName,
          systemName: item.productSystemName,
          lastUpdatedOn: item.lastUpdatedDate
            ? formatDate(item.lastUpdatedDate)
            : null,
          eppoCode: item.eppoCode,
          peatId: item.peatId,
          productDisplayOrder: item.productDisplayOrder,
          childDiseaseName: item.childDiseaseName,
          childDiseaseDisplayOrder: item.childDiseaseDisplayOrder,
          childDiseaseImageUrl: item.childDiseaseImageUrl,
          languageName: item.languageName
        }));
        setTotalPages(listData.totalPages);
        setDataToDisplay(data);
      }
    } catch (error) {
      TriggerToastMessage("unable to process your request", "error");
    }
  };

  const fetchMyUploads = () => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.bulk_upload;
    apiData.payLoad = {
      dataType: "Plantix Peatid Mapping",
      pageNo: currentPage,
      pageSize: limit,
      sortId: parseInt(sortId),
    };

    if (searchValue) {
      apiData.payLoad.searchKey = searchValue.toLocaleLowerCase();
    }

    api_service
      .get(apiData)
      ?.then((res) => {
        let listData = res.data.data;
        let data: any[];

        data = listData.uploadDocumentList?.map((item: any) => {
          return {
            id: item.id,
            fileName: item.fileName,
            cropsUploadDate: formatDate(item.createdDate, "YYYY/MM/DD hh:mm a"),
            comnMyUploadStatus: item.status,
          };
        });

        setTotalPages(Math.ceil(listData.totalElements / listData.pageSize));
        setDataToDisplay(data);
      })
      .catch(() => {
        TriggerToastMessage("unable to process your request", "error");
      });
  };
  const fetchPlantixMapDeleteLogs = () => {
    let apiData: IRequestBody = {
      domain: base_url,
      endPoint: api_endpoints.delete_logs,
      payLoad: { type: "PLANTIX_MAP" },
      showLoader: true,
    };

    GetDeleteLog(apiData)
      .then((response: any) => {
        const logs = response?.data?.deleteLogs?.map((log: any) => {
          let parsedPayload = {
            cropName: "N/A",
            diseaseName: "N/A",
            systemName: "N/A",
            eppoCode: "N/A",
            peatId: "N/A",
          };

          try {
            parsedPayload = JSON.parse(log.payload);
          } catch (error) {
            console.error("Error parsing payload:", error);
          }

          return {
            ...log,
            cropName: parsedPayload.cropName || "N/A",
            diseaseName: parsedPayload.diseaseName || "N/A",
            systemName: parsedPayload.systemName || "N/A",
            eppoCode: parsedPayload.eppoCode || "N/A",
            peatId: parsedPayload.peatId || "N/A",
            draft: log.draft ? "True" : "False",
          };
        });

        setDataToDisplay(logs);
      })
      .catch((error) => {
        console.error("Error fetching delete logs:", error);
        TriggerToastMessage("Failed to fetch delete logs", "error");
      });
  };


  const getDetailDataPlantixMap = async (systemName: string) => {
    const apiData: IRequestBody = {
      domain: base_url,
      endPoint: api_endpoints.plantix_cp_mapping_detail.replace("__systemName__", systemName),
      payLoad: {
        application: "admin-portal",
      },
    };

    return new Promise((resolve, reject) => {
      api_service
        .get(apiData)
        ?.then((res) => {
          resolve(res.data.data.payload);
        })
        .catch((err) => {
          console.error("Error fetching detail data:", err);
          reject(err);
        });
    });
  };


  const deletePublishedData = async (systemName: string) => {
    try {
      const deletedData = (await getDetailDataPlantixMap(systemName)) as any;
      const apiData: IRequestBody = {
        domain: base_url,
        endPoint: api_endpoints.plantix_cp_mapping_delete.replace(
          "__systemName__",
          systemName
        ),
        showLoader: true,
      };

      const logPayload = {
        type: "PLANTIX_MAP",
        payload: JSON.stringify({
          cropName: deletedData.cropName || "N/A",
          diseaseName: deletedData.diseaseName || "N/A",
          systemName: deletedData.systemName || "N/A",
          eppoCode: deletedData.eppoCode || "N/A",
          peatId: deletedData.peatId || "N/A",
        }),
        isDraft: true, //need to fix
      };

      await api_service.delete(apiData);

      const logRequest: IRequestBody = {
        domain: base_url,
        endPoint: api_endpoints.delete_logs,
        payLoad: logPayload,
        showLoader: false,
      };

      PostDeleteLog(
        logRequest,
        (logResponse: any) => {
          TriggerToastMessage(
            logResponse.message ? logResponse.message : "Delete log recorded successfully",
            "success"
          );
        },
        (logError: any) => {
          TriggerToastMessage(
            logError.message ? logError.message : "Failed to record delete log",
            "error"
          );
        }
      );

      TriggerToastMessage("Deleted Successfully", "success");
      fetchData();

    } catch (error) {
      console.error("Error while deleting Plantix Map Product:", error);
      TriggerToastMessage("Unable to process your request", "error");
    }
  };

  useEffect(() => {
    if (currentPage === 0) {
      fetchData();
    } else {
      setCurrentPage(0);
    }
  }, [limit, searchValue, filterObj, sortId, activeTab]);

  useEffect(() => {
    setDataToDisplay([]);
    fetchData();
  }, [currentPage]);

  useEffect(() => {
    setDataToDisplayIsChecked([]);
  }, [limit]);

  useEffect(() => {
    if (dataToDisplay.length < 1) return;
    replaceDataAlreadyExistWithChecked();
  }, [currentPage, dataToDisplay]);

  useEffect(() => {
    checkExportToExcelAllowed("Plantix Map Product")?.then((res) =>
      setButtonExportVisible(res)
    );
  }, []);

  const fetchData = () => {
    setDataToDisplay([]);
    switch (activeTab) {
      case 0:
        // datalist
        fetchDataList();
        break;
      case 1:
        // myupload
        fetchMyUploads();
        break;
      case 2:
        fetchPlantixMapDeleteLogs();
        break;
      default:
        break;
    }
  };

  return (
    <>
      <PlantixMapProductView
        searchValue={searchValue}
        activeTab={activeTab}
        limit={limit}
        sortId={sortId}
        filterObj={filterObj}
        currentPage={currentPage}
        totalPages={totalPages}
        open={open}
        showCreateOrEditModal={showCreateOrEditModal}
        actionType={actionType}
        showBulkUpload={showBulkUpload}
        onSearchValueChange={handleSearchValueChange}
        onChange={handleChange}
        onClickAction={handleClickAction}
        onKnowMorePopUp={handleKnowMorePopUp}
        onOpenModal={handleOpenModal}
        onCloseModal={handleCloseModal}
        onSelectAll={AssignCheckedFlag}
        onRowSelect={checkOrUncheckRow}
        onShowCreateOrEditModal={handleShowCreateOrEditModal}
        onShowBulkUpload={handleShowBulkUpload}
        setLimit={setLimit}
        setSortId={setSortId}
        setFilterObj={(filters) => {
          console.log('Filters', filters)
          setFilterObj(filters)
        }}
        setCurrentPage={setCurrentPage}
        dataToDisplay={dataToDisplay}
        buttonExportVisible={buttonExportVisible}
        showAdditionalColumns={showAdditionalColumns}
      />
      {openKnowMore && (
        <KnowMore
          handleKnowMoreClose={(flag: boolean) => {
            setOpenKnowMore(flag);
            setMyUploadData(undefined);
          }}
          title={myUploadData && myUploadData?.fileName}
          tableHeaders={ErrorTableHeaders}
          tableData={errorList}
          openBulkUploadPopup={() => {
            setShowBulkUpload(true);
            setOpenKnowMore(false);
          }}
        />
      )}
      {showViewModal && (
        <ViewPlantixMapProduct
          onClose={handleCloseViewModal}
          selectedId={selectedId}
          showAdditionalColumns={showAdditionalColumns}
        />
      )}
    </>
  );
};

export default PlantixMapProduct;
