export type ThreatsColumnType = {
  fieldLabel: string;
  fieldName: string;
};

export const CpProductsDataList: Array<ThreatsColumnType> = [
  {
    fieldLabel: "Title",
    fieldName: "title",
  },
  {
    fieldLabel: "Language",
    fieldName: "languageName",
  },
  // {
  //   fieldLabel: 'Category',
  //   fieldName: 'category'
  // },
  {
    fieldLabel: "System Name",
    fieldName: "system",
  },
  {
    fieldLabel: "Crop",
    fieldName: "crop",
  },
  {
    fieldLabel: "Image",
    fieldName: "image",
  },
  {
    fieldLabel: "Last Updated On",
    fieldName: "lastUpdatedOn",
  },
];

export const CpProductsMyUploads: Array<ThreatsColumnType> = [
  {
    fieldLabel: "Filename",
    fieldName: "fileName",
  },
  {
    fieldLabel: "Uploaded On",
    fieldName: "lastUpdatedDate",
  },
  {
    fieldLabel: "Status",
    fieldName: "comnMyUploadStatus",
  },
];

export const CpProductsDeleteLog: Array<ThreatsColumnType> = [
  {
    fieldLabel: "Title",
    fieldName: "productTitle",
  },
  {
    fieldLabel: "Language",
    fieldName: "languageName",
  },
  {
    fieldLabel: "System Name",
    fieldName: "productSystemName",
  },
  {
    fieldLabel: "Type",
    fieldName: "type"
  },
  {
    fieldLabel: "Deleted By",
    fieldName: "deletedBy"
  },
  {
    fieldLabel: "Deleted At",
    fieldName: "deletedAt"
  },
  {
    fieldLabel: "Is Draft",
    fieldName: "draft"
  },
];
