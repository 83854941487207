export type HybridsTableColumnType = {
    fieldLabel: string;
    fieldName: string;
};

export const HybridsTableColumnType: Array<HybridsTableColumnType> = [
    {
        fieldLabel: 'Title',
        fieldName: 'title'
    },
    {
        fieldLabel: 'System Name',
        fieldName: 'systemName'
    },
    {
        fieldLabel: 'Language',
        fieldName: 'languageName'
    },
    {
        fieldLabel: 'Crop',
        fieldName: 'cropSystemName'
    },
    {
        fieldLabel: 'Image',
        fieldName: 'imagePath'
    },
    {
        fieldLabel: 'Last Updated On',
        fieldName: 'lastUpdatedOn'
    },

];

export const HybridsMyUploadsTableColumnType: Array<HybridsTableColumnType> = [
    {
        fieldLabel: 'File Name',
        fieldName: 'fileName'
    },
    {
        fieldLabel: 'Uploaded On',
        fieldName: 'cropsUploadDate'
    },
    {
        fieldLabel: 'Status',
        fieldName: 'comnMyUploadStatus'
    }

];
export const HybridsDeletedListTableColumnType: Array<HybridsTableColumnType> = [
    {
        fieldLabel: 'Title',
        fieldName: 'title'
    },
    {
        fieldLabel: 'System Name',
        fieldName: 'systemName'
    },
    {
        fieldLabel: 'Language',
        fieldName: 'language'
    },
    {
        fieldLabel: 'Crop',
        fieldName: 'crop'
    },
    {
        fieldLabel: "Type",
        fieldName: "type"
    },
    {
        fieldLabel: "Deleted By",
        fieldName: "deletedBy"
    },
    {
        fieldLabel: "Deleted At",
        fieldName: "deletedAt"
    },
    {
        fieldLabel: "Is Draft",
        fieldName: "draft"
    },
];