import React from "react";
import { DOWNLOAD_SAMPLE_TEMPLATE_TYPE } from "../../Common/Constants";
import { ACTION_TYPE } from "../../Common/RenderTableActions";
import { filterObjType } from "./PlantixCPProducts";
import ViewPlantixCPProduct from "./ViewPlantixCPProduct";
import PlantixView from "../PlantixMapProduct/PlantixView";

interface PlantixCPProductsProps {
  searchValue: string;
  activeTab: number;
  limit: number;
  sortId: "" | "0" | "1";
  filterObj: filterObjType;
  currentPage: number;
  totalPages: number;
  open: boolean;
  showCreateOrEditModal: boolean;
  actionType: string;
  showBulkUpload: boolean;
  dataToDisplay: any[];
  showViewModal: boolean;
  onSearchValueChange: (newValue: string) => void;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
  onClickAction: (clickAction: string, detail?: any) => void;
  onKnowMorePopUp: (flag: boolean, rowData: any) => void;
  onCloseModal: () => void;
  onShowCreateOrEditModal: (toggle: boolean) => void;
  onShowBulkUpload: (toggle: boolean) => void;
  setLimit: React.Dispatch<React.SetStateAction<number>>;
  setSortId: React.Dispatch<React.SetStateAction<"" | "0" | "1">>;
  setFilterObj: React.Dispatch<React.SetStateAction<filterObjType>>;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  setShowViewModal: React.Dispatch<React.SetStateAction<boolean>>;
  onSelectAll: (status: boolean) => void;
  onRowSelect: (status: boolean, rowInfo: any) => void;
  buttonExportVisible?: boolean;
}

type ColumnTypes = {
  fieldLabel: string;
  fieldName: string;
};

const DataListColumns: ColumnTypes[] = [
  {
    fieldLabel: "System Name",
    fieldName: "systemName",
  },
  {
    fieldLabel: "Language",
    fieldName: "languageName",
  },
  {
    fieldLabel: "Last Updated On",
    fieldName: "lastUpdatedOn",
  },
];

const MyUploadColumns: ColumnTypes[] = [
  {
    fieldLabel: "File Name",
    fieldName: "fileName",
  },
  {
    fieldLabel: "Uploaded On",
    fieldName: "cropsUploadDate",
  },
  {
    fieldLabel: "Status",
    fieldName: "comnMyUploadStatus",
  },
];

const DeleteListColumns: ColumnTypes[] = [
  {
    fieldLabel: "System Name",
    fieldName: "productSystemName",
  },
  {
    fieldLabel: "Language",
    fieldName: "languageName",
  },
  {
    fieldLabel: "Type",
    fieldName: "type"
  },
  {
    fieldLabel: "Deleted By",
    fieldName: "deletedBy"
  },
  {
    fieldLabel: "Deleted At",
    fieldName: "deletedAt"
  },
  {
    fieldLabel: "Is Draft",
    fieldName: "draft"
  },
];

const PlantixCPProductsView: React.FC<PlantixCPProductsProps> = (props) => {
  const getDataColumns = () => {
    switch (props.activeTab) {
      case 0:
        return DataListColumns;
      case 1:
        return MyUploadColumns;
      case 2:
        return DeleteListColumns;
      default:
        return [];
    }
  };

  return (
    <PlantixView
      typePlantix={'cp'}
      searchValue={props.searchValue}
      activeTab={props.activeTab}
      limit={props.limit}
      sortId={props.sortId}
      filterObj={props.filterObj}
      currentPage={props.currentPage}
      totalPages={props.totalPages}
      open={props.open}
      showCreateOrEditModal={props.showCreateOrEditModal}
      actionType={props.actionType}
      showBulkUpload={props.showBulkUpload}
      dataToDisplay={props.dataToDisplay}
      showViewModal={props.showViewModal}
      onSearchValueChange={props.onSearchValueChange}
      onChange={props.onChange}
      onClickAction={props.onClickAction}
      onKnowMorePopUp={props.onKnowMorePopUp}
      onCloseModal={props.onCloseModal}
      onShowCreateOrEditModal={props.onShowCreateOrEditModal}
      onShowBulkUpload={props.onShowBulkUpload}
      setLimit={props.setLimit}
      setSortId={props.setSortId}
      setFilterObj={props.setFilterObj}
      setCurrentPage={props.setCurrentPage}
      setShowViewModal={props.setShowViewModal}
      onSelectAll={props.onSelectAll}
      onRowSelect={props.onRowSelect}
      buttonExportVisible={props.buttonExportVisible}
      tabsList={["Data List", "My uploads", "Deleted List"]}
      dataColumns={getDataColumns()} 
      downloadLiteral={DOWNLOAD_SAMPLE_TEMPLATE_TYPE.HYBRID}
      actionsType={ACTION_TYPE.TYPE_CROP}
      viewModalComponent={<ViewPlantixCPProduct onClose={() => props.setShowViewModal(false)} />}
    />
  );
};

export default PlantixCPProductsView;