import AddIcon from "@mui/icons-material/Add";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import PublishOutlinedIcon from "@mui/icons-material/PublishOutlined";
import { Box, FormControl, FormHelperText, TextareaAutosize, TextField, } from "@mui/material";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { ChangeEvent, useEffect, useState } from "react";
import api_endpoints, { base_url } from "../../../Api/end_points";
import { GetFileS3BucketURL } from "../../../Api/generic_apicalls";
import { ActionType, ModuleImageUpload, STEPS, ValidationType, } from "../../../Common/Constants";
import { CP_S3ImageUploadType } from "../../../Common/Constants/types";
import SortableComponent from "../../../Common/SortableRecords";
import { IRequestBody } from "../../../Constants/interfaces";
import { checkForDuplicates, clearImageObject, } from "../../../Utils/genericUtils";
import { TriggerToastMessage } from "../../../Utils/toastTrigger";
import { FeaturesType, initialFeature } from "./";
import { genericFormFieldValidation, genericValidateImage, } from "../../../Utils/fileHelper";
import { useStyles } from "./styles";
import { ValidationSchemaFeatures } from "./validationSchema";

const Input = styled("input")({
  display: "none",
  height: "30px",
});

interface FeaturesProps {
  onNext: Function;
  onPrevious: Function;
  updateListOrder: Function;
  listOfFeatures: Array<FeaturesType>;
  selectedFeature: FeaturesType;
  onSave: Function;
  onRemove: Function;
  onSelect: Function;
  updateUniqueKey: Function;
  uniqueKey: number;
  productSystemName: string;
  isPrimaryCP: boolean;
  actionType: string;
  cropSystemName: string;
  getLatestImgURL: Function;
  isEdit: boolean;
  isSystemNameEditable: boolean;
  canAddNewRecord: boolean;
  canUpdateExistingRecord: boolean;
}



const Features: React.FC<FeaturesProps> = (props) => {
  // variables
  const classes = useStyles();

  const [isRuleErrorFlag, setIsRuleErrorFlag] = useState<boolean>(false);

  /** Drag and Drop Menu lsiting */
  const renderFeatureList = () => {
    return (
      <>
        <SortableComponent
          name={"Features"}
          items={props.listOfFeatures}
          updateCropStageOrder={(newList: Array<FeaturesType>) => {
            props.updateListOrder(newList);
          }}
          key={props.listOfFeatures.length}
          listProps={props}
          displayKey={"systemName"}
          initialState={initialFeature}
          selectedRecord={props.selectedFeature}
          isDisabled={
            !props.isPrimaryCP && props.actionType !== ActionType.CREATE
          }
        />
      </>
    );
  };
  /** Filed level validation for Image file upload */
  const validateImage = (value: any, name: any) => {
    /** For image upload mandatory */
    // let errorResult = genericValidateImage(value);
    // return errorResult;

    /** For Img upload is optional,
     * but when Img uploaded, check for for other image validation
     */
    let errorResult = genericValidateImage(value);
    if (errorResult === "Image required") {
      /* Image field is optional **/
      return "";
    } else {
      return errorResult;
    }
  };
  /** Clear selected image */

  const getFileUploadPayload = (file: any, data: CP_S3ImageUploadType) => {
    let fileNameArr: any = file.name.split(".");
    let s3Data: any = {
      productSystemName: props.productSystemName?.replaceAll(" ", ""),
      type: data.type,
      fileNameWithExtension: `${data.productSystemName?.replaceAll(" ", "")}.${fileNameArr[fileNameArr.length - 1]
        }`,
      cropSystemName: props.cropSystemName,
    };
    return s3Data;
  };

  const getFileS3URL_Save = (values: any) => {
    /** fetching image S3 url from api and saving the stage after success */
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.fileupload_S3bucket;
    apiData.showLoader = true;
    if (values.imagePath && typeof values.imagePath !== "string") {
      /** generating crop stage growth related payload */
      let imgData: any = {
        productSystemName: values.systemName,
        cropSystemName: values.crop,
        type: ModuleImageUpload.CP_FEATURE,
      };
      let payload = getFileUploadPayload(values.imagePath, imgData);
      let formData: any = new FormData();
      formData.append("file", values.imagePath);
      formData.append("data", JSON.stringify(payload));
      apiData.payLoad = formData;
      return GetFileS3BucketURL(
        apiData,
        (response: any, type: string, imgType: string) => {
          /** SUCCESS CALLBACK */
          let imgUrl: string = response.data.imageUrl;
          /** storing latest img file urls do delete if not saved */
          props.getLatestImgURL(imgUrl);
          values.imagePath = imgUrl;
          props.onSave(values);
        },
        (_error: any, type: string, imgType: string) => {
          /** ERROR CALLBACK */
          TriggerToastMessage(
            "Unable to process your request!!!" + " - " + type,
            "error"
          );
        },
        ModuleImageUpload.CP_FEATURE
      );
    } else {
      /** if no change in image, direct save the record */
      props.onSave(values);
    }
  };
  /** Getting latest unique key to see latest
   * selected record for edit
   */
  useEffect(() => {
    props.updateUniqueKey();
  }, [props.selectedFeature.systemName]);

  return (
    <Formik
      initialValues={{
        systemName: props.selectedFeature.systemName,
        header: props.selectedFeature.header,
        description: props.selectedFeature.description,
        imagePath: props.selectedFeature.imagePath,
      }}
      validationSchema={ValidationSchemaFeatures}
      onSubmit={(values: any, _onSubmitProps: any) => {
        /** submit form */
        if (
          checkForDuplicates(
            props.listOfFeatures,
            { systemName: values!.systemName },
            values!.systemName,
            props.selectedFeature.systemName
          )
        ) {
          _onSubmitProps.setFieldError("systemName", "Duplicate system name");
          _onSubmitProps.setSubmitting(false);
          return;
        } else {
          _onSubmitProps.setSubmitting(false);
          getFileS3URL_Save(values);
          clearImageObject("upload-img");
          _onSubmitProps.resetForm();
          props.updateUniqueKey();
          setIsRuleErrorFlag(false);
        }
      }}
      enableReinitialize={true}
    >
      {({ values, setFieldValue }) => (
        <>
          <Form className={classes.formOutline}>
            <Box className={classes.formContainer}>
              <Box>
                <div className={classes.formContainerChildDivHelper}>
                  <Stack direction="row" spacing={2}>
                    <Stack
                      flex={1}
                      width={400}
                      justifyContent="flex-start"
                      borderRight="1px solid #C2C7D0"
                      paddingRight={2}
                      className={classes.leftPanel}
                    >
                      <Field
                        name="systemName"
                        validate={
                          props.selectedFeature.systemName
                            ? null
                            : (value: string) =>
                              genericFormFieldValidation(
                                value,
                                ValidationType.SYS_NM
                              )
                        }
                      >
                        {() => {
                          return (
                            <FormControl
                              className={classes.formMarginHelper}
                              fullWidth
                            >
                              <Typography variant="subtitle2">
                                Feature System Name *
                              </Typography>
                              <TextField
                                name="systemName"
                                onChange={(event: any) => {
                                  setFieldValue(
                                    "systemName",
                                    event.target.value
                                      .trim()
                                      .toLocaleUpperCase()
                                  );
                                }}
                                value={values.systemName}
                                defaultValue={values.systemName}
                                disabled={
                                  props.canAddNewRecord
                                    ? !!props.selectedFeature.systemName
                                    : true
                                }
                              />
                            </FormControl>
                          );
                        }}
                      </Field>
                      <FormHelperText error={true}>
                        <ErrorMessage name="systemName" />
                      </FormHelperText>
                      <Field name="header">
                        {() => {
                          return (
                            <FormControl
                              className={classes.formMarginHelper}
                              fullWidth
                            >
                              <Typography variant="subtitle2">
                                Feature Heading *
                              </Typography>
                              <TextField
                                name="header"
                                value={values.header}
                                onChange={(event: any) => {
                                  setFieldValue("header", event.target.value);
                                }}
                              />
                            </FormControl>
                          );
                        }}
                      </Field>
                      <FormHelperText error={true}>
                        <ErrorMessage name="header" />
                      </FormHelperText>
                      <Field name="description">
                        {() => {
                          return (
                            <FormControl
                              className={classes.formMarginHelper}
                              fullWidth
                            >
                              <Typography variant="subtitle2">
                                Feature Description *
                              </Typography>
                              <TextareaAutosize
                                onBlur={(event) => {
                                  setFieldValue(
                                    "description",
                                    event.target.value
                                  );
                                }}
                                name={'description'}
                                className={classes.textAreaStyles}
                                maxLength={1024}
                                defaultValue={values.description}
                                key={values?.description}
                              />
                            </FormControl>
                          );
                        }}
                      </Field>
                      <FormHelperText error={true}>
                        <ErrorMessage name="description" />
                      </FormHelperText>
                      <Field
                        name="imagePath"
                        validate={(value: any) =>
                          validateImage(value, values.systemName)
                        }
                      >
                        {() => {
                          return (
                            <Stack>
                              <Typography
                                variant="subtitle2"
                                fontSize={14}
                                component="span"
                                width="103px"
                              >
                                Feature Image
                              </Typography>
                              <Stack
                                direction="row"
                                alignItems="flex-start"
                                justifyContent={
                                  props.canAddNewRecord ? "space-between" : ""
                                }
                                spacing={1}
                                marginTop={3}
                              >
                                <Stack>
                                  <label
                                    htmlFor="contained-button-file"
                                    id="upload-img"
                                  >
                                    <Input
                                      accept=".jpg, .png, .jpeg"
                                      id="contained-button-file"
                                      // multiple
                                      type="file"
                                      onChange={(
                                        event: ChangeEvent<HTMLInputElement>
                                      ) => {
                                        let imgFile: any =
                                          event.target.files &&
                                          event.target.files[0];
                                        setFieldValue("imagePath", imgFile);
                                      }}
                                      disabled={!props.canAddNewRecord}
                                    />
                                    {/* Displaying image either by Raw data or by using URL */}
                                    {values.imagePath && (
                                      <img
                                        alt="Feature"
                                        src={
                                          typeof values.imagePath !== "string"
                                            ? `${URL.createObjectURL(
                                              values.imagePath
                                            )}`
                                            : `${values.imagePath}`
                                        }
                                        width="128px"
                                        height="128px"
                                      />
                                    )}
                                    {!values.imagePath && (
                                      <Button
                                        variant="contained"
                                        size="small"
                                        component="span"
                                        disableRipple
                                        disableElevation
                                        disableTouchRipple
                                        className={
                                          classes.formUploadImageHelper
                                        }
                                      >
                                        <AddIcon
                                          className={
                                            classes.uploadImgBtnIconColorHelper
                                          }
                                        />{" "}
                                        Upload an image
                                      </Button>
                                    )}
                                  </label>
                                </Stack>
                                {props.canAddNewRecord && (
                                  <Stack spacing={2}>
                                    <FormHelperText
                                      className={
                                        classes.formHelperTextFontSizeHelper
                                      }
                                    >
                                      JPG, JPEG or PNG. Max size of 5 MB
                                    </FormHelperText>
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      justifyContent="center"
                                    >
                                      <PublishOutlinedIcon fontSize="inherit" />
                                      <Typography variant="body2">
                                        <label
                                          className={classes.rpPicture}
                                          htmlFor="contained-button-file2"
                                        >
                                          {values.imagePath && (
                                            <Input
                                              accept=".jpg, .png, .jpeg"
                                              id="contained-button-file2"
                                              // multiple
                                              type="file"
                                              name="replace-image"
                                              onChange={(
                                                event: ChangeEvent<HTMLInputElement>
                                              ) => {
                                                let imgFile: any =
                                                  event.target.files &&
                                                  event.target.files[0];
                                                setFieldValue(
                                                  "imagePath",
                                                  imgFile
                                                );
                                              }}
                                              disabled={!props.canAddNewRecord}
                                            />
                                          )}
                                          Replace picture
                                        </label>
                                      </Typography>
                                    </Stack>
                                    <Stack
                                      className={classes.rmPicture}
                                      direction="row"
                                      alignItems="center"
                                      justifyContent="center"
                                      onClick={() => {
                                        if (props.canAddNewRecord) {
                                          setFieldValue("imagePath", "");
                                          clearImageObject("upload-img");
                                        }
                                      }}
                                    >
                                      <DeleteOutlinedIcon fontSize="inherit" />
                                      <Typography variant="body2">
                                        Remove picture
                                      </Typography>
                                    </Stack>
                                  </Stack>
                                )}
                              </Stack>
                            </Stack>
                          );
                        }}
                      </Field>
                      <FormHelperText error={true}>
                        <ErrorMessage name="imagePath" />
                      </FormHelperText>
                      <Stack alignSelf="self-end">
                        {/* <ButtonWithIcon label='Add Feature' type={"cancel"} onClick={() => { }} /> */}
                        <Button
                          type="submit"
                          variant="contained"
                          data-testid="add-feature"
                          color="success"
                          disabled={
                            !(props.selectedFeature.systemName
                              ? props.canUpdateExistingRecord
                              : props.canAddNewRecord)
                          }
                        >
                          {(props.selectedFeature.systemName &&
                            "Update Feature") ||
                            "Add Feature"}
                        </Button>
                      </Stack>
                    </Stack>
                    <Stack flex={1} width={400}>
                      <Box>
                        <Typography variant="subtitle2">
                          Added Feature(s):
                        </Typography>
                        <Typography variant="caption" component="span">
                          You may reorder the features by dragging each panel up
                          or down
                        </Typography>
                        <Box my={2} className={classes.scrollableList}>
                          <FormHelperText error={true}>
                            {isRuleErrorFlag && (
                              <>Create at least one record to proceed</>
                            )}
                          </FormHelperText>
                          {renderFeatureList()}
                        </Box>
                      </Box>
                    </Stack>
                  </Stack>
                </div>
              </Box>
            </Box>
            <Divider />
            <div className={classes.formFooterBtnActionHepler}>
              <Stack direction="row" spacing={2}>
                <Button
                  variant="outlined"
                  color="info"
                  className={classes.buttonHelperNext}
                  onClick={() => props.onPrevious(STEPS.STEP2)}
                >
                  Previous
                </Button>
                <Button
                  color="success"
                  variant="contained"
                  className={classes.buttonHelperNext}
                  type="button"
                  onClick={() => {
                    // props.listOfFeatures.length ? props.onNext(STEPS.STEP4) : setIsRuleErrorFlag(true);
                    props.onNext(STEPS.STEP4);
                  }}
                >
                  Next
                </Button>
              </Stack>
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default Features;
