import { DOWNLOAD_SAMPLE_TEMPLATE_TYPE } from "../../Common/Constants";
import { ACTION_TYPE } from "../../Common/RenderTableActions";
import PlantixView from "./PlantixView";

interface PlantixMapProductProps {
  searchValue: string;
  activeTab: number;
  limit: number;
  sortId: "" | "0" | "1";
  filterObj: any;
  currentPage: number;
  totalPages: number;
  open: boolean;
  showCreateOrEditModal: boolean;
  actionType: string;
  showBulkUpload: boolean;
  onSearchValueChange: (newValue: string) => void;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
  onClickAction: (clickAction: string, detail?: any) => void;
  onKnowMorePopUp: (flag: boolean, rowData: any) => void;
  onOpenModal: () => void;
  onCloseModal: () => void;
  onShowCreateOrEditModal: (toggle: boolean) => void;
  onShowBulkUpload: (toggle: boolean) => void;
  setLimit: React.Dispatch<React.SetStateAction<number>>;
  setSortId: React.Dispatch<React.SetStateAction<"" | "0" | "1">>;
  setFilterObj: React.Dispatch<React.SetStateAction<any>>;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  onSelectAll: (status: boolean) => void;
  onRowSelect: (status: boolean, rowInfo: any) => void;
  dataToDisplay: any[];
  buttonExportVisible?: boolean;
  showAdditionalColumns: boolean;
}

type ColumnTypes = {
  fieldLabel: string;
  fieldName: string;
};

const PlantixMapProductView: React.FC<PlantixMapProductProps> = (props) => {

  const baseDataListColumns: ColumnTypes[] = [
    {
      fieldLabel: "Crop",
      fieldName: "cropName",
    },
    {
      fieldLabel: "Disease",
      fieldName: "diseaseName",
    },
    {
      fieldLabel: "CP System Name",
      fieldName: "systemName",
    },
    {
      fieldLabel: "Eppo Code",
      fieldName: "eppoCode",
    },
    {
      fieldLabel: "Peat ID",
      fieldName: "peatId",
    },
    {
      fieldLabel: "Display Order",
      fieldName: "productDisplayOrder"
    },
    {
      fieldLabel: "Last Updated On",
      fieldName: "lastUpdatedOn",
    },
  ];

  const additionalColumns: ColumnTypes[] = [
    {
      fieldLabel: "Child Disease Name",
      fieldName: "childDiseaseName",
    },
    {
      fieldLabel: "Child Disease Display Order",
      fieldName: "childDiseaseDisplayOrder",
    },
    {
      fieldLabel: "Child Disease Image URL",
      fieldName: "childDiseaseImageUrl",
    },
    {
      fieldLabel: "Language",
      fieldName: "languageName",
    },
  ];


  const DataListColumns = [
    ...baseDataListColumns.slice(0, 2),
    ...(props.showAdditionalColumns ? additionalColumns : []),
    ...baseDataListColumns.slice(2)
  ];

  const MyUploadColumns: ColumnTypes[] = [
    {
      fieldLabel: "File Name",
      fieldName: "fileName",
    },
    {
      fieldLabel: "Uploaded On",
      fieldName: "cropsUploadDate",
    },
    {
      fieldLabel: "Status",
      fieldName: "comnMyUploadStatus",
    },
  ];

  const DeleteListColumns: ColumnTypes[] = [
    {
      fieldLabel: "Crop",
      fieldName: "cropName",
    },
    {
      fieldLabel: "Disease",
      fieldName: "diseaseName",
    },
    {
      fieldLabel: "CP System Name",
      fieldName: "systemName",
    },
    {
      fieldLabel: "Eppo Code",
      fieldName: "eppoCode",
    },
    {
      fieldLabel: "Peat ID",
      fieldName: "peatId",
    },
    {
      fieldLabel: "Type",
      fieldName: "type"
    },
    {
      fieldLabel: "Deleted By",
      fieldName: "deletedBy"
    },
    {
      fieldLabel: "Deleted At",
      fieldName: "deletedAt"
    },
    {
      fieldLabel: "Is Draft",
      fieldName: "draft"
    },
  ];
  const getDataColumns = () => {
    switch (props.activeTab) {
      case 0:
        return DataListColumns;
      case 1:
        return MyUploadColumns;
      case 2:
        return DeleteListColumns;
      default:
        return [];
    }
  };

  return (
    <>
      <PlantixView
        typePlantix={'map'}
        searchValue={props.searchValue}
        activeTab={props.activeTab}
        limit={props.limit}
        sortId={props.sortId}
        filterObj={props.filterObj}
        currentPage={props.currentPage}
        totalPages={props.totalPages}
        open={props.open}
        showCreateOrEditModal={props.showCreateOrEditModal}
        actionType={props.actionType}
        showBulkUpload={props.showBulkUpload}
        dataToDisplay={props.dataToDisplay}
        showViewModal={false}
        onSearchValueChange={props.onSearchValueChange}
        onChange={props.onChange}
        onClickAction={props.onClickAction}
        onKnowMorePopUp={props.onKnowMorePopUp}
        onCloseModal={props.onCloseModal}
        onShowCreateOrEditModal={props.onShowCreateOrEditModal}
        onShowBulkUpload={props.onShowBulkUpload}
        setLimit={props.setLimit}
        setSortId={props.setSortId}
        setFilterObj={props.setFilterObj}
        setCurrentPage={props.setCurrentPage}
        onSelectAll={props.onSelectAll}
        onRowSelect={props.onRowSelect}
        buttonExportVisible={props.buttonExportVisible}
        tabsList={["Data List", "My uploads", "Deleted List"]}
        dataColumns={getDataColumns()}
        downloadLiteral={DOWNLOAD_SAMPLE_TEMPLATE_TYPE.PLANTIX_MAP}
        actionsType={ACTION_TYPE.TYPE_CP_PRODUCT}
        showAdditionalColumns={props.showAdditionalColumns}
      />
    </>
  );
};

export default PlantixMapProductView;