import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Stack,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { ErrorMessage, Field, Form, Formik, useField } from "formik";
import { useState } from "react";
import ButtonWithIcon, { CANCEL_TYPE, CREATE_TYPE, } from "../../../Common/Buttons";
import SortableComponent from "../../../Common/SortableRecords";
import { checkForDuplicates } from "../../../Utils/genericUtils";
import { PrecautionSchemaValidation } from "../HybridValidationSchema";
import { useStyles } from "../useStyle";
import { InitialPrecautions, PrecautionFormKeys } from "./index";
import { STEPS } from "../../../Common/Constants";

type PrecautionFormProps = {
  ListOfStages: Array<PrecautionFormKeys>;
  selectedStage: PrecautionFormKeys;
  onSelect: Function;
  onSave: Function;
  onRemove: Function;
  onNext: Function;
  onPrevious: Function;
  updateUniqueKey: Function;
  uniqueKey: number;
  // systemName: string;
  updateListOrder: Function;
  isEdit: boolean;
  isPrimaryProduct: boolean;
  isSystemNameEditable: boolean;
  canAddNewRecord: boolean;
  canUpdateExistingRecord: boolean;
  actionType: string;
};


const TextArea = ({ label, ...props }: any) => {
  const classes = useStyles();
  const [field] = useField(props);

  return (
    <>
      <Typography variant="subtitle2">{`${label} *`}</Typography>
      <Typography fontSize="14px" color="#696F88">
        Max. 1000 characters
      </Typography>
      <TextareaAutosize
        {...field}
        {...props}
        className={classes.textAreaStyles}
      ></TextareaAutosize>
    </>
  );
};

export const PrecautionsForm: React.FC<PrecautionFormProps> = (
  props: PrecautionFormProps
) => {
  const classes = useStyles();
  const [isRuleErrorFlag, setIsRuleErrorFlag] = useState<boolean>(false);
  const nextStepHandler = (step: number) => {
    props.onNext(step);
  };
  const renderAddedPrecautions = () => {
    if (props.ListOfStages.length === 0) {
      return (
        <>
          {" "}
          <Stack flex={1} width={400}>
            <Box>
              <Typography variant="subtitle2">Added Precaution(s):</Typography>
              <Typography variant="caption" component="span">
                You may reorder the stages by dragging each panel up or down
              </Typography>
              <FormHelperText error={true}>
                {isRuleErrorFlag && (
                  <>{"Please add one precaution to continue"}</>
                )}
              </FormHelperText>
            </Box>
          </Stack>
        </>
      );
    }
    return (
      <Stack flex={1} width={400}>
        <Box>
          <Typography variant="subtitle2">Added Precaution(s):</Typography>
          <Typography variant="caption" component="span">
            You may reorder the stages by dragging each panel up or down
          </Typography>
          <FormHelperText error={true}></FormHelperText>
          <Box my={2} className={classes.scrollableList}>
            <SortableComponent
              name={"Precautions"}
              items={props.ListOfStages}
              updateCropStageOrder={(newList: Array<PrecautionFormProps>) => {
                props.updateListOrder(newList);
              }}
              key={props.ListOfStages.length}
              listProps={props}
              displayKey={"systemName"}
              initialState={InitialPrecautions}
              selectedRecord={props.selectedStage}
              // isDisabled={
              //   !props.isPrimaryProduct &&
              //   props.actionType !== ActionType.CREATE
              // }
              showRemoveButton={props.isPrimaryProduct}
              isDraggable={true}
            />
          </Box>
        </Box>
      </Stack>
    );
  };

  return (
    <Box>
      <Box className={classes.formContainer}>
        <div className={classes.formContainerChildDivStylesHepler}>
          <Stack direction="row" spacing={2}>
            <Formik
              initialValues={{
                displayOrder: props.selectedStage.displayOrder,
                heading: props.selectedStage.heading,
                id: props.selectedStage.id ?? null,
                media: props.selectedStage.media,
                systemName: props.selectedStage.systemName,
                text: props.selectedStage.text,
              }}
              validationSchema={PrecautionSchemaValidation(
                props.selectedStage.systemName
              )}
              enableReinitialize={true}
              onSubmit={(values: any, _onSubmitProps: any) => {
                if (
                  checkForDuplicates(
                    props.ListOfStages,
                    { systemName: values!.systemName },
                    values!.systemName,
                    props.selectedStage.systemName
                  )
                ) {
                  _onSubmitProps.setFieldError(
                    "systemName",
                    "Duplicate system name"
                  );
                  _onSubmitProps.setSubmitting(false);
                  return;
                } else {
                  _onSubmitProps.setSubmitting(false);
                  props.onSave(values);
                  _onSubmitProps.resetForm();
                  props.updateUniqueKey();
                  setIsRuleErrorFlag(false);
                }
              }}
            >
              {({ values, setFieldValue }) => (
                <Stack
                  flex={1}
                  justifyContent="flex-start"
                  direction="row"
                  gap={2}
                  width={400}
                  className={classes.leftPanel}
                >
                  <Form>
                    <Stack spacing={2} minWidth={350}>
                      <Box>
                        <Field name="systemName">
                          {() => {
                            return (
                              <FormControl
                                className={classes.formMarginHelper}
                                fullWidth
                              >
                                <Typography variant="subtitle2">
                                  Precaution System Name *
                                </Typography>
                                <TextField
                                  name="systemName"
                                  onChange={(event: any) => {
                                    setFieldValue(
                                      "systemName",
                                      event.target.value
                                        .trim()
                                        .toLocaleUpperCase()
                                    );
                                  }}
                                  defaultValue={values.systemName}
                                  value={values?.systemName}
                                  disabled={
                                    props.canAddNewRecord
                                      ? !!props.selectedStage.systemName
                                      : true
                                  }
                                />
                              </FormControl>
                            );
                          }}
                        </Field>
                        <FormHelperText error={true}>
                          <ErrorMessage name="systemName" />
                        </FormHelperText>
                        <TextArea
                          name="text"
                          as="textarea"
                          label="Precaution Description"
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setFieldValue("text", event.target.value);
                          }}
                        />
                        <FormHelperText
                          error={true}
                          className={classes.languagErrorMsgHepler}
                        >
                          <ErrorMessage name="text" />
                        </FormHelperText>
                      </Box>
                      <Stack alignSelf="self-end" maxWidth={150}>
                        <Button
                          type="submit"
                          variant="contained"
                          data-testid="addPrecaution"
                          color="success"
                          disabled={
                            !(props.selectedStage.systemName
                              ? props.canUpdateExistingRecord
                              : props.canAddNewRecord)
                          }
                        >
                          {(props.selectedStage?.systemName && "Update") ||
                            "Add Precaution"}
                        </Button>
                      </Stack>
                    </Stack>
                  </Form>
                  <Divider orientation="vertical" flexItem />
                </Stack>
              )}
            </Formik>
            {
              /**
               * added precautions will be listed here
               */

              renderAddedPrecautions()
            }
          </Stack>
        </div>
      </Box>
      <>
        <Divider />
        <div className={classes.formActionFooterBtnHelper}>
          <Stack direction="row" spacing={2}>
            <ButtonWithIcon
              showCreateProgress={false}
              type={CANCEL_TYPE}
              label="Previous"
              onClick={() => {
                props.onPrevious(STEPS.STEP2);
              }}
            />
            <ButtonWithIcon
              showCreateProgress={false}
              type={CREATE_TYPE}
              label="Next"
              onClick={() => {
                // props.ListOfStages.length ? nextStepHandler(STEPS.STEP4) : setIsRuleErrorFlag(true);
                nextStepHandler(STEPS.STEP4);
              }}
            />
          </Stack>
        </div>
      </>
    </Box>
  );
};
