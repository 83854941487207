import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  Select: {
    '& ul': {
        maxHeight: '20vh',
    }
},
  languagePanelHelper: {
    margin: '24px auto 0px',
    display: 'flex',
    alignItems: 'center',
    width: '-webkit-fill-available'
  },
  formControlHelper: {
    left: '2px',
  },
  formFieldErrorText: {
    color: '#ff0000',
    fontSize: '10px'
  }
});
