import AddIcon from "@mui/icons-material/Add";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import PublishOutlinedIcon from "@mui/icons-material/PublishOutlined";
import {Box, FormControl, FormHelperText, TextareaAutosize, TextField,} from "@mui/material";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import {ErrorMessage, Field, Form, Formik} from "formik";
import React, {ChangeEvent, useState} from "react";
import api_endpoints, {base_url} from "../../../Api/end_points";
import {GetFileS3BucketURL} from "../../../Api/generic_apicalls";
import {CP_S3ImageUploadType} from "../../../Common/Constants/types";
import SortableComponent from "../../../Common/SortableRecords";
import {IRequestBody} from "../../../Constants/interfaces";
import {checkForDuplicates} from "../../../Utils/genericUtils";
import {TriggerToastMessage} from "../../../Utils/toastTrigger";
import {GrowerTestimonialsType, initialTestimonial} from "./";
import {useStyles} from "./styles";
import {ValidationSchemaGrowerTestimonial} from "./validationSchema";
import {ActionType, MEDIA_TYPE, ModuleImageUpload, STEPS, ValidationType,} from "../../../Common/Constants";
import {genericFormFieldValidation, genericValidateImage,} from "../../../Utils/fileHelper";
import RenderField from "../../../Common/RenderField";

const Input = styled("input")({
  display: "none",
});

export interface GrowerTestimonialProps {
  onNext: Function;
  onPrevious: Function;
  updateListOrder: Function;
  listOfTestimonial: Array<GrowerTestimonialsType>;
  selectedTestimonial: GrowerTestimonialsType;
  onSave: Function;
  onRemove: Function;
  onSelect: Function;
  updateUniqueKey: Function;
  uniqueKey: number;
  isPrimaryCP: boolean;
  actionType: string;
  cropSystemName: string;
  getLatestImgURL: Function;
  isEdit: boolean;
  productSystemName: string;
  isSystemNameEditable: boolean;
  canAddNewRecord: boolean;
  canUpdateExistingRecord: boolean;
}

const GrowerTestimonialForm: React.FC<GrowerTestimonialProps> = (props) => {
  // variables
  const classes = useStyles();

  const [isRuleErrorFlag, setIsRuleErrorFlag] = useState<boolean>(false);

  /** Drag and Drop Menu lsiting */
  const renderTestimonialList = () => {
    return (
      <>
        <SortableComponent
          name={"BrowerTestimonial"}
          items={props.listOfTestimonial}
          updateCropStageOrder={(newList: Array<GrowerTestimonialsType>) => {
            props.updateListOrder(newList);
          }}
          key={props.listOfTestimonial.length}
          listProps={props}
          displayKey={"systemName"}
          initialState={initialTestimonial}
          selectedRecord={props.selectedTestimonial}
          isDisabled={
            !props.isPrimaryCP && props.actionType !== ActionType.CREATE
          }
        />
      </>
    );
  };
  /** Filed level validation for Image file upload */
  const validateImage = (value: any, optional = false) => {
    return genericValidateImage(value, optional);
  };

  const validationTrimUrl = (text: string): string => {
    const trimmedUrl = text.trim();
    return trimmedUrl.replace(/\s/g, "");
  };

  /** Clear selected image */
  const clearImageObject = () => {
    /**remove img code to upload the same img again */
    let parent: any = document.getElementById("upload-img");
    let children = parent.children[0];
    children.value = "";
  };
  const getFileUploadPayload = (file: any, data: CP_S3ImageUploadType) => {
    let fileNameArr: any = file.name.split(".");
    let s3Data: any = {
      productSystemName: props.productSystemName,
      type: data.type,
      fileNameWithExtension: `${data.productSystemName}.${
        fileNameArr[fileNameArr.length - 1]
      }`,
      cropSystemName: props.cropSystemName,
    };
    return s3Data;
  };
  const getFileS3URL_Save = (values: any) => {
    /** fetching image S3 url from api and saving the stage after success */
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.fileupload_S3bucket;
    apiData.showLoader = true;
    if (typeof values.mediaUrl !== "string") {
      /** generating crop stage growth related payload */
      let imgData: any = {
        productSystemName: values.systemName,
        cropSystemName: values.crop,
        type: ModuleImageUpload.CP_TESTIMONIAL,
      };
      let payload = getFileUploadPayload(values.mediaUrl, imgData);
      let formData: any = new FormData();
      formData.append("file", values.mediaUrl);
      formData.append("data", JSON.stringify(payload));
      apiData.payLoad = formData;
      return GetFileS3BucketURL(
        apiData,
        (response: any, type: string, imgType: string) => {
          /** SUCCESS CALLBACK */
          let imgUrl: string = response.data.imageUrl;
          /** storing latest img file urls do delete if not saved */
          props.getLatestImgURL(imgUrl);
          values.mediaUrl = imgUrl;
          props.onSave(values);
        },
        (_error: any, type: string, imgType: string) => {
          /** ERROR CALLBACK */
          TriggerToastMessage(
            "Unable to process your request!!! - " + type,
            "error"
          );
        },
        ModuleImageUpload.CP_TESTIMONIAL
      );
    } else {
      /** if no change in image, direct save the record */
      props.onSave(values);
    }
  };
  return (
    <Formik
      initialValues={{
        systemName: props.selectedTestimonial.systemName,
        text: props.selectedTestimonial.text,
        mediaType: props.selectedTestimonial.mediaType,
        subtext: props.selectedTestimonial.subtext,
        mediaUrl: props.selectedTestimonial.mediaUrl,
      }}
      validationSchema={ValidationSchemaGrowerTestimonial}
      onSubmit={(values: any, _onSubmitProps: any) => {
        console.log("values", values);
        if (
          checkForDuplicates(
            props.listOfTestimonial,
            { systemName: values!.systemName },
            values!.systemName,
            props.selectedTestimonial.systemName
          )
        ) {
          _onSubmitProps.setFieldError("systemName", "Duplicate system name");
          _onSubmitProps.setSubmitting(false);
          return;
        } else {
          if (values.mediaType === MEDIA_TYPE[0]) {
            /** saving after image save */
            getFileS3URL_Save(values);
          } else {
            /** direct saving */
            props.onSave(values);
          }
          _onSubmitProps.setSubmitting(false);
          _onSubmitProps.resetForm();
          props.updateUniqueKey();
          setIsRuleErrorFlag(false);
        }
      }}
      enableReinitialize={true}
    >
      {({ values, setFieldValue }) => (
        <Form className={classes.formOutline}>
          <Box className={classes.formContainer}>
            <Box>
              <div className={classes.formContainerChildDivHelper}>
                <Stack direction="row" spacing={2}>
                  <Stack
                    flex={1}
                    width={400}
                    justifyContent="flex-start"
                    borderRight="1px solid #C2C7D0"
                    paddingRight={2}
                    className={classes.leftPanel}
                  >
                    <Field
                      name="systemName"
                      validate={
                        props.selectedTestimonial.systemName
                          ? null
                          : (value: string) =>
                              genericFormFieldValidation(
                                value,
                                ValidationType.SYS_NM
                              )
                      }
                    >
                      {() => {
                        return (
                          <FormControl
                            className={classes.formMarginHelper}
                            fullWidth
                          >
                            <Typography variant="subtitle2">
                              Grower Testimonial System Name *
                            </Typography>
                            <TextField
                                data-testid="Grower Testimonial System Name"
                              name="systemName"
                              onChange={(event: any) => {
                                setFieldValue(
                                  "systemName",
                                  event.target.value.trim().toLocaleUpperCase()
                                );
                              }}
                              defaultValue={values.systemName}
                              value={values.systemName}
                              disabled={
                                props.canAddNewRecord
                                  ? !!props.selectedTestimonial.systemName
                                  : true
                              }
                            />
                          </FormControl>
                        );
                      }}
                    </Field>
                    <FormHelperText error={true}>
                      <ErrorMessage name="systemName" />
                    </FormHelperText>
                    <Field name="text">
                      {() => {
                        return (
                          <FormControl
                            className={classes.formMarginHelper}
                            fullWidth
                          >
                            <Typography variant="subtitle2">
                              Media Title *
                            </Typography>
                            <TextField
                              name="text"
                              value={values.text}
                              onChange={(event: any) => {
                                setFieldValue("text", event.target.value);
                              }}
                            />
                          </FormControl>
                        );
                      }}
                    </Field>
                    <FormHelperText error={true}>
                      <ErrorMessage name="text" />
                    </FormHelperText>
                    <Field name="mediaType">
                      {() => {
                        return (
                          <FormControl
                            className={classes.formMarginHelper}
                            fullWidth
                          >
                            <RenderField
                              required
                              key={"mediaType"}
                              name="mediaType"
                              labelKey="mediaType"
                              options={MEDIA_TYPE}
                              label="Media Type"
                              value={values.mediaType}
                              onChange={(name: string, value: any) => {
                                setFieldValue(name, value);
                                if (value !== MEDIA_TYPE[1]) {
                                  setFieldValue("mediaUrl", "");
                                }
                              }}
                              isDisabled={
                                props.canAddNewRecord
                                  ? !!props.selectedTestimonial.systemName
                                  : true
                              }
                            />
                          </FormControl>
                        );
                      }}
                    </Field>
                    <FormHelperText error={true}>
                      <ErrorMessage name="mediaType" />
                    </FormHelperText>
                    <FormControl className={classes.formMarginHelper}>
                      <Typography fontSize={14} variant="subtitle2">
                        Grower Testimonial Description
                      </Typography>
                      <Field name="subtext">
                        {() => {
                          return (
                            <TextareaAutosize
                              onBlur={(event) => {
                                setFieldValue("subtext", event.target.value);
                              }}
                              name="subtext"
                              className={classes.textAreaStyles}
                              maxLength={1024}
                              defaultValue={values?.subtext}
                              key={values.subtext}
                            />
                          );
                        }}
                      </Field>
                    </FormControl>
                    <FormHelperText error={true}>
                      <ErrorMessage name="subtext" />
                    </FormHelperText>
                    {values.mediaType === MEDIA_TYPE[1] && (
                      <>
                        <Field name="mediaUrl">
                          {() => {
                            return (
                              <FormControl
                                className={classes.formMarginHelper}
                                fullWidth
                              >
                                <Typography variant="subtitle2">
                                  Media URL
                                </Typography>
                                <TextField
                                  name="mediaUrl"
                                  onChange={(event: any) => {
                                    const spacelessUrl = validationTrimUrl(
                                      event.target.value
                                    );
                                    setFieldValue("mediaUrl", spacelessUrl);
                                  }}
                                  value={values.mediaUrl}
                                  defaultValue={values.mediaUrl}
                                />
                              </FormControl>
                            );
                          }}
                        </Field>
                        <FormHelperText error={true}>
                          <ErrorMessage name="mediaUrl" />
                        </FormHelperText>
                      </>
                    )}
                    {values.mediaType === MEDIA_TYPE[0] && (
                      <>
                        <Field
                          name="mediaUrl"
                          validate={(value: any) => validateImage(value, true)}
                        >
                          {() => {
                            return (
                              <Stack>
                                <Stack
                                  direction="row"
                                  alignItems="flex-start"
                                  justifyContent={
                                    props.canAddNewRecord ? "space-between" : ""
                                  }
                                  // spacing={props.canAddNewRecord ? 1 : 6}
                                  spacing={1}
                                  marginTop={3}
                                >
                                  <Typography
                                    variant="subtitle2"
                                    fontSize={14}
                                    component="span"
                                    width="80px"
                                  >
                                    Image
                                  </Typography>
                                  <Stack>
                                    <label
                                      htmlFor="contained-button-file"
                                      id="upload-img"
                                    >
                                      <Input
                                        accept=".jpg, .png, .jpeg"
                                        id="contained-button-file"
                                        // multiple
                                        type="file"
                                        name="image-upload"
                                        onChange={(
                                          event: ChangeEvent<HTMLInputElement>
                                        ) => {
                                          let imgFile: any =
                                            event.target.files &&
                                            event.target.files[0];
                                          setFieldValue("mediaUrl", imgFile);
                                        }}
                                        disabled={!props.canAddNewRecord}
                                      />
                                      {/* Displaying image either by Raw data or by using URL */}
                                      {values.mediaUrl && (
                                        <img
                                            alt={'img'}
                                          src={
                                            typeof values.mediaUrl !== "string"
                                              ? `${URL.createObjectURL(
                                                  values.mediaUrl
                                                )}`
                                              : `${values.mediaUrl}`
                                          }
                                          width="128px"
                                          height="128px"
                                        />
                                      )}
                                      {!values.mediaUrl && (
                                        <Button
                                          variant="contained"
                                          size="small"
                                          component="span"
                                          disableRipple
                                          disableElevation
                                          disableTouchRipple
                                          className={
                                            classes.formUploadImageHelper
                                          }
                                        >
                                          <AddIcon
                                            className={
                                              classes.uploadImgBtnIconColorHelper
                                            }
                                          />{" "}
                                          Upload an image
                                        </Button>
                                      )}
                                    </label>
                                  </Stack>
                                  {props.canAddNewRecord && (
                                    <Stack spacing={2}>
                                      <FormHelperText
                                        className={
                                          classes.formHelperTextFontSizeHelper
                                        }
                                      >
                                        JPG, JPEG or PNG. Max size of 5 MB
                                      </FormHelperText>
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="center"
                                      >
                                        <PublishOutlinedIcon fontSize="inherit" />
                                        <Typography variant="body2">
                                          <label
                                            className={classes.rpPicture}
                                            htmlFor="contained-button-file2"
                                          >
                                            {values.mediaUrl && (
                                              <Input
                                                accept=".jpg, .png, .jpeg"
                                                id="contained-button-file2"
                                                // multiple
                                                type="file"
                                                name="replace-file"
                                                onChange={(
                                                  event: ChangeEvent<HTMLInputElement>
                                                ) => {
                                                  let imgFile: any =
                                                    event.target.files &&
                                                    event.target.files[0];
                                                  setFieldValue(
                                                    "mediaUrl",
                                                    imgFile
                                                  );
                                                }}
                                                disabled={
                                                  !props.canAddNewRecord
                                                }
                                              />
                                            )}
                                            Replace picture
                                          </label>
                                        </Typography>
                                      </Stack>
                                      <Stack
                                        className={classes.rmPicture}
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="center"
                                        onClick={() => {
                                          if (props.canAddNewRecord) {
                                            setFieldValue("mediaUrl", "");
                                            clearImageObject();
                                          }
                                        }}
                                      >
                                        <DeleteOutlinedIcon fontSize="inherit" />
                                        <Typography variant="body2">
                                          Remove picture
                                        </Typography>
                                      </Stack>
                                    </Stack>
                                  )}
                                </Stack>
                              </Stack>
                            );
                          }}
                        </Field>
                        <FormHelperText error={true}>
                          <ErrorMessage name="mediaUrl" />
                        </FormHelperText>
                      </>
                    )}
                    <Stack alignSelf="self-end" mt={2}>
                      {/* <ButtonWithIcon label='Add testimonial' type={"cancel"} onClick={() => { }} /> */}
                      <Button
                        type="submit"
                        variant="contained"
                        color="success"
                        // disabled={(props.actionType === ActionType.ADD_TRANSLATION && !props.selectedTestimonial.systemName)}
                        disabled={
                          !(props.selectedTestimonial.systemName
                            ? props.canUpdateExistingRecord
                            : props.canAddNewRecord)
                        }
                      >
                        {props.selectedTestimonial.systemName
                          ? "Update Testimonial"
                          : "Add Testimonial"}
                      </Button>
                    </Stack>
                  </Stack>
                  <Stack flex={1} width={400}>
                    <Box>
                      <Typography variant="subtitle2">
                        Added Testmonial(s):
                      </Typography>
                      <Typography variant="caption" component="span">
                        You may reorder the stages by dragging each panel up or
                        down
                      </Typography>
                      <Box my={2} className={classes.scrollableList}>
                        {/* <Stack direction='row' spacing={3} alignItems='center' className={classes.OddTableRow}>
                            <Box className={classes.tableFirstChild}>1</Box>
                            <Typography variant='subtitle2' className={classes.addedFeaturesTableColData} width='120px'>Seedling_Satge</Typography>
                            <Box>
                              <ButtonGroup variant='text'>
                                <Button>Remove</Button>
                                <Button>Edit</Button>
                              </ButtonGroup>
                            </Box>
                            <Box>
                            </Box>
                          </Stack> */}
                        <FormHelperText error={true}>
                          {isRuleErrorFlag && (
                            <>Create at least one record to proceed</>
                          )}
                        </FormHelperText>
                        {renderTestimonialList()}
                      </Box>
                    </Box>
                  </Stack>
                </Stack>
              </div>
            </Box>
          </Box>
          <Divider />
          <div className={classes.formFooterBtnActionHepler}>
            <Stack direction="row" spacing={2}>
              <Button
                variant="outlined"
                color="info"
                onClick={() => props.onPrevious(STEPS.STEP3)}
                className={classes.buttonHelperNext}
              >
                Previous
              </Button>
              <Button
                color="success"
                variant="contained"
                className={classes.buttonHelperNext}
                type="button"
                // onClick={() => {
                //   props.listOfTestimonial.length ? props.onNext(STEPS.STEP5) : setIsRuleErrorFlag(true);
                // }}
                onClick={() => props.onNext(STEPS.STEP5)}
              >
                Next
              </Button>
            </Stack>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default GrowerTestimonialForm;
