import { Box, FormControl, FormHelperText, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import SortableComponent from "../../../Common/SortableRecords";
import { checkForDuplicates } from "../../../Utils/genericUtils";
import { initialThreat, TargetThreatsType } from "./";
import { useStyles } from "./styles";
import { ValidationSchemaTargetThreat } from "./validationSchema";
import { ActionType, STEPS } from "../../../Common/Constants";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import RenderField from "../../../Common/RenderField";
export interface TargetThreatsProps {
  onNext: Function;
  onPrevious: Function;
  updateListOrder: Function;
  listOfThreats: Array<TargetThreatsType>;
  selectedThreat: TargetThreatsType;
  onSave: Function;
  onRemove: Function;
  onSelect: Function;
  updateUniqueKey: Function;
  uniqueKey: number;
  isPrimaryCP: boolean;
  actionType: string;
  threatList: Array<any>;
  stagesList: Array<any>;
  isEdit: boolean;
  isSystemNameEditable: boolean;
  canAddNewRecord: boolean;
  generalDetails: any;
  canUpdateExistingRecord: boolean /*  */;
}


const TargetThreats: React.FC<TargetThreatsProps> = (props) => {
  // variables
  const classes = useStyles();
  const storeData = useSelector((state: RootState) => {
    return {
      primaryLangCode: state.auth.languageCode,
    };
  });
  const [isRuleErrorFlag, setIsRuleErrorFlag] = useState<boolean>(false);

  /** Drag and Drop Menu lsiting */
  const renderThreatList = () => {
    return (
      <SortableComponent
        name={"TargetThreats"}
        items={props.listOfThreats}
        updateCropStageOrder={(newList: Array<TargetThreatsType>) => {
          const updatedList = newList.map((item, index) => ({
            ...item,
            displayOrder: index,
          }));
          props.updateListOrder(updatedList);
        }}
        key={props.listOfThreats.length}
        listProps={props}
        displayKey={"systemName"}
        initialState={initialThreat}
        selectedRecord={props.selectedThreat}
        secMatchKey="stages"
        isDisabled={
          !props.isPrimaryCP && props.actionType !== ActionType.CREATE
        }
        multipleMatchKey={["systemName", "stages", "displayOrder"]}
      />
    );
  };
  return (
    <Formik
      initialValues={{
        systemName: props.selectedThreat.systemName,
        threatDisplayOrder:
          (
            props.selectedThreat.stageThreatDisplayOrders?.find(
              (item: any) =>
                item.stageSystemName === props.selectedThreat.stages
            ) as any
          )?.cpThreatDisplayOrder ?? "",
        stages: props.selectedThreat.stages,
        stageDisplayOrder:
          (
            props.selectedThreat.stageThreatDisplayOrders?.find(
              (item: any) =>
                item.stageSystemName === props.selectedThreat.stages
            ) as any
          )?.cpDisplayOrder ?? "",
      }}
      validationSchema={ValidationSchemaTargetThreat}
      onSubmit={(values: any, _onSubmitProps: any) => {
        /** submit form */
        if (
          checkForDuplicates(
            props.listOfThreats,
            { systemName: values!.systemName, stages: values!.stages },
            values!.systemName,
            props.selectedThreat.systemName
          )
        ) {
          _onSubmitProps.setFieldError(
            "systemName",
            "Combination with threat system name & stage system name  exists!"
          );
          _onSubmitProps.setSubmitting(false);
          return;
        } else {
          _onSubmitProps.setSubmitting(false);

          let { threatDisplayOrder, stageDisplayOrder, ...updatedValues } =
            values;

          if (!updatedValues.stageThreatDisplayOrders) {
            updatedValues.stageThreatDisplayOrders = [];
          }

          let item = updatedValues.stageThreatDisplayOrders?.find(
            (item: any) => item.stageSystemName === values.stages
          );

          if (!item) {
            updatedValues.stageThreatDisplayOrders = [
              ...updatedValues.stageThreatDisplayOrders,
              {
                threatSystemName: values.systemName,
                stageSystemName: values.stages,
                cpDisplayOrder: stageDisplayOrder,
                cpThreatDisplayOrder: threatDisplayOrder,
              },
            ];
          } else {
            item.threatSystemName = values.systemName;
            item.stageSystemName = values.stages;
            item.cpDisplayOrder = stageDisplayOrder;
            item.cpThreatDisplayOrder = threatDisplayOrder;
          }

          props.onSave(updatedValues);
          _onSubmitProps.resetForm();
          props.updateUniqueKey();
          setIsRuleErrorFlag(false);
        }
      }}
      enableReinitialize={true}
    >
      {({ values, setFieldValue, setFieldTouched }) => (
        <Form className={classes.formOutline}>
          <Box className={classes.formContainer}>
            <Box>
              <div className={classes.formContainerChildDivHelper}>
                <Stack direction="row" spacing={2}>
                  <Stack
                    flex={1}
                    justifyContent="flex-start"
                    borderRight="1px solid #C2C7D0"
                    paddingRight={2}
                    className={classes.leftPanel}
                  >
                    <Field
                      name="systemName">
                      {() => {
                        return (
                          <FormControl
                            className={classes.formMarginHelper}
                            fullWidth
                          >
                            <RenderField
                              key={"systemName"}
                              name="systemName"
                              labelKey="name"
                              options={props.threatList}
                              // options={ThreatData}
                              label="Threat System Name *"
                              value={values.systemName}
                              onChange={(name: string, value: any) => {
                                setFieldValue(name, value);
                              }}
                              isDisabled={
                                props.canAddNewRecord
                                  ? !!props.selectedThreat.systemName
                                  : true
                              }
                            />
                          </FormControl>
                        );
                      }}
                    </Field>
                    <FormHelperText error={true}>
                      <ErrorMessage name="systemName" />
                    </FormHelperText>

                    <Field name="threatDisplayOrder">
                      {() => {
                        return (
                          <FormControl
                            className={classes.formMarginHelper}
                            fullWidth
                          >
                            <Typography variant="subtitle2">
                              Threat Display Order
                            </Typography>
                            <TextField
                              disabled={
                                !(
                                  storeData.primaryLangCode ==
                                  props.generalDetails.languageCode
                                )
                              }
                              name="threatDisplayOrder"
                              onChange={(event) => {
                                setFieldTouched("threatDisplayOrder");
                                if (
                                  /^(0|[1-9]\d*)$/.test(event.target.value) ||
                                  event.target.value === ""
                                ) {
                                  setFieldValue(
                                    "threatDisplayOrder",
                                    event.target.value
                                  );
                                }
                                event.stopPropagation();
                              }}
                              value={values.threatDisplayOrder}
                            />
                          </FormControl>
                        );
                      }}
                    </Field>
                    <FormHelperText error={true}>
                      <ErrorMessage name="threatDisplayOrder" />
                    </FormHelperText>

                    <Stack width={400} sx={{ marginBottom: "10px" }}>
                      <Field name="stages">
                        {() => {
                          return (
                            <FormControl
                              className={classes.formMarginHelper}
                              fullWidth
                            >
                              <RenderField
                                key={"stages"}
                                name="stages"
                                labelKey="stages"
                                options={props.stagesList}
                                // options={StageData}
                                label="Stage System Name *"
                                value={values.stages}
                                onChange={(name: string, value: any) => {
                                  setFieldValue(name, value);
                                }}
                                isDisabled={
                                  props.canAddNewRecord
                                    ? !!props.selectedThreat.systemName
                                    : true
                                }
                              />
                            </FormControl>
                          );
                        }}
                      </Field>
                      <FormHelperText error={true}>
                        <ErrorMessage name="stages" />
                      </FormHelperText>
                    </Stack>

                    <Field name="stageDisplayOrder">
                      {() => {
                        return (
                          <FormControl
                            className={classes.formMarginHelper}
                            fullWidth
                          >
                            <Typography variant="subtitle2">
                              Stage Display Order
                            </Typography>
                            <TextField
                              name="stageDisplayOrder"
                              disabled={
                                !(
                                  storeData.primaryLangCode ==
                                  props.generalDetails.languageCode
                                )
                              }
                              onChange={async (event) => {
                                setFieldTouched("stageDisplayOrder");
                                if (
                                  /^(0|[1-9]\d*)$/.test(event.target.value) ||
                                  event.target.value === ""
                                ) {
                                  setFieldValue(
                                    "stageDisplayOrder",
                                    event.target.value
                                  );
                                }
                                event.stopPropagation();
                              }}
                              value={values.stageDisplayOrder}
                            />
                          </FormControl>
                        );
                      }}
                    </Field>
                    <FormHelperText error={true}>
                      <ErrorMessage name="stageDisplayOrder" />
                    </FormHelperText>

                    <Stack alignSelf="self-end" mt={2}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="success"
                        disabled={props.isEdit && !props.isPrimaryCP}
                      >
                        {props.selectedThreat.systemName
                          ? "Update Target"
                          : "Add Target"}
                      </Button>
                    </Stack>
                  </Stack>
                  <Stack width={400} flex={1}>
                    <Box>
                      <Typography variant="subtitle2">
                        Added Target Threat(s):
                      </Typography>
                      <Typography variant="caption" component="span">
                        You may reorder the stages by dragging each panel up or
                        down
                      </Typography>
                      <Box my={2} className={classes.scrollableList}>
                        <FormHelperText error={true}>
                          {isRuleErrorFlag && (
                            <>Create at least one record to proceed</>
                          )}
                        </FormHelperText>
                        {renderThreatList()}
                      </Box>
                    </Box>
                  </Stack>
                </Stack>
              </div>
            </Box>
          </Box>
          <Divider />
          <div className={classes.formFooterBtnActionHepler}>
            <Stack direction="row" spacing={2}>
              <Button
                variant="outlined"
                color="info"
                onClick={() => props.onPrevious(STEPS.STEP4)}
                className={classes.buttonHelperNext}
              >
                Previous
              </Button>
              <Button
                color="success"
                variant="contained"
                className={classes.buttonHelperNext}
                type="button"
                onClick={() => {
                  props.listOfThreats.length
                    ? props.onNext(STEPS.STEP6)
                    : setIsRuleErrorFlag(true);
                }}
              >
                Next
              </Button>
            </Stack>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default TargetThreats;
