import appActions from '../redux/action';
import { store } from './../redux/store';

export const TriggerToastMessage = (
    message: string,
    typeOfToast: string,
    autoClose?: number | false
) => {
    store.dispatch(appActions.generic_actions.showToaster({
        text: message,
        type: typeOfToast,
        time: autoClose ?? 5000
    }));
}
