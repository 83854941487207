import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import {
  CropCalendarTypes,
  CropGrowthStageKeys,
  CropStageCalendarTypes,
  initialCropStageCalendar,
} from ".";
import ButtonWithIcon, {
  CANCEL_TYPE,
  CREATE_TYPE,
} from "../../../Common/Buttons";
import SortableComponent from "../../../Common/SortableRecords";
import { checkForDuplicates } from "../../../Utils/genericUtils";
import { CropStageCalendarSchemaValidation } from "../CropValidationSchema";
import { useStyles } from "../useStyle";
import { ActionType, STEPS } from "../../../Common/Constants";
import getRandomizeValue from "../../../Utils/randomizer";
import RenderField from "../../../Common/RenderField";

export type FormProps = {
  cropStageList: Array<CropStageCalendarTypes>;
  selectedCropCalendarStage: CropStageCalendarTypes;
  onSave: Function;
  onRemove: Function;
  onSelect: Function;
  onNext: Function;
  onPrevious: Function;
  updateUniqueKey: Function;
  uniqueKey: number;
  corpGrowthStagesList: Array<CropGrowthStageKeys>;
  corpCalendarList: Array<CropCalendarTypes>;
  isEdit: boolean;
  updateListOrder: Function;
  actionType: string;
  isPrimaryCrop: boolean;
  isSystemNameEditable: boolean;
  canAddNewRecord: boolean;
  canUpdateExistingRecord: boolean;
};
export const CropStageCalenderForm: React.FC<FormProps> = (
  props: FormProps
) => {
  const classes = useStyles();
  const [isRuleErrorFlag, setIsRuleErrorFlag] = useState<boolean>(false);

  const renderCropStageCalendarFormStages = () => {
    return (
      <>
        <SortableComponent
          name={"Crop Stage Calendar"}
          items={props.cropStageList}
          updateCropStageOrder={(newList: Array<CropStageCalendarTypes>) => {
            props.updateListOrder(newList);
          }}
          key={getRandomizeValue()}
          listProps={props}
          displayKey={"calendarName"}
          secMatchKey={"stageSystemName"}
          initialState={initialCropStageCalendar}
          selectedRecord={props.selectedCropCalendarStage}
          isDisabled={
            !props.isPrimaryCrop && props.actionType !== ActionType.CREATE
          }
        />
      </>
    );
  };
  /** End day Field level validation */
  const validateEndDay = (endDay: any, startDayValue: any) => {
    /**
     * 1. should be there
     * 2. between 1 to 365
     * 3. must be equal to or greater than start day
     */
    let startDay: number = Number(startDayValue);
    if (!endDay) {
      return "End day required";
    } else if (endDay < 0 || endDay > 365) {
      return "End day must be between 1 to 365";
    } else if (startDay && startDay > endDay) {
      return "End day must be greater than Start day";
    }

    return "";
  };
  /** Getting latest unique key to see latest
   * selected record for edit
   */
  useEffect(() => {
    props.updateUniqueKey();
  }, [props.selectedCropCalendarStage.calendarName]);
  return (
    <>
      <Box>
        <Box className={classes.formContainer}>
          <div className={classes.formContainerChildDivHelper}>
            <Stack direction="row" spacing={2}>
              <Formik
                validationSchema={CropStageCalendarSchemaValidation}
                enableReinitialize={true}
                initialValues={{
                  calendarName: props.selectedCropCalendarStage.calendarName,
                  stageSystemName:
                    props.selectedCropCalendarStage.stageSystemName,
                  applicable: props.selectedCropCalendarStage.applicable,
                  startDay: String(props.selectedCropCalendarStage.startDay),
                  endDay: String(props.selectedCropCalendarStage.endDay),
                  reference: props.selectedCropCalendarStage.reference,
                }}
                onSubmit={(values: any, _onSubmitProps: any) => {
                  if (
                    checkForDuplicates(
                      props.cropStageList,
                      {
                        stageSystemName: values!.stageSystemName,
                        calendarName: values!.calendarName,
                      },
                      values!.calendarName,
                      props.selectedCropCalendarStage.calendarName
                    )
                  ) {
                    _onSubmitProps.setFieldError(
                      "stageSystemName",
                      "Combination with stage system name & calendarname exists!"
                    );
                    _onSubmitProps.setSubmitting(false);
                    return;
                  } else {
                    props.onSave(values);
                    _onSubmitProps.resetForm();
                    props.updateUniqueKey();
                    setIsRuleErrorFlag(false);
                  }
                }}
              >
                {({ values, setFieldTouched }) => (
                  <Stack
                    flex={1}
                    justifyContent="flex-end"
                    gap={2}
                    direction="row"
                  >
                    <Form>
                      <Stack flex={1} justifyContent="flex-end" minWidth={350}>
                        <Field name="calendarName" id="calendarName">
                          {(_props: any) => {
                            const { form } = _props;
                            const { setFieldValue } = form;
                            return (
                              <FormControl
                                className={classes.formControlMrtHelper}
                                fullWidth
                              >
                                <RenderField
                                  name="calendarName"
                                  label="Calender System Name *"
                                  labelKey="calendarName"
                                  value={
                                    values.calendarName
                                      ? `${values.calendarName}`
                                      : ""
                                  }
                                  options={props.corpCalendarList}
                                  onChange={(
                                    name: string,
                                    selectedType: any
                                  ) => {
                                    setFieldValue(
                                      "calendarName",
                                      selectedType.calendarName
                                    );
                                  }}
                                  key={props.uniqueKey}
                                  isDisabled={
                                    props.canAddNewRecord
                                      ? !!props.selectedCropCalendarStage
                                            .calendarName
                                      : true
                                  }
                                />
                              </FormControl>
                            );
                          }}
                        </Field>
                        <FormHelperText error={true}>
                          <ErrorMessage name="calendarName" />
                        </FormHelperText>
                        <Field name="stageSystemName" id="stageSystemName">
                          {(_props: any) => {
                            const { form } = _props;
                            const { setFieldValue } = form;
                            return (
                              <FormControl
                                className={classes.formControlMrtHelper}
                                fullWidth
                              >
                                <RenderField
                                  name="systemName"
                                  label="Stage System Name *"
                                  labelKey="systemName"
                                  value={
                                    values.stageSystemName
                                      ? `${values.stageSystemName}`
                                      : ""
                                  }
                                  options={props.corpGrowthStagesList}
                                  onChange={(
                                    name: string,
                                    selectedType: any
                                  ) => {
                                    setFieldValue(
                                      "stageSystemName",
                                      selectedType.systemName
                                    );
                                  }}
                                  key={props.uniqueKey}
                                  isDisabled={
                                    props.canAddNewRecord
                                      ? !!props.selectedCropCalendarStage
                                            .calendarName
                                      : true
                                  }
                                />
                              </FormControl>
                            );
                          }}
                        </Field>
                        <FormHelperText error={true}>
                          <ErrorMessage name="stageSystemName" />
                        </FormHelperText>
                        <Field name="applicable" id="applicable">
                          {(_props: any) => {
                            const { form } = _props;
                            const { setFieldValue } = form;
                            return (
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Typography
                                  variant="subtitle2"
                                  id="demo-row-radio-buttons-group-label"
                                >
                                  Applicable? *
                                </Typography>
                                <FormControl>
                                  <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    onChange={(e) => {
                                      setFieldValue(
                                        "applicable",
                                        e.target.value === "true"
                                      );
                                    }}
                                    key={props.uniqueKey}
                                  >
                                    <FormControlLabel
                                      value={true}
                                      control={
                                        <Radio
                                          size="small"
                                          value={true}
                                          disabled={
                                            props.canAddNewRecord
                                              ? !!props.selectedCropCalendarStage
                                                    .calendarName
                                              : true
                                          }
                                        />
                                      }
                                      label="Yes"
                                      checked={values?.applicable === true}
                                    />
                                    <FormControlLabel
                                      value={false}
                                      control={
                                        <Radio
                                          size="small"
                                          value={false}
                                          disabled={
                                            props.canAddNewRecord
                                              ? !!props.selectedCropCalendarStage
                                                    .calendarName
                                              : true
                                          }
                                        />
                                      }
                                      label="No"
                                      checked={values?.applicable === false}
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Stack>
                            );
                          }}
                        </Field>
                        <FormHelperText error={true}>
                          <ErrorMessage name="applicable" />
                        </FormHelperText>
                        <Field name="startDay" id="startDay">
                          {(_props: any) => {
                            const { form } = _props;
                            const { setFieldValue } = form;
                            return (
                              <FormControl
                                className={classes.formControlMrtHelper}
                                fullWidth
                              >
                                <Typography variant="subtitle2">
                                  Start Day *
                                </Typography>
                                <TextField
                                  fullWidth
                                  onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    setFieldTouched("startDay");
                                    setFieldValue(
                                      "startDay",
                                      event.target.value
                                    );
                                  }}
                                  value={values.startDay}
                                  defaultValue={values?.startDay}
                                  type="text"
                                    name={'startDay'}
                                  key={props.uniqueKey}
                                  disabled={
                                    !(
                                      (props.isEdit && props.isPrimaryCrop) ||
                                      props.actionType === ActionType.CREATE
                                    )
                                  }
                                />
                              </FormControl>
                            );
                          }}
                        </Field>
                        <FormHelperText error={true}>
                          <ErrorMessage name="startDay" />
                        </FormHelperText>
                        <Field
                          name="endDay"
                          id="endDay"
                          validate={(endDay: any) =>
                            validateEndDay(endDay, values.startDay)
                          }
                        >
                          {(_props: any) => {
                            const { form } = _props;
                            const { setFieldValue } = form;
                            return (
                              <FormControl
                                className={classes.formControlMrtHelper}
                                fullWidth
                              >
                                <Typography variant="subtitle2">
                                  End Day *
                                </Typography>
                                <TextField
                                  fullWidth
                                  onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    setFieldTouched("endDay");
                                    setFieldValue(
                                      "endDay",
                                      event.target.value
                                    );
                                  }}
                                  defaultValue={values?.endDay}
                                  type="text"
                                  name={'endDay'}
                                  inputProps={{
                                    inputMode: "numeric",
                                    pattern: "[0-9]*",
                                  }}
                                  key={props.uniqueKey}
                                  disabled={
                                    !(
                                      (props.isEdit && props.isPrimaryCrop) ||
                                      props.actionType === ActionType.CREATE
                                    )
                                  }
                                />
                              </FormControl>
                            );
                          }}
                        </Field>
                        <FormHelperText error={true}>
                          <ErrorMessage name="endDay" />
                        </FormHelperText>
                        <Field name="reference" id="reference">
                          {(_props: any) => {
                            const { form } = _props;
                            const { setFieldValue } = form;
                            return (
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Typography
                                  variant="subtitle2"
                                  id="demo-row-radio-buttons-group-label"
                                >
                                  Reference *
                                </Typography>
                                <FormControl>
                                  <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    onChange={(e) => {
                                      setFieldValue(
                                        "reference",
                                        e.target.value
                                      );
                                    }}
                                    key={props.uniqueKey}
                                  >
                                    <FormControlLabel
                                      value={"DOS"}
                                      control={
                                        <Radio
                                          size="small"
                                          value={"DOS"}
                                          disabled={
                                            props.canAddNewRecord
                                              ? !!props.selectedCropCalendarStage
                                                    .calendarName
                                              : true
                                          }
                                        />
                                      }
                                      label="DOS"
                                      checked={values?.reference === "DOS"}
                                    />
                                    <FormControlLabel
                                      value={"DOT"}
                                      control={
                                        <Radio
                                          size="small"
                                          value={"DOT"}
                                          disabled={
                                            props.canAddNewRecord
                                              ? !!props.selectedCropCalendarStage
                                                    .calendarName
                                              : true
                                          }
                                        />
                                      }
                                      label="DOT"
                                      checked={values?.reference === "DOT"}
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Stack>
                            );
                          }}
                        </Field>
                        <FormHelperText error={true}>
                          <ErrorMessage name="reference" />
                        </FormHelperText>
                        <Stack
                          alignSelf="self-end"
                          direction="row"
                          spacing={2}
                          my={2}
                        >
                          <Button
                            disabled={
                              !(
                                (props.isEdit && props.isPrimaryCrop) ||
                                props.actionType === ActionType.CREATE
                              )
                            }
                            type="submit"
                            variant="contained"
                            color="success"
                            data-testid="add-stage-calendar"
                          >
                            {(props.selectedCropCalendarStage?.calendarName &&
                              "Update Calender") ||
                              "Add Calender"}
                          </Button>
                        </Stack>
                      </Stack>
                    </Form>
                    <Divider orientation="vertical" flexItem />
                  </Stack>
                )}
              </Formik>
              {
                <Stack flex={1}>
                  <Box>
                    <Typography variant="subtitle2">
                      ‘Added Stage Calendar(s):
                    </Typography>
                    <Typography variant="caption" component="span">
                      You may reorder the stages by dragging each panel up or
                      down
                    </Typography>
                    <FormHelperText error={true}>
                      {isRuleErrorFlag && (
                        <>Create at least one record to proceed</>
                      )}
                    </FormHelperText>
                    <Box my={2}>{renderCropStageCalendarFormStages()}</Box>
                  </Box>
                </Stack>
              }
            </Stack>
          </div>
        </Box>
        <>
          <Divider />
          <div className={classes.modalFooterActionBtnsHelper}>
            <Stack direction="row" spacing={2}>
              <ButtonWithIcon
                showCreateProgress={false}
                type={CANCEL_TYPE}
                label="Previous"
                onClick={() => {
                  props.onPrevious(STEPS.STEP4);
                }}
              />
              <ButtonWithIcon
                showCreateProgress={false}
                type={CREATE_TYPE}
                label={
                  props.isEdit &&
                  props.actionType !== ActionType.ADD_TRANSLATION
                    ? "Update"
                    : "Create"
                }
                onClick={() => {
                  // props.cropStageList.length > 0 ? props.onNext(STEPS.STEP6) : setIsRuleErrorFlag(true);
                  props.onNext(STEPS.STEP6);
                }}
              />
            </Stack>
          </div>
        </>
      </Box>
    </>
  );
};
