export type DashboardMediaColumnType = {
  fieldLabel: string;
  fieldName: string;
};

export const DashboardMediaColumns: Array<DashboardMediaColumnType> = [

  {
    fieldLabel: 'Video/Image/Title',
    fieldName: 'title'
  },
  {
    fieldLabel: 'Language',
    fieldName: 'languageName'
  },
  {
    fieldLabel: 'Category',
    fieldName: 'category'
  },

  {
    fieldLabel: 'Media URL/Image',
    fieldName: 'feedurl'
  },
  {
    fieldLabel: 'Scheduled Date',
    fieldName: 'dashboardScheduleDate'
  },
  {
    fieldLabel: 'Last Updated On',
    fieldName: 'lastUpdated'
  }
];
export const DashboardMediaMyUploadsTableColumnType: Array<DashboardMediaColumnType> = [
  {
    fieldLabel: 'File Name',
    fieldName: 'fileName'
  },
  {
    fieldLabel: 'Uploaded On',
    fieldName: 'lastUpdatedDate'
  },
  {
    fieldLabel: 'Status',
    fieldName: 'comnMyUploadStatus'
  }
];
export const DashboardMediaDeletedListTableColumnType: Array<DashboardMediaColumnType> = [
  {
    fieldLabel: 'Video/Image/Title',
    fieldName: 'title'
  },
  {
    fieldLabel: 'Language',
    fieldName: 'languageName'
  },
  {
    fieldLabel: 'Category',
    fieldName: 'category'
  },
  {
    fieldLabel: "Type",
    fieldName: "type"
  },
  {
    fieldLabel: "Deleted By",
    fieldName: "deletedBy"
  },
  {
    fieldLabel: "Deleted At",
    fieldName: "deletedAt"
  },
  {
    fieldLabel: "Is Draft",
    fieldName: "draft"
  },
];