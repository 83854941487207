import { Box, Modal, Paper, Stack, TableContainer } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import api_endpoints, { base_url } from "../../Api/end_points";
import { checkExportToExcelAllowed, checkStatusCpHybridFlag, GetDeleteLog, PostDeleteLog } from "../../Api/generic_apicalls";
import ButtonWithIcon from "../../Common/Buttons";
import {
  ActionType,
  DOWNLOAD_SAMPLE_TEMPLATE_TYPE,
  ErrorTableHeaders,
  PublishModuleDD,
} from "../../Common/Constants";
import CustomTabs from "../../Common/CustomTabs";
import DataTable from "../../Common/DataTable";
import DataTableActions from "../../Common/DataTable/tableActions";
import { DownLoadSampleTemplate } from "../../Common/DownloadSampleTemplate";
import FilterAndSort from "../../Common/FilterAndSort";
import ModalHeader from "../../Common/Modal/ModalHeader";
import PageTitle from "../../Common/PageTitle";
import { ACTION_TYPE } from "../../Common/RenderTableActions";
import RowsPerPage from "../../Common/RowsPerPage";
import SearchBar from "../../Common/SearchBar";
import { RequestMethods } from "../../Constants/enums";
import { IRequestBody } from "../../Constants/interfaces";
import { formatDate } from "../../Utility/DateFormat";
import downloadZipFile, {
  DownloadZipParamsType,
} from "../../Utils/downloadZip";
import { ConvertJSONToXLS } from "../../Utils/fileHelper";
import _ from "../../Utils/lodash";
import { TriggerToastMessage } from "../../Utils/toastTrigger";
import { RootState } from "../../redux/store";
import BulkUploadModal from "../RetailersAndNurseries/Modal/BulkUploadModal";
import KnowMorePopup from "./../../Common/KnowMoreErrorModal/knowMoreDetails";
import { CreateOrModifyHybrid } from "./AddEditHybrid";
import {
  DeleteHybridCrop as DeleteHybrid,
  GetErrorList,
  GetHybridDetails,
  GetHybridsDataList,
  GetMyUploadList,
} from "./ApiCalls/HybridsAPICalls";
import {
  HybridsDeletedListTableColumnType,
  HybridsMyUploadsTableColumnType,
  HybridsTableColumnType,
} from "./HybridsColumnsData";
import ViewHybrid from "./ViewHybrid/ViewHybrid";
import { useStyles } from "./useStyle";
import { HybridsResponseObject } from "./types";
import { useDownloadProgressContext } from "../../Common/ProgressProvider";
import cropImageMedia from "../../Utils/cropMedia";
import { api_service } from "../../Api/api_service";

const Hybrids = (props: any) => {
  const { addDownloadProgress } = useDownloadProgressContext();
  const storeData = useSelector((state: RootState) => {
    return {
      languages: state.generic?.ListOfLanguages?.languageList,
      countryCode: state.auth.countryCode,
    };
  });

  const [searchValue, setSearchValue] = React.useState<string | undefined>("");
  const [activeTab, setActiveTab] = React.useState<number>(0);
  const [limit, setLimit] = React.useState<number>(5);
  const [hybridsData, setHybridsData] = React.useState<Array<any>>([]);
  const [hybridDataChecked, setHybridDataChecked] = useState<Array<any>>([]);
  const INITIAL_FILTER_STATE = {
    languageIds: [],
    status: [],
    crop: [],
    category: [],
  };
  const [filterObj, setFilterObj] = React.useState(INITIAL_FILTER_STATE);
  const [totalPages, setTotalPages] = React.useState<number>(0);
  const [viewHybridModal, toggleViewHybridModal] =
    React.useState<boolean>(false);
  const [hybridInfo, setSelectedHybridInfo] = React.useState<any>("");
  const [showHybridModal, toggleNewHybridModal] =
    React.useState<boolean>(false);
  const [open, setOpen] = React.useState(false);
  const [showBulkUploadModal, toggleBulkUploadView] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [actionType, setActionType] = React.useState<string>("");
  const [sortId, setSortId] = React.useState<string>("1");
  const [myUploadData, setMyUploadData] = useState<any | undefined>(undefined);
  const [errorList, setErrorList] = useState<Array<any> | undefined>(undefined);
  const [openKnowMore, setOpenKnowMore] = useState<boolean>(false);
  const [buttonExportVisible, setButtonExportVisible] =
    useState<boolean>(false);
  const [hybridFlag, setHybridFlag] = useState<boolean | null>(null);

  const handleSelectAll = (status: boolean) => {
    AssignCheckedFlag(hybridsData, status);
  };

  const selectEachByRow = (check: boolean, rowData: any) => {
    checkOrUncheckRow(rowData, check);
  };

  const checkOrUncheckRow = (data: any, status: boolean) => {
    const checkedItems = hybridDataChecked.flatMap((item) =>
      item.data.filter((itemData: any) => itemData.checked)
    );

    const isValid =
      checkedItems.length === 0 ||
      checkedItems.some(
        (itemData: any) => data.cropSystemName === itemData.cropSystemName
      );

    if (isValid) {
      const filterData: any = hybridsData.map((isdata: any) => {
        if (
          isdata.systemName === data.systemName &&
          isdata.languageId === data.languageId
        ) {
          isdata.checked = status;
        }

        return isdata;
      });

      setHybridsData(filterData);
      filterDataBasedOnPageChecked(currentPage, filterData);
    } else {
      TriggerToastMessage(
        "You are selecting a hybrid from a different crop. Please select from the same crop!",
        "warn"
      );
    }
  };

  const AssignCheckedFlag = (data: any, status: boolean) => {
    const filterData: any = data.map((isdata: any) => {
      isdata.checked = status;
      return isdata;
    });

    filterDataBasedOnPageChecked(currentPage, filterData);
  };

  const filterDataBasedOnPageChecked = (page: number, data: any) => {
    if (hybridDataChecked.length < 1) {
      setHybridDataChecked([
        {
          page: page,
          data: data,
        },
      ]);
      return;
    }

    const checkPageIsExist = hybridDataChecked.some(
      (oldData) => oldData.page === page
    );
    if (checkPageIsExist) {
      const filterData: any = hybridDataChecked.map((isdata: any) => {
        if (isdata.page === page) {
          return {
            page,
            data,
          };
        }
        return isdata;
      });
      setHybridDataChecked(filterData);
      return;
    }

    setHybridDataChecked([
      ...hybridDataChecked,
      {
        page: page,
        data: data,
      },
    ]);
  };

  const replaceDataAlreadyExistWithChecked = () => {
    if (hybridDataChecked.length < 1) return;

    const dataCheckedPage = hybridDataChecked.filter((data) => {
      return data.page === currentPage;
    });
    if (currentPage === dataCheckedPage?.[0]?.page) {
      setHybridsData(dataCheckedPage?.[0]?.data);
    }
  };

  const convertMatrictToArray = () => {
    const data = hybridDataChecked.map((data) => {
      return data.data;
    });
    return data.flat();
  };

  const getDetailData = (data: any) => {
    let apiData = {} as IRequestBody;
    apiData.domain = base_url;
    let endPoint: string = api_endpoints.view_hybrid_details;
    apiData.endPoint = endPoint
      .replace("__CROP_SYSTEM_NAME__", `${data.cropSystemName}`)
      .replace("__HYBRID_SYS_NM__", `${data.systemName}`);
    apiData.showLoader = true;

    apiData.customHeaders = {
      "language-code":
        storeData.languages.find((lang) => lang.languageId === data.languageId)
          ?.languageCode ?? "en",
    };

    return new Promise((resolve, reject) => {
      GetHybridDetails(apiData)
        .then((response: any) => {
          resolve(response.data);
        })
        .catch((error) => reject(new Error("Error", { cause: error })));
    });
  };

  const handleExportToExcel = async () => {
    try {
      const dataArrayDisplay = convertMatrictToArray();
      const selectedCpProductInfo = _.filter(dataArrayDisplay, {
        checked: true,
      });

      const publishPendingStatus = selectedCpProductInfo.some(
        (value: any) => value.statusId === 5
      );

      if (publishPendingStatus) {
        const confirmationExport = confirm(
          "Exporting draft record for 'Publish Pending' item"
        );
        if (!confirmationExport) return;
      }

      const allData = await Promise.all(
        selectedCpProductInfo.map(getDetailData)
      );

      const featuresData: any[] = [];
      const agronomicsPracticesData: any[] = [];
      const growerTestimonialsData: any[] = [];
      const precetionsData: any[] = [];
      const filtersData: any[] = [];

      processAllData(
        allData,
        featuresData,
        agronomicsPracticesData,
        growerTestimonialsData,
        precetionsData,
        filtersData
      );

      if (selectedCpProductInfo.length === 0) {
        TriggerToastMessage("Please select atleast one line to export", "warn");
        return;
      }

      const MAX_RETRY_COUNT = 3;

      const downloadWithRetry = (
        params: DownloadZipParamsType,
        retryCount: number = 0
      ) => {
        downloadZipFile({
          ...params,
          onErrorDownload: (message) => {
            handleDownloadError(
              message,
              params,
              retryCount,
              MAX_RETRY_COUNT,
              selectedCpProductInfo
            );
          },
        });
      };

      initiateDownload(selectedCpProductInfo, storeData, downloadWithRetry);

      const columnsData = getColumnsData(storeData);
      const columnFeatures = getColumnFeatures(storeData);
      const columnAgronomyPractices = getColumnAgronomyPractices(storeData);
      const columnGrowerTestimonials = getColumnGrowerTestimonials(storeData);
      const columnPrecautions = getColumnPrecautions(storeData);
      const columnFilters = getColumnFilters(storeData);

      const dataObj = [
        {
          columns: columnsData,
          content: allData,
          sheet: "General Info",
        },
        {
          columns: columnFeatures,
          content: featuresData,
          sheet: "Features",
        },
        {
          columns: columnAgronomyPractices,
          content: agronomicsPracticesData,
          sheet: "AgronomyPractices",
        },
        {
          columns: columnGrowerTestimonials,
          content: growerTestimonialsData,
          sheet: "Grower Testimonials",
        },
        {
          columns: columnPrecautions,
          content: precetionsData,
          sheet: "Precautions",
        },
        {
          columns: columnFilters,
          content: filtersData,
          sheet: "Filters",
        },
      ];
      let settings = {
        fileName: "HybridsInfo_" + new Date().getTime(), // Name of the resulting spreadsheet
        extraLength: 3, // A bigger number means that columns will be wider
        writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
      };
      ConvertJSONToXLS(dataObj, settings);
    } catch (error: any) {
      TriggerToastMessage(error.message, "error");
    }
  };

  const processAllData = (
    allData: any[],
    featuresData: any[],
    agronomicsPracticesData: any[],
    growerTestimonialsData: any[],
    precetionsData: any[],
    filtersData: any[]
  ) => {
    allData.forEach((data: any) => {
      data.payload.features.forEach((feature: any) =>
        featuresData.push({
          ...feature,
          hybridSystemName: data.hybridSystemName,
          languageName: data.languageName,
        })
      );
      data.payload.agronomyPractices.forEach((agronomy: any) =>
        agronomicsPracticesData.push({
          ...agronomy,
          hybridSystemName: data.hybridSystemName,
          languageName: data.languageName,
        })
      );
      data.payload.growerTestimonials.forEach((grower: any) =>
        growerTestimonialsData.push({
          ...grower,
          hybridSystemName: data.hybridSystemName,
          languageName: data.languageName,
        })
      );
      data.payload.precautions.forEach((precaution: any) =>
        precetionsData.push({
          ...precaution,
          hybridSystemName: data.hybridSystemName,
          languageName: data.languageName,
        })
      );
      const existingFilterData = filtersData.find(
        (filter: any) => filter.hybridSystemName === data.hybridSystemName
      );
      if (!existingFilterData) {
        data.payload.filters.forEach((filter: any) =>
          filtersData.push({
            ...filter,
            crop: data.payload.crop,
            hybridSystemName: data.hybridSystemName,
            languageName: data.languageName,
          })
        );
      }
    });
  };

  const handleDownloadError = (
    message: string,
    params: DownloadZipParamsType,
    retryCount: number,
    MAX_RETRY_COUNT: number,
    selectedCpProductInfo: any[]
  ) => {
    addDownloadProgress({
      module: "Hybrid",
      downloadName:
        selectedCpProductInfo[0].cropSystemName +
        " - " +
        selectedCpProductInfo
          .map((data: any) => data.systemName)
          .filter(
            (systemName: string, index: number, self: string[]) =>
              index === self.indexOf(systemName)
          )
          .join(", "),
      status: "Error",
      message: message ?? "Something went wrong",
      canRefetch: true,
      onRefetchCallback: () => {
        if (retryCount < MAX_RETRY_COUNT) {
          const downloadWithRetry = (
            params: DownloadZipParamsType,
            retryCount: number
          ) => {
            downloadZipFile({
              ...params,
              onErrorDownload: (message) => {
                handleDownloadError(
                  message,
                  params,
                  retryCount,
                  MAX_RETRY_COUNT,
                  selectedCpProductInfo
                );
              },
            });
          };
          downloadWithRetry(params, retryCount + 1);
        } else {
          addDownloadProgress({
            module: "Hybrid",
            downloadName:
              selectedCpProductInfo[0].cropSystemName +
              " - " +
              selectedCpProductInfo
                .map((data: any) => data.systemName)
                .filter(
                  (systemName: string, index: number, self: string[]) =>
                    index === self.indexOf(systemName)
                )
                .join(", "),
            status: "Error",
            message: `Maximum number of ${MAX_RETRY_COUNT} retries has been reached`,
            canRefetch: false,
          });
        }
      },
    });
  };

  const initiateDownload = (
    selectedCpProductInfo: any[],
    storeData: any,
    downloadWithRetry: any
  ) => {
    downloadWithRetry({
      systemNames: ["HYBRID"],
      path: `admin-portal/${storeData.countryCode}/${selectedCpProductInfo[0].cropSystemName}/`,
      folder: `hybrids`,
      onDownloadProgress: (ProgressEvent: any) => {
        const totalDownloaded = ProgressEvent.loaded;
        const totalSize = ProgressEvent.total;
        const progress = Math.round((totalDownloaded / totalSize) * 100);

        if (ProgressEvent.target?.status === 200) {
          addDownloadProgress({
            module: "Hybrid",
            downloadName:
              selectedCpProductInfo[0].cropSystemName +
              " - " +
              selectedCpProductInfo
                .map((data: any) => data.systemName)
                .filter(
                  (systemName: string, index: number, self: string[]) =>
                    index === self.indexOf(systemName)
                )
                .join(", "),
            totalDownloaded,
            totalSize,
            progress,
            status: "Downloading",
          });
        }
      },
      onPreparingDownload: () => {
        addDownloadProgress({
          module: "Hybrid",
          downloadName:
            selectedCpProductInfo[0].cropSystemName +
            " - " +
            selectedCpProductInfo
              .map((data: any) => data.systemName)
              .filter(
                (systemName: string, index: number, self: string[]) =>
                  index === self.indexOf(systemName)
              )
              .join(", "),
          status: "Preparing",
          message: "Preparing to Download Zip File",
        });
      },
    });
  };

  const getColumnsData = (storeData: any) => [
    {
      label: "Hybrid System Name",
      value: "hybridSystemName",
    },
    {
      label: "Language",
      value: "languageName",
    },
    {
      label: "SeedTitle",
      value: "payload.name",
    },
    {
      label: "SowingWindowText/Month",
      value: "payload.month",
    },
    {
      label: "Spacing/Expected Yield",
      value: "payload.expectedYield",
    },
    {
      label: "SeedRateText",
      value: "payload.rate",
    },
    {
      label: "ImageUrl",
      value: (row: any) =>
        cropImageMedia(row.payload.media[0]?.path, storeData),
    },
    {
      label: "Crop",
      value: "payload.crop",
    },
    {
      label: "PlantPopulation",
      value: "payload.plantPopulation",
    },
    {
      label: "AgronomyText/Maturity",
      value: "payload.maturity",
    },
    {
      label: "Rating",
      value: "payload.rating",
    },
    {
      label: "KeyBenefitOne",
      value: "payload.benefit1",
    },
    {
      label: "KeyBenefitTwo",
      value: "payload.benefit2",
    },
    {
      label: "KeyBenefitThree",
      value: "payload.benefit3",
    },
    {
      label: "AvailableStates",
      value: (row: any) =>
        row.payload.states.map((data: any) => data.shortCode).join(", "),
    },
    {
      label: "DisplayOrder",
      value: (row: any) => row.payload.displayOrder ?? 0,
    },
  ];

  const getColumnFeatures = (storeData: any) => [
    {
      label: "Hybrid System Name",
      value: "hybridSystemName",
    },
    {
      label: "Language",
      value: "languageName",
    },
    {
      label: "Feature System Name",
      value: "systemName",
    },
    {
      label: "Feature Heading",
      value: "heading",
    },
    {
      label: "Feature Text",
      value: "text",
    },
    {
      label: "Feature Image Path",
      value: (row: any) =>
        row.media
          .map((media: any) => cropImageMedia(media?.path, storeData))
          .join(", "),
    },
    {
      label: "Display Order",
      value: "displayOrder",
    },
  ];

  const getColumnAgronomyPractices = (storeData: any) => [
    {
      label: "Hybrid System Name",
      value: "hybridSystemName",
    },
    {
      label: "Language",
      value: "languageName",
    },
    {
      label: "Agronomy System Name",
      value: "systemName",
    },
    {
      label: "Agronomy Heading",
      value: "heading",
    },
    {
      label: "Agronomy Text",
      value: "text",
    },
    {
      label: "Agronomy Image Path",
      value: (row: any) =>
        row.media
          .map((media: any) => cropImageMedia(media?.path, storeData))
          .join(", "),
    },
    {
      label: "Display Order",
      value: "displayOrder",
    },
  ];

  const getColumnGrowerTestimonials = (storeData: any) => [
    {
      label: "Hybrid System Name",
      value: "hybridSystemName",
    },
    {
      label: "Language",
      value: "languageName",
    },
    {
      label: "Grower Testimonial System Name",
      value: "systemName",
    },
    {
      label: "Grower Testimonial Text",
      value: "text",
    },
    {
      label: "Media Url",
      value: "mediaUrl",
    },
    {
      label: "Media Type",
      value: "mediaType",
    },
    {
      label: "Display Order",
      value: "displayOrder",
    },
    {
      label: "Grower Testimonial Subtext",
      value: "subtext",
    },
  ];

  const getColumnPrecautions = (storeData: any) => [
    {
      label: "Hybrid System Name",
      value: "hybridSystemName",
    },
    {
      label: "Language",
      value: "languageName",
    },
    {
      label: "Precaution System Name",
      value: "systemName",
    },
    {
      label: "Precaution Text",
      value: "text",
    },
    {
      label: "Precaution Image Path",
      value: "heading",
    },
    {
      label: "Display Order",
      value: "displayOrder",
    },
  ];

  const getColumnFilters = (storeData: any) => [
    {
      label: "Hybrid System Name",
      value: "hybridSystemName",
    },
    {
      label: "Filter Key",
      value: "keySystemName",
    },
    {
      label: "Filter Value",
      value: "valueSystemName",
    },
    {
      label: "Crop",
      value: "crop",
    },
    {
      label: "State Code",
      value: (row: any) =>
        row.filterStates.map((state: any) => state.shortCode).join(", "),
    },
  ];

  /** all useEffects here */
  useEffect(() => {
    if (currentPage === 0) {
      fetchData();
    } else {
      setCurrentPage(0);
    }
  }, [limit, searchValue, filterObj, sortId, activeTab]);

  useEffect(() => {
    setHybridsData([]);
    fetchData();
  }, [currentPage]);

  useEffect(() => {
    setHybridDataChecked([]);
  }, [limit]);

  useEffect(() => {
    if (hybridsData.length < 1) return;
    replaceDataAlreadyExistWithChecked();
  }, [currentPage, hybridsData]);

  useEffect(() => {
    checkExportToExcelAllowed("Hybrids")?.then((res) =>
      setButtonExportVisible(res)
    );
  }, []);

  /**Table DataList API integration */
  const fetchDataList = () => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    let endPoint: string = api_endpoints.list_hybrids;
    apiData.payLoad = { pageNo: currentPage, pageSize: limit };

    if (filterObj.languageIds?.length > 0) {
      apiData.payLoad.languageIds = filterObj.languageIds
        .map((filterLngObj: any) => {
          return filterLngObj.split(":::")[1];
        })
        .join(",");
    }

    if (filterObj.crop.length > 0) {
      apiData.payLoad.cropIds = filterObj.crop
        .map((filterLngObj: any) => {
          return filterLngObj.split(":::")[1];
        })
        .join(",");
    }
    /**search payload */
    if (searchValue) {
      apiData.payLoad.term = searchValue.toLocaleLowerCase();
    }

    if (sortId) {
      apiData.payLoad.sort = sortId === "1" ? "desc" : "asc";
    }
    apiData.endPoint = endPoint;
    apiData.showLoader = true;
    /** prepare selected filter payload */
    // apiData.payLoad = prepareTabPayload('data_list');
    GetHybridsDataList(apiData, successCallBack, errorCallback);
  };

  const fetchMyUploads = () => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    let endPoint: string = api_endpoints.myupload_list;
    /**applying sortid to the query params */
    sortId
      ? (endPoint += `?sortId=${sortId}&pageNo=${currentPage}&pageSize=${limit}&dataType=${PublishModuleDD.HYBRIDS}`)
      : (endPoint += `?pageNo=${currentPage}&pageSize=${limit}&dataType=${PublishModuleDD.HYBRIDS}`);
    /**search payload */
    if (searchValue) {
      endPoint += `&searchKey=${searchValue}`;
    }
    apiData.endPoint = endPoint;
    apiData.showLoader = true;
    GetMyUploadList(apiData, successCallBack, errorCallback);
  };

  const fetchHybridDeleteLogs = () => {
    let apiData: IRequestBody = {
      domain: base_url,
      endPoint: api_endpoints.delete_logs,
      payLoad: { type: "HYBRID" },
      showLoader: true,
    };

    GetDeleteLog(apiData)
      .then((response: any) => {
        const logs = response?.data?.deleteLogs?.map((log: any) => {
          let parsedPayload = {
            systemName: "N/A",
            crop: "N/A",
            language: "N/A",
            title: "N/A",
          };

          try {
            parsedPayload = JSON.parse(log.payload);
          } catch (error) {
            console.error("Error parsing payload:", error);
          }

          return {
            ...log,
            systemName: parsedPayload.systemName || "N/A",
            crop: parsedPayload.crop || "N/A",
            language: parsedPayload.language || "N/A",
            title: parsedPayload.title || "N/A",
            draft: log.draft ? "True" : "False",
          };
        });

        setHybridsData(logs);
      })
      .catch((error) => {
        console.error("Error fetching delete logs:", error);
        TriggerToastMessage("Failed to fetch delete logs", "error");
      });
  };

  const deletePublishApi = async (systemName: string, hybridInfo: any): Promise<void> => {

    if (confirm(`Are you sure to delete this record for ${systemName}?`)) {
      try {
        const deletedData = (await getDetailData(hybridInfo)) as any;

        const deleteRequest: IRequestBody = {
          domain: base_url,
          endPoint: api_endpoints.delete_hybrid.replace(
            "__HYBRID_SYSTEM_NAME__",
            systemName
          ),
          showLoader: true,
        };

        const logPayload = {
          type: "HYBRID",
          payload: JSON.stringify({
            systemName: deletedData.hybridSystemName || "N/A",
            crop: deletedData.cropSystemName || "N/A",
            language: deletedData.languageName || "N/A",
            title: deletedData.payload.name || "N/A",
          }),
          isDraft: true, //need to fix
        };

        DeleteHybrid(
          deleteRequest,
          () => {
            successDeleteCallBack;
            const logRequest: IRequestBody = {
              domain: base_url,
              endPoint: api_endpoints.delete_logs,
              payLoad: logPayload,
              showLoader: false,
            };

            PostDeleteLog(
              logRequest,
              (logResponse: any) => {
                TriggerToastMessage(
                  logResponse.message
                    ? logResponse.message
                    : "Delete log recorded successfully",
                  "success"
                );
              },
              (logError: any) => {
                TriggerToastMessage(
                  logError.message ? logError.message : "Failed to record delete log",
                  "error"
                );
              }
            );
          },
          errorDeleteCallback
        );
      } catch (error) {
        console.error("Error while deleting Hybrid:", error);
        TriggerToastMessage("Failed to delete Hybrid", "error");
      }
    }
  };

  const successDeleteCallBack = (response: any, method: RequestMethods) => {
    resetCurrentPage();
  };
  const errorDeleteCallback = () => {
    TriggerToastMessage("could not delete", "error");
  };

  const changeStatusOfHybrids = (status: boolean, systemName: string) => {
    if (
      confirm(
        "By changing the status of this hybrid, all the other translated records will be updated. Do you wish to continue?"
      )
    ) {
      const apiData = {} as IRequestBody;
      apiData.domain = base_url;
      const endPoint: string = api_endpoints.update_hybrid_status.replace(
        "__SYSTEM__NAME__",
        systemName
      );
      apiData.endPoint = endPoint;
      apiData.payLoad = {
        isActive: status,
      };

      api_service
        .patch(apiData)
        .then((response) => {
          if (response?.status === 200 || response?.status === 201) {
            TriggerToastMessage("Hybrid status updated successfully", "success");
            return response.data;
          } else {
            throw new Error(`Unexpected response status: ${response?.status}`);
          }
        })
        .catch((err) => {
          TriggerToastMessage(
            err?.message || "Unable to update status of hybrid",
            "error"
          );
          console.error("Error details:", err);
        });
    }
  };


  const fetchFlagHybrid = async () => {
    try {
      const response: any = await checkStatusCpHybridFlag();
      setHybridFlag(response.data === "true");
    } catch (error) {
      console.error("Error checking feature modified by:", error);
      setHybridFlag(false);
    }
  };

  useEffect(() => {
    fetchFlagHybrid()
  }, []);
  /**
   * @description A function which will reset current page
   * if currentPage === 0, it will trigger a method to load languages
   */
  const resetCurrentPage = () => {
    if (currentPage === 0) {
      // activeTab === 0 ? fetchPublishable() : fetchPublishedHistory();
      fetchDataList();
    } else {
      setCurrentPage(0);
    }
  };

  const handleSearchValueChange = (newSearchValue: string) => {
    setSearchValue(newSearchValue);
  };

  const handleActionClick = (clickAction: string, details?: any) => {
    setActionType(clickAction);
    switch (clickAction) {
      case ActionType.VIEW:
        showViewHybrid(details);
        break;
      case ActionType.UPDATE:
        toggleNewHybridModal(true);
        setSelectedHybridInfo(details);
        break;
      case ActionType.CREATE:
        setOpen(true);
        break;
      case ActionType.DELETE:
        deletePublishApi(details.systemName, details);
        setSelectedHybridInfo(details);
        break;
      case ActionType.ADD_TRANSLATION:
        toggleNewHybridModal(true);
        setSelectedHybridInfo(details);
        break;
      default:
        handleExportToExcel();
        return;
    }
  };

  const getHybridsColumns = () => {
    let columns = [];

    switch (activeTab) {
      case 0:
        columns = [...HybridsTableColumnType];
        if (hybridFlag) {
          columns.push({
            fieldLabel: 'Status',
            fieldName: 'hybridColumnStatus'
          });
        }
        break;
      case 1:
        columns = [...HybridsMyUploadsTableColumnType];
        break;
      case 2:
        columns = [...HybridsDeletedListTableColumnType];
        break;
      default:
        columns = [...HybridsTableColumnType];
        if (hybridFlag) {
          columns.push({
            fieldLabel: 'Status',
            fieldName: 'hybridColumnStatus'
          });
        }
        break;
    }
    return columns;
  };

  const showViewHybrid = (rowInfo: any) => {
    toggleViewHybridModal(true);
    setSelectedHybridInfo(rowInfo);
  };

  const showBulkUploadPopup = () => {
    setOpen(false);
    toggleBulkUploadView(true);
  };

  const showCreateHybridModal = (flag: boolean) => {
    setActionType(ActionType.CREATE);
    setOpen(false);
    toggleNewHybridModal(flag);
    if (!flag) {
      setSelectedHybridInfo("");
    }
  };

  /**
   * @description A callback function triggered when an api is failed
   */
  const errorCallback = (error: any, type: string) => {
    switch (type) {
      default: {
        TriggerToastMessage("Unable to process your request", "error");
      }
    }
  };

  const closeCreateHybridModal = () => {
    setOpen(false);
    fetchDataList();
  };

  const renderViewHybridModal = () => {
    return (
      <>
        <ViewHybrid
          cropSystemName={hybridInfo.cropSystemName}
          systemName={hybridInfo.systemName}
          lngId={hybridInfo.languageId}
          onCloseViewHybrid={() => toggleViewHybridModal(false)}
          canShowEditButton={activeTab === 0}
          onEditClick={(clickAction: string, details: any) =>
            handleActionClick(clickAction, details)
          }
        />
      </>
    );
  };

  const renderCreateEditHybrid = () => {
    return (
      <>
        <CreateOrModifyHybrid
          onClose={() => showCreateHybridModal(false)}
          isEdit={!!hybridInfo.systemName}
          systemName={hybridInfo.systemName}
          cropSystemName={hybridInfo.cropSystemName}
          lngId={hybridInfo.languageId}
          lngCode={hybridInfo.languageCode}
          actionType={actionType}
        />
      </>
    );
  };

  const successCallBack = (response: any, type: string, optionalData?: any) => {
    switch (type) {
      case "hybrids_data_list": {
        const listData: HybridsResponseObject = response.data;
        const updatedHybridsData = listData.seedAdminPortalDtos.map(
          (row: any) => ({
            title: row.title,
            systemName: row.systemName,
            languageName: row.languageName,
            languageId: row.languageId,
            cropSystemName: row.cropSystemName,
            imagePath: row.imagePath,
            lastUpdatedOn: row.lastUpdatedDate
              ? formatDate(row.lastUpdatedDate)
              : null,
            isActive: row.isactive,
            statusId: row.statusId,
          })
        );
        setHybridsData(updatedHybridsData);
        setTotalPages(listData?.totalPages);
        break;
      }
      case "crops_upload_list": {
        const listData: any = response.data;
        setTotalPages(Math.ceil(listData.totalElements / limit));
        const myUploadListToDisplay = listData.uploadDocumentList.map(
          (row: any) => ({
            id: row.id,
            fileName: row.fileName,
            cropsUploadDate: moment(row?.lastUpdatedDate)
              .add(moment().utcOffset(), "m")
              .format("YYYY/MM/DD hh:mm a"),
            comnMyUploadStatus: row.status,
          })
        );
        setHybridsData(myUploadListToDisplay);
        break;
      }
      case "error_list": {
        if (response.statusCode === 200) {
          setErrorList(response.data);
        }
        break;
      }
      case "delete_crop":
        fetchDataList();
        break;
    }
  };

  const renderPopupToCreateHybrid = () => {
    return (
      <>
        <Modal
          open={open}
          onClose={() => {
            closeCreateHybridModal();
          }}
        >
          <Paper className={classes.createHybridPaper}>
            <ModalHeader
              header={`Add New Data`}
              onClose={() => {
                closeCreateHybridModal();
              }}
              showDelete={false}
              showEdit={false}
            />
            <Box className="containerBox">
              <Box className="flexBoxContainerInner">
                <Stack width={330} spacing={4}>
                  <ButtonWithIcon
                    label={"Create new data"}
                    type={"MODAL CREATE"}
                    onClick={() => {
                      showCreateHybridModal(true);
                    }}
                  />
                  <ButtonWithIcon
                    label={"Bulk upload data"}
                    type={"MODAL CANCEL"}
                    onClick={() => {
                      showBulkUploadPopup();
                    }}
                  />
                </Stack>
              </Box>
            </Box>
          </Paper>
        </Modal>
      </>
    );
  };

  const fetchData = () => {
    switch (activeTab) {
      case 0: {
        fetchDataList();
        break;
      }
      case 1: {
        fetchMyUploads();
        break;
      }
      case 2: {
        fetchHybridDeleteLogs();
        break;
      }
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
    setFilterObj(INITIAL_FILTER_STATE);
    setSortId("1");
    setSearchValue("");
    setCurrentPage(0);
    setHybridDataChecked([]);
    if (newValue === 2) {
      fetchHybridDeleteLogs();
    }
  };

  const renderBulkUploadForm = () => {
    return (
      <>
        <BulkUploadModal
          allowedTypes={["xls", "xlsx"]}
          maxFileSize={5}
          onClose={handleBulkClose}
          validationLiteral={PublishModuleDD.HYBRIDS}
          templateLiteral={DOWNLOAD_SAMPLE_TEMPLATE_TYPE.HYBRID}
          reuploadId={myUploadData ? myUploadData.id : null}
          canByPassValidation={false}
        />
      </>
    );
  };

  const handleBulkClose = (currentTab: number) => {
    toggleBulkUploadView(false);
    setMyUploadData(undefined);
    if (currentTab) {
      setActiveTab(currentTab);
      fetchMyUploads();
    }
  };

  const getErrorListData = (id: number) => {
    let apiData = {} as IRequestBody;
    apiData.domain = base_url;
    let endPoint: string = api_endpoints.bulkupload_error_list;
    apiData.endPoint = endPoint.replace("__ID__", `${id}`);
    apiData.showLoader = true;
    /** payload */

    GetErrorList(apiData, successCallBack, errorCallback);
  };

  const renderKnowMoreModal = () => {
    return (
      <>
        <KnowMorePopup
          handleKnowMoreClose={(flag: boolean) => {
            setOpenKnowMore(flag);
            setMyUploadData(undefined);
          }}
          title={myUploadData && myUploadData.fileName}
          tableHeaders={ErrorTableHeaders}
          tableData={errorList}
          openBulkUploadPopup={() => {
            toggleBulkUploadView(true);
            setOpenKnowMore(false);
          }}
        />
      </>
    );
  };

  const handleKnowMorePopup = (flag: boolean, rowData: any) => {
    setOpenKnowMore(flag);
    /** API call to get Error list */
    getErrorListData(rowData.id);
    /** using this state variable to store file name and pass it
     * to the ErrorListPopup
     */
    setMyUploadData(rowData);
  };
  const classes = useStyles();

  return (
    <>
      <TableContainer>
        <div>
          <Stack
            direction="row"
            alignItems="center"
            className={classes.pageTitleNSearchStylesHelper}
          >
            <PageTitle title={props.title} />
            <SearchBar
              placeholderText="Search by Title, Systemname"
              searchValue={searchValue || ""}
              onSearchValueChange={handleSearchValueChange}
              onEnterClick={() => { }}
            />
          </Stack>
          <Box>
            <Box className={classes.customTabBtmBorder}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <CustomTabs
                  value={activeTab}
                  tabsList={["Data List", "My uploads", "Deleted List"]}
                  onChange={handleChange}
                />
                {activeTab === 1 && (
                  <DownLoadSampleTemplate
                    literal={DOWNLOAD_SAMPLE_TEMPLATE_TYPE.HYBRID}
                    displayAsButton={false}
                  />
                )}
              </Stack>
            </Box>
            <Stack direction="row" className={classes.rowsPerPageStylesHelper}>
              <RowsPerPage
                pagSize={5}
                onChangeRowsPerPage={(data: number) => {
                  setLimit(data);
                }}
              />
              <FilterAndSort
                sortBy={sortId}
                onSort={(sortType: any) => setSortId(sortType?.toString())}
                onChangeFilters={(data: any) => {
                  setFilterObj(data);
                }}
                filters={{ ...filterObj }}
                hasFilter={activeTab === 0}
                isResetFilter={`${activeTab}`}
                showLanguageSelection={true}
                showSecondarySort={false}
                showCropSelection={true}
                showLastUpdatedOn={true}
              />
            </Stack>
            <DataTable
              columns={getHybridsColumns()}
              data={hybridsData}
              enableSelection={activeTab === 0 && buttonExportVisible}
              singleSelection={activeTab === 0 && buttonExportVisible}
              pageNumber={1}
              onPageChange={() => { }}
              onSelectAll={handleSelectAll}
              onDeselectAll={() => { }}
              onRowSelect={selectEachByRow}
              isEditEnabled={activeTab === 0}
              isViewButton={activeTab === 0}
              isDeleteEnabled={activeTab === 0}
              onActionClick={(type, rowInfo) => {
                handleActionClick(type, rowInfo);
              }}
              hasTranslation={true}
              languageKey="languageName"
              handleKnowMorePopup={(flag: boolean, rowData: any) =>
                handleKnowMorePopup(flag, rowData)
              }
              onStatusChanged={(checked: boolean, data: any) =>
                changeStatusOfHybrids(checked, data.systemName)
              }
            />
            {/* Bottom of list page (delete, pagination, plus icon) */}
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <DataTableActions
                actionsType={ACTION_TYPE.TYPE_CP_PRODUCT}
                pageNumber={currentPage + 1}
                onPageChange={(selectedPageNo: number) =>
                  setCurrentPage(selectedPageNo - 1)
                }
                buttonVisible={activeTab === 0}
                buttonExportVisible={buttonExportVisible}
                onActionClick={handleActionClick}
                canShowAvatar={activeTab === 0}
                activeTab={activeTab}
                totalpages={totalPages}
              />
            </Stack>
          </Box>
        </div>
      </TableContainer>
      {renderPopupToCreateHybrid()}
      {/* -------------------------------------- View Hybrids Info ---------------------------------------- */}
      {viewHybridModal && renderViewHybridModal()}
      {/* -------------------------------------- Add New Data Modal ---------------------------------------- */}
      {showHybridModal && renderCreateEditHybrid()}
      {/* ------------------------------ Bulk Upload Modal ---------------------------------------- */}
      {showBulkUploadModal && renderBulkUploadForm()}
      {/* ------------------------------ Uploaded Hybrids Error List Info ---------------------------------------- */}
      {openKnowMore && renderKnowMoreModal()}
    </>
  );
};
export default Hybrids;
