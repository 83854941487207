import { Dispatch } from "react";
import { ServicesType, services } from "../Components/Home/allServices";
import appActions from "../redux/action";
import { IRequestBody } from "../Constants/interfaces";
import { api_service } from "./api_service";
import api_endpoints, { base_url } from "./end_points";
/**
 * @description function triggers an api call to get roles
 * and base_url
 * @param data
 * @param successCallBack function
 * @param errorCallBack funciton
 */
export const getApplicationConfig = (data: IRequestBody) => {
  return new Promise((resolve, reject) => {
    api_service
      .get(data)
      ?.then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return reject(new Error("Error", { cause: err }));
      });
  });
};

export const fetchLanguagesList = (dispatch: Dispatch<any>) => {
  const apiData = {} as IRequestBody;
  apiData.domain = base_url;
  apiData.endPoint = api_endpoints.master_api_language;
  apiData.showLoader = false;
  apiData.payLoad = { pageNo: 0, pageSize: 1000 };
  return new Promise((resolve, reject) => {
    api_service
      .get(apiData)
      ?.then((response) => {
        dispatch(appActions.apiData.appLanguages(response.data?.data));
        resolve(response.data);
      })
      .catch((err) => {
        reject(new Error("Error", { cause: err }));
      });
  });
};

export const fetchCropsList = (dispatch: Dispatch<any>) => {
  const apiData = {} as IRequestBody;
  apiData.domain = base_url;
  apiData.endPoint = api_endpoints.crops;
  apiData.showLoader = false;
  apiData.payLoad = { pageNo: 0, pageSize: 100 };
  return new Promise((resolve, reject) => {
    api_service
      .get(apiData)
      ?.then((response) => {
        //console.log(response.data)
        dispatch(appActions.apiData.appCrops(response.data?.data));
        resolve(response.data);
      })
      .catch((err) => {
        reject(new Error("Error", { cause: err }));
      });
  });
};

export const fetchNotificationTypes = (dispatch: Dispatch<any>) => {
  const apiData = {} as IRequestBody;
  apiData.domain = base_url;
  apiData.endPoint = api_endpoints.notification_types;
  apiData.showLoader = false;
  apiData.payLoad = { pageNo: 0, pageSize: 10 };
  return new Promise((resolve, reject) => {
    api_service
      .get(apiData)
      ?.then((response) => {
        dispatch(appActions.apiData.appNotificationTypes(response.data?.data));
        resolve(response.data);
      })
      .catch((err) => {
        reject(new Error("Error", { cause: err }));
      });
  });
};

export const fetchMobileLengthNurseryData = (
  countryCode: string,
  dispatch: Dispatch<any>
) => {
  const apiData = {} as IRequestBody;
  apiData.domain = base_url;
  let endPoint: string = api_endpoints.attribute_api;
  apiData.endPoint = endPoint.replace(
    "__ATTRIBUTE__KEY__",
    "TENANT_COUNTRY_SPECIFIC_MOBILE"
  );
  apiData.showLoader = true;
  apiData.customHeaders = {
    "country-code": countryCode,
  };

  return new Promise((resolve, reject) => {
    api_service
      .get(apiData)
      ?.then((response) => {
        dispatch(
          appActions.apiData.retailerMobileLength(parseInt(response.data.data))
        );
        resolve(response.data);
      })
      .catch((err) => {
        reject(new Error("Error", { cause: err }));
      });
  });
};

export const fetchCountryModules = async (
  countryCode: string,
  dispatch: Dispatch<any>
) => {
  if (!countryCode) throw new Error("Country code is required");

  const apiData = {
    domain: base_url,
    endPoint: api_endpoints.attribute_api.replace(
      "__ATTRIBUTE__KEY__",
      "TENANT_COUNTRY_SPECIFIC_MODULE"
    ),
    showLoader: true,
    customHeaders: {
      "country-code": countryCode,
    },
  } as IRequestBody;

  try {
    const response = await api_service.get(apiData);
    if (!response?.data?.data) throw new Error("Invalid API response");

    const parsedServices = JSON.parse(response.data.data.replaceAll("\\", ""));
    if (!Array.isArray(parsedServices)) throw new Error("Invalid service data");

    const enabledModulesList = [];
    
    for (const service of services) {
      const matchingService = parsedServices.find(
        parsedService => parsedService.serviceName === service.serviceName
      );
      if (matchingService) {
        enabledModulesList.push({
          ...service,
          isPublish: matchingService.isPublish
        });
      }
    }

    console.log("Country Modules", enabledModulesList);
    dispatch(appActions.apiData.modulesList(enabledModulesList));
    
    return response.data;
  } catch (err) {
    console.error("Failed to fetch country modules:", err);
    throw new Error("Error fetching country modules", { cause: err });
  }
};

export const fetchConfigModules = (
  countryCode: string,
  dispatch: Dispatch<any>,
  countryModules: Array<ServicesType>
) => {
  const apiData = {} as IRequestBody;
  apiData.domain = base_url;
  apiData.endPoint = api_endpoints.app_config_modules;
  apiData.showLoader = true;
  apiData.customHeaders = {
    "country-code": countryCode,
  };

  const handleResponse = (response: any) => {
    const serviceData = response.data.data;
    const enableServices = serviceData.replace("\\", "");
    let enabledModulesList: any[] = [];

    countryModules.forEach((item1) => {
      let data = JSON.parse(enableServices).find(
        (item2: { serviceName: string }) =>
          item2.serviceName === item1.serviceName
      );
      if (data) {
        enabledModulesList.push({ ...item1, isPublish: data.isPublish });
      }
    });

    dispatch(appActions.apiData.modulesList(enabledModulesList));
    return response.data;
  };

  return new Promise((resolve, reject) => {
    api_service
      .get(apiData)
      ?.then((response) => resolve(handleResponse(response)))
      .catch((err) => reject(new Error("Error", { cause: err })));
  });
};

/**
 * @description function triggers an api call to get roles
 * and base_url
 * @param data
 * @param successCallBack function
 * @param errorCallBack funciton
 */
export const GetFileS3BucketURL = (
  data: IRequestBody,
  successCallBack: any,
  errorCallBack: any,
  imgType: string
) => {
  api_service
    .post(data)
    ?.then((response: any) => {
      if (response.status === 200 || response.status === 201) {
        return successCallBack(response.data, "s3_file_url", imgType);
      }
      throw new Error(response.message);
    })
    .catch((err) => {
      errorCallBack(err, "s3_file_url", imgType);
    });
};

/**
 * @description Method to make an api call to fetch default language based on country seelcted
 */

export const getDefaultLanguage = (countryCode: string) => {
  const apiData: IRequestBody = {
    domain: base_url,
    endPoint: api_endpoints.attribute_api.replace(
      "__ATTRIBUTE__KEY__",
      "TENANT_PRIMARY_LANGUAGE_CODE"
    ),
    showLoader: true,
    customHeaders: {
      "country-code": countryCode,
    },
  };
  return new Promise((resolve, reject) => {
    api_service
      .get(apiData)
      ?.then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(new Error("Error", { cause: err }));
      });
  });
};

/** generic sample template download api call for all modules */
export const DownloadSampleTemplate = (
  data: IRequestBody,
  successCallBack: any,
  errorCallBack: any
) => {
  api_service
    .get(data)
    ?.then((response) => {
      successCallBack(response.data, "sample_template");
    })
    .catch((err) => {
      errorCallBack(err, "sample_template");
    });
};

export const GetCropStagesWithSystemName = (
  apiData: IRequestBody,
  successCallBack: any,
  errorCallBack: any
) => {
  api_service
    .get(apiData)
    ?.then((response) => {
      successCallBack(response.data, "crop_stage_info");
    })
    .catch((err) => {
      errorCallBack(err, "crop_stage_info");
    });
};

const getPayloadForS3Upload = (
  file: any,
  systemName: string,
  uploadType: string
) => {
  let fileNameArr: any = file.name.split(".");
  let s3Data: any = {
    //cropSystemName should be common for all images related to same crop
    type: uploadType,
    fileNameWithExtension: `${systemName}.${
      fileNameArr[fileNameArr.length - 1]
    }`,
  };
  if (systemName) {
    s3Data.cropSystemName = systemName;
  }
  return s3Data;
};

export const getFileUploadPayload = (
  file: any,
  systemName: string,
  imageUploadType: string,
  successCallBack: Function,
  errorCallBack: Function
) => {
  const apiData = {} as IRequestBody;
  apiData.endPoint = api_endpoints.fileupload_S3bucket;
  let payload = getPayloadForS3Upload(file, systemName, imageUploadType);
  let formData: any = new FormData();
  formData.append("file", file);
  formData.append("data", JSON.stringify(payload));
  apiData.payLoad = formData;
  GetFileS3BucketURL(apiData, successCallBack, errorCallBack, imageUploadType);
};

/** remove image list api */
export const RemoveImageList = (
  data: IRequestBody,
  successCallBack: any,
  errorCallBack: any
) => {
  api_service
    .delete(data)
    ?.then((res) => successCallBack(res.data, "remove_img"))
    .catch((err) => errorCallBack(err, "remove_img"));
};

/** generic api call for combination of Current module Sysytem Name and
 * selected CROP system name
 * like: in Hybrid module, CP Module
 */
const getPayloadForS3UploadWithCROP = (
  file: File,
  systemName: string,
  uploadType: string,
  cropSysNm: string,
  sysNmKey: string,
  prefix?: string
) => {
  let fileNameArr: any = file.name.split(".");
  let s3Data: any = {
    cropSystemName: cropSysNm,
    type: uploadType,
    fileNameWithExtension: `${systemName?.replaceAll(" ", "")}${
      prefix ? `_${prefix}` : ""
    }.${fileNameArr[fileNameArr.length - 1]}`,
  };
  if (sysNmKey) {
    s3Data[sysNmKey] = systemName?.replaceAll(" ", "");
  }
  return s3Data;
};

export const GetImageURLMappedWithCROP = (
  file: File,
  systemName: string,
  imageUploadType: string,
  cropSysNm: string,
  sysNmKey: string,
  successCallBack: Function,
  errorCallBack: Function,
  prefix?: string
) => {
  const apiData = {} as IRequestBody;
  apiData.endPoint = api_endpoints.fileupload_S3bucket;
  let payload = getPayloadForS3UploadWithCROP(
    file,
    systemName,
    imageUploadType,
    cropSysNm,
    sysNmKey,
    prefix
  );
  let formData: any = new FormData();
  formData.append("file", file);
  formData.append("data", JSON.stringify(payload));
  apiData.payLoad = formData;
  GetFileS3BucketURL(apiData, successCallBack, errorCallBack, imageUploadType);
};

export const checkExportToExcelAllowed = (serviceName: string) => {
  const apiData = {} as IRequestBody;
  apiData.endPoint = api_endpoints.attribute_api.replace(
    "__ATTRIBUTE__KEY__",
    "EXPORT_TO_EXCEL"
  );

  return api_service
    .get(apiData)
    ?.then((res) => {
      const dataParsed = JSON.parse(res.data.data);
      const found = dataParsed.find((item: any) => {
        return item.serviceName === serviceName;
      });

      if (found) {
        return found.exportToExcel;
      }

      return false;
    })
    .catch((err) => console.error(err));
};

export const checkFeatureModifiedBy = () => {
  const apiData = {} as IRequestBody;
  apiData.endPoint = api_endpoints.attribute_api.replace(
    "__ATTRIBUTE__KEY__",
    "FEATURE_MODIFIED_BY"
  );
  return new Promise((resolve, reject) => {
    api_service
      .get(apiData)
      ?.then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(new Error("Error", { cause: err }));
      });
  });
};

export const checkStatusCpHybridFlag = () => {
  const apiData = {} as IRequestBody;
  apiData.endPoint = api_endpoints.attribute_api.replace(
    "__ATTRIBUTE__KEY__",
    "CP_AND_HYBRID_STATUS_FLAG_ENABLE"
  );
  return new Promise((resolve, reject) => {
    api_service
      .get(apiData)
      ?.then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(new Error("Error", { cause: err }));
      });
  });
};

const fetchAttributeKeyWithSignal = (
  attributeKey: string,
  signal?: AbortSignal
) => {
  const apiData = {} as IRequestBody;
  apiData.endPoint = api_endpoints.attribute_api.replace(
    "__ATTRIBUTE__KEY__",
    attributeKey
  );
  apiData.signal = signal;

  return new Promise((resolve, reject) => {
    api_service
      .get(apiData)
      ?.then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(new Error("Error", { cause: err }));
      });
  });
};

export const checkDashboardControllerFlag = ({
  signal,
}: {
  signal?: AbortSignal;
}) => {
  return fetchAttributeKeyWithSignal("DB_CONTROLLER_FLAG", signal);
};

export const checkDashboardControllerUsersList = ({
  signal,
}: {
  signal?: AbortSignal;
}) => {
  return fetchAttributeKeyWithSignal("DB_CONTROLLER_USERS_LIST", signal);
};

export const PostDeleteLog= (
  data: IRequestBody,
  successCallBack: any,
  errorCallBack: any
) => {
  api_service
    .post(data)
    ?.then((response) => {
      successCallBack(response.data, "delete_log");
    })
    .catch((err) => {
      errorCallBack(err, "delete_log");
    });
};

export const GetDeleteLog = (data: IRequestBody) => {
  return new Promise((resolve, reject) => {
    api_service
      .get(data)
      ?.then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return reject(new Error("Error", { cause: err }));
      });
  });
};
