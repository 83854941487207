import { Box, Modal, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import ModalHeader from "../../../Common/Modal/ModalHeader";
import { useStyles } from "../style";

type ErrorPopupProps = {
  errorMessageContent: any;
  onCloseErrorPopup: Function;
};

const ErrorPopup: React.FC<ErrorPopupProps> = (props) => {
  const classes = useStyles();
  const [errorPopup, setErrorPopup] = useState<boolean>(true);
  const { errorMessageContent } = props;

  const handleClose = () => {
    setErrorPopup(false);
    props.onCloseErrorPopup();
  };

  return (
    <Modal open={errorPopup} className={classes.formModal}>
      <Paper className={classes.formPaper}>
        <ModalHeader
          header={`Error Details`}
          onClose={() => {
            handleClose();
          }}
          data-testid="error-popup-header"
        />
        <Box className={classes.formContainer}>
          <Typography
            className={classes.panelBoxHelper}
            variant="subtitle2"
            fontWeight={100}
          >
            {errorMessageContent
              .split(",")
              .map((line: string, index: number) => (
                <React.Fragment key={index}>
                  {line.trim()}
                  <br />
                </React.Fragment>
              ))}
          </Typography>
        </Box>
      </Paper>
    </Modal>
  );
};

export default ErrorPopup;
